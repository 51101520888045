/*** FONTS ***/

/* INTER FONT*/

@font-face {
    font-family: "Inter-Black";
    src: url("../fonts/inter/Inter-Black.ttf");
    src: local("Inter Black"), local("Inter-Black"),
        url("../fonts/inter/Inter-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Bold";
    src: url("../fonts/inter/Inter-Bold.ttf");
    src: local("Inter Bold"), local("Inter-Bold"),
        url("../fonts/inter/Inter-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-ExtraBold";
    src: url("../fonts/inter/Inter-ExtraBold.ttf");
    src: local("Inter ExtraBold"), local("Inter-ExtraBold"),
        url("../fonts/inter/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-ExtraLight";
    src: url("../fonts/inter/Inter-ExtraLight.ttf");
    src: local("Inter ExtraLight"), local("Inter-ExtraLight"),
        url("../fonts/inter/Inter-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Light";
    src: url("../fonts/inter/Inter-Light.ttf");
    src: local("Inter Light"), local("Inter-Light"),
        url("../fonts/inter/Inter-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Medium";
    src: url("../fonts/inter/Inter-Medium.ttf");
    src: local("Inter Medium"), local("Inter-Medium"),
        url("../fonts/inter/Inter-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Regular";
    src: url("../fonts/inter/Inter-Regular.ttf");
    src: local("Inter Regular"), local("Inter-Regular"),
        url("../fonts/inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-SemiBold";
    src: url("../fonts/inter/Inter-SemiBold.ttf");
    src: local("Inter SemiBold"), local("Inter-SemiBold"),
        url("../fonts/inter/Inter-SemiBold.ttf") format("truetype");
}

/* POPPINS FONT*/

@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src: local("Poppins Black"), local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src: local("Poppins BlackItalic"), local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src: local("Poppins Bold"), local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src: local("Poppins BoldItalic"), local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src: local("Poppins ExtraBoldItalic"), local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src: local("Poppins ExtraLight"), local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-ExtraLightItalic";
    src: url("../fonts/poppins/Poppins-ExtraLightItalic.otf");
    src: local("Poppins ExtraLightItalic"), local("Poppins-ExtraLightItalic"),
        url("../fonts/poppins/Poppins-ExtraLightItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src: local("Poppins Italic"), local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src: local("Poppins Light"), local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-LightItalic";
    src: url("../fonts/poppins/Poppins-LightItalic.otf");
    src: local("Poppins LightItalic"), local("Poppins-LightItalic"),
        url("../fonts/poppins/Poppins-LightItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src: local("Poppins Medium"), local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src: local("Poppins MediumItalic"), local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src: local("Poppins Regular"), local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src: local("Poppins SemiBold"), local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-SemiBoldItalic";
    src: url("../fonts/poppins/Poppins-SemiBoldItalic.otf");
    src: local("Poppins SemiBoldItalic"), local("Poppins-SemiBoldItalic"),
        url("../fonts/poppins/Poppins-SemiBoldItalic.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-Thin";
    src: url("../fonts/poppins/Poppins-Thin.otf");
    src: local("Poppins Thin"), local("Poppins-Thin"),
        url("../fonts/poppins/Poppins-Thin.otf") format("opentype");
}

@font-face {
    font-family: "Poppins-ThinItalic";
    src: url("../fonts/poppins/Poppins-ThinItalic.otf");
    src: local("Poppins ThinItalic"), local("Poppins-ThinItalic"),
        url("../fonts/poppins/Poppins-ThinItalic.otf") format("opentype");
}

/*** Variables ***/

$color-one: #050505;
$color-two: #ffffff;
$color-three: #642ba5;
$color-four: #441556;
$color-five: #ff28df;
$color-six: #062d51;
$color-seven: #cc1616;
$color-eight: #29b77b;

/* Fonts variable */
$inter-regular: Inter-Regular, Arial, Helvetica, sans-serif;
$inter-bold: Inter-Bold, Arial, Helvetica, sans-serif;
$inter-medium: Inter-Medium, Arial, Helvetica, sans-serif;
$inter-light: Inter-Light, Arial, Helvetica, sans-serif;
$inter-black: Inter-Black, Arial, Helvetica, sans-serif;
$inter-extra-bold: Inter-ExtraBold, Arial, Helvetica, sans-serif;
$inter-extra-light: Inter-ExtraLight, Arial, Helvetica, sans-serif;
$inter-semi-bold: Inter-SemiBold, Arial, Helvetica, sans-serif;

$poppins-black: Poppins-Black, Arial, Helvetica, sans-serif;
$poppins-black-italic: Poppins-BlackItalic, Arial, Helvetica, sans-serif;
$poppins-bold: Poppins-Bold, Arial, Helvetica, sans-serif;
$poppins-bold-italic: Poppins-BoldItalic, Arial, Helvetica, sans-serif;
$poppins-extra-bold: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
$poppins-extra-bold-italic: Poppins-ExtraBoldItalic, Arial, Helvetica,
    sans-serif;
$poppins-extra-light: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
$poppins-extra-light-italic: Poppins-ExtraLightItalic, Arial, Helvetica,
    sans-serif;
$poppins-italic: Poppins-Italic, Arial, Helvetica, sans-serif;
$poppins-light: Poppins-Light, Arial, Helvetica, sans-serif;
$poppins-light-italic: Poppins-LightItalic, Arial, Helvetica, sans-serif;
$poppins-medium: Poppins-Medium, Arial, Helvetica, sans-serif;
$poppins-medium-italic: Poppins-MediumItalic, Arial, Helvetica, sans-serif;
$poppins-regular: Poppins-Regular, Arial, Helvetica, sans-serif;
$poppins-semi-bold: Poppins-SemiBold, Arial, Helvetica, sans-serif;
$poppins-semi-boldItalic: Poppins-SemiBoldItalic, Arial, Helvetica, sans-serif;
$poppins-thin: Poppins-Thin, Arial, Helvetica, sans-serif;
$poppins-thin-italic: Poppins-ThinItalic, Arial, Helvetica, sans-serif;

/*** Responsivo ***/
@mixin largemobile() {
    @media screen and (max-width: 1999px) {
        @content;
    }
}

@mixin mobile() {
    @media screen and (max-width: 899px) {
        @content;
    }
}
@mixin miniMobile() {
    @media screen and (max-width: 767px) {
        @content;
    }
}

html,
body {
    width: 100%;
    margin: 0;
    background-color: #fcfcfe !important;
    overflow: auto;
    position: relative;
}

.container {
    padding: 0;
}
.content {
    max-width: 100%;
}

html,
body,
p,
li,
a {
    font-family: $inter-regular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $poppins-regular;
}

a {
    color: $color-four;
    text-decoration: none !important;
}
a:active {
    font-weight: bold;
}

form,
input,
label {
    font-family: $inter-regular !important;
}

.hidden-element {
    display: none !important;
}

body::-webkit-scrollbar {
    width: 15px;
    height: 6px;
}
body::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(63, 59, 63, 0.1);
}
body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}
body::-webkit-scrollbar-thumb:hover {
    background: rgba(144, 143, 143, 0.4);
}
body::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.2);
}

.frame::-webkit-scrollbar {
    -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
    width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
    height: 11px;
}

.frame::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 1px solid black;
    background-color: rgba(0, 0, 0, 0.5);
}

.d-flex {
    display: flex;
}

.j-c-c {
    justify-content: center;
}

.h-55 {
    height: 55px !important;
}

.m-0 {
    margin: 0px !important;
}
.m-r-10 {
    margin-right: 10px !important;
}
.m-r-20 {
    margin-right: 20px !important;
}
.m-t-7 {
    margin-top: 7px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-15 {
    margin-top: 15px !important;
}
.m-t-20 {
    margin-top: 20px !important;
}
.p-tb-15 {
    padding: 15px 0 !important;
}
.p-r-25 {
    padding-right: 25px;
}
.m-b-10 {
    margin-bottom: 10px !important;
}
.m-b-15 {
    margin-bottom: 15px !important;
}
.m-b-20 {
    margin-bottom: 20px !important;
}
.m-b-30 {
    margin-bottom: 30px !important;
}

.no-uppercase {
    text-transform: none !important;
}

.MuiCircularProgress-svg {
    display: block;
    color: #642ba5;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    color: #5c39b3 !important;
}

/*** Login Page ***/
/*** Forgot Page ***/
/*** Reset Page ***/
.login-page,
.forgot-password-page,
.reset-password-page {
    width: 100vw !important;
    height: 100% !important;
    min-height: 100vh !important;
    overflow: hidden;

    background: -webkit-linear-gradient(#441556, #7544ef) !important;
    background: -moz-linear-gradient(#441556, #7544ef);
    background: rgb(63, 94, 251);
    background: linear-gradient(#441556, #7544ef);

    .login-image-container,
    .forgot-password-image-container,
    .reset-password-image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        .login-image,
        .forgot-password-image {
            width: 55%;
        }
        .reset-password-image {
            width: 35%;
        }
    }

    .forgot-password-icon-container,
    .reset-password-icon-container {
        justify-content: center;
        .forgot-password-icon,
        .reset-password-icon {
            &.error-icon {
                font-size: 30px;
                color: $color-seven;
            }
            &.success-icon {
                font-size: 30px;
                color: $color-eight;
            }
        }
    }

    @include mobile() {
        .login-image-container,
        .forgot-password-image-container,
        .reset-password-image-container {
            display: none;
        }
    }

    .login-component-container,
    .forgot-password-component-container,
    .reset-password-component-container {
        .login-component,
        .forgot-password-component,
        .reset-password-component {
            position: relative;

            padding: 10% 15%;

            .login-form,
            .forgot-password-form,
            .reset-password-form {
                display: flex;
                align-content: center;
                justify-content: center;

                // position: absolute;
                &.no-form {
                    position: relative;
                    padding: 0 75px;
                }

                width: 70%;
                height: auto;

                border-radius: 5px;
                background: white;

                .login-title-container,
                .forgot-password-title-container,
                .reset-password-title-container {
                    padding: 50px 0;

                    .login-title,
                    .forgot-password-title,
                    .reset-password-title {
                        color: $color-four;
                        font-family: $poppins-regular;
                        font-size: 25px;
                        margin: 0;
                    }
                    .forgot-password-title,
                    .reset-password-title {
                        font-size: 20px;
                        &.custom-text {
                            font-size: 18px;
                            color: $color-six;
                        }
                    }
                    .reset-password-title {
                        color: $color-six;
                    }

                    @include miniMobile() {
                        .login-title {
                            font-size: 21px;
                        }
                        .forgot-password-title,
                        .reset-password-title {
                            font-size: 16px;
                            &.custom-text {
                                font-size: 14px;
                                color: $color-six;
                            }
                        }
                    }
                }

                .forgot-password-title-container,
                .reset-password-title-container {
                    justify-content: center;
                }

                .forgot-password-text-container,
                .reset-password-text-container {
                    justify-content: center;
                    .forgot-password-text,
                    .reset-password-text {
                        color: $color-six;
                        font-family: $inter-regular;
                        font-size: 15px;
                        letter-spacing: 0.05rem;
                        &.custom-text {
                            font-size: 16px;
                            font-family: $inter-regular;
                            color: $color-four;
                        }
                    }
                    &.custom-text-container {
                        position: absolute;
                        bottom: 50px;
                        .custom-text {
                            font-size: 13px;
                            font-family: $inter-light;
                            color: $color-six;
                        }
                    }
                }

                .reset-password-button-container {
                    justify-content: center;
                    .reset-password-button {
                        font-size: 16px;
                        font-family: $inter-bold;
                        text-transform: none;
                    }
                }

                .login-logo-container,
                .forgot-password-logo-container,
                .reset-password-logo-container {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    padding: 15px 0 15px 15px;
                    &.custom-logo {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                    }
                    .login-logo,
                    .forgot-password-logo,
                    .reset-password-logo {
                        width: 80px;
                        height: 60px;
                    }
                }

                form {
                    position: relative;
                    width: 70%;
                    height: auto;
                    padding-bottom: 40px;
                    .custom-field-container,
                    .login-field-container,
                    .forgot-password-field-container,
                    .reset-password-field-container {
                        // border: 1px solid;
                        padding: 12px 0;
                        .custom-field {
                            margin: 5px 0;
                            .custom-textfield {
                                width: 100% !important;
                            }
                        }
                    }

                    .login-button,
                    .forgot-password-button,
                    .reset-password-button {
                        width: 100%;
                        margin: 20px 0;
                        padding: 10px 0;
                        background: $color-three;
                        font-family: $inter-regular;
                        font-size: 14px;
                        text-transform: none;
                    }

                    .forgot-password-link-container {
                        justify-content: center;
                        .forgot-password-link {
                            color: $color-five;
                            font-size: 14px;
                            font-family: $inter-regular;
                            text-align: center;
                            text-decoration: none;
                        }
                    }

                    .error-container {
                        width: 100%;
                        padding: 5px 0 0;
                        .error-text {
                            font-family: $inter-light;
                            color: #e00000;
                            font-size: 13px;
                            margin: 0;
                        }
                    }

                    .login-loader,
                    .forgot-password-loader,
                    .reset-password-loader {
                        justify-content: center;
                        padding: 25px 0 5px;
                        span {
                            width: 25px !important;
                            height: 25px !important;
                        }
                    }

                    .login-response,
                    .forgot-password-response,
                    .reset-password-response {
                        margin: 10px 0 20px;
                        text-align: center;
                        .error-container {
                            .error-text {
                                font-family: $inter-light;
                                font-size: 16px;
                            }
                        }
                        .success-container {
                            padding: 5px 0 0;
                            width: 100%;
                            .success-text {
                                font-size: 16px;
                                font-family: $inter-light;
                                color: $color-four;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.form-footer-text-container {
    justify-content: center;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 15px;
    right: auto;
    left: auto;
    .copyright-icon {
        font-size: 11px;
        color: $color-four;
    }
    .form-footer-text {
        font-family: $inter-regular;
        color: $color-four;
        font-size: 14px;
        margin: 0;
    }
}

.justify-content-start {
    justify-content: start !important;
}

@mixin svgColor() {
    div {
        div {
            svg {
                @content;
            }
        }
    }
}

@mixin linkStyle {
    color: $color-six;
    font-family: $inter-regular;
    text-decoration: none !important;
}

.css-16ac5r2-MuiButtonBase-root:active {
    background-color: #000;
}

hr.MuiDivider-root {
    width: 100%;
    margin: 0;
    padding: 0 0 5px;
    color: #e5e6e8;
}

.app-main-layout {
    min-height: 100vh;
    div {
        header.layout-header {
            border: none;
            background: #ffffff;
            border-bottom: 1px solid #e9e8e9;
            box-shadow: none;
            div {
                button {
                    svg {
                        color: #611c7c;
                    }
                }

                div {
                    font-family: $poppins-semi-bold;
                    color: $color-four;
                    padding-left: 20px;
                }
            }
        }

        div.layout-menu {
            div {
                border-color: #e9e8e9;
                color: #611c7c;
                div.layout-menu-header {
                    img.layout-logo {
                        width: 125px;
                        position: absolute;
                        left: 10px;
                    }
                }
                div.layout-menu-list {
                    ul {
                        li {
                            a {
                                @include linkStyle;

                                @include svgColor() {
                                    color: #611c7c;
                                }
                            }

                            &.active {
                                background: #341f72 !important;
                            }
                        }
                        @include svgColor() {
                            color: #611c7c;
                        }
                    }
                }
                .layout-menu-submenu {
                    svg {
                        color: #611c7c;
                    }
                }
                .layout-menu-submenu-options {
                    div {
                        // background-color: #F1ECFF;

                        div {
                            div {
                                a {
                                    @include linkStyle;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.div.layout-menu-list:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04) !important;
}

/** MARGENES Y PADDINGS **/

.margin-0 {
    margin: 0 !important;
}

/** ELEMENTS **/

button {
    height: 45px;
    padding: 0 20px;
    text-align: center;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-family: $inter-medium;
    letter-spacing: 0.5px;
    color: $color-two;
    background-color: $color-three;
    margin: 10px 0;
    transition-duration: 0.4s;
    cursor: pointer;
    icon {
        font-size: 16px;
        font-weight: 700;
        margin: 0 10px 0 0;
    }
}

button:hover {
    background-color: #491881;
}

.svg {
    font-size: 17px !important;
    top: 50%;
    margin: 0 10px 0 0;
    vertical-align: text-bottom !important;
}

/*** CONTENT ***/

.page-header {
    position: relative;
    margin-bottom: 15px;
    margin-top: 10px;
    display: flex;
    align-items: center;

    h3 {
        font-family: $poppins-regular;
        font-size: 22px;
        margin: 15px 0 23px;
    }
    &.ticket-detail {
        margin: 0 !important;
        display: flex;
        align-items: center;
        margin-top: -7px !important;
        svg {
            font-size: 15px;
            margin-right: 10px;
            font-weight: 700;
        }
        svg:hover {
            color: #9c27b0;
        }
        h3 {
            font-size: 21px;
            display: block;
        }
        h3:first-letter {
            text-transform: uppercase;
        }
        .right {
            position: absolute;
            width: 100%;

            .ticket {
                display: flex;
                align-items: center;
                color: #591a64;
                h4 {
                    font-size: 17px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    align-content: center;

                    svg {
                        font-size: 15px !important;
                        margin-top: 0 !important;
                    }
                }
            }
            .counter {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .item {
                    color: #591a64;
                    padding: 0 30px;
                    text-align: center;
                    background-color: white;
                    border: solid 1px #d8d7db;
                }
            }
        }
    }
}

.right,
.right-data {
    position: absolute;
    right: 0;
}

.ticket-bar {
    background: #fbf9ff;
    margin: 0;
    display: block;
    padding: 85px 20px 0;
    position: fixed;
    top: 0;
    right: 0;
    border: 1px solid #e9e8e9;
    border-radius: 0 0 15px 15px;
    .ticket-id-status {
        margin: 5px 0;
        display: flex;
        align-items: center;
        h2 {
            font-size: 20px;
            margin-bottom: 0;
            margin-right: 20px !important;
            display: flex;
            font-family: $poppins-regular;
            align-items: center;
            color: #441556;
        }
    }
}

/*** TICKETS ***/

.support-tickets-user-datagrid {
    height: 500px;
    width: 100%;
}

.MuiDataGrid-main {
    font-family: $inter-regular;
    font-size: 16px;
    color: #212122;
    overflow-y: auto !important;

    .MuiDataGrid-columnHeaderTitle {
        font-family: $poppins-semi-bold;
        color: rgba(0, 0, 0, 0.87);
    }
    .MuiDataGrid-row:hover {
        background-color: transparent !important;
    }
    .MuiDataGrid-row.Mui-selected {
        background-color: rgb(255, 255, 255) !important;
    }
    .MuiDataGrid-row:nth-child(even) {
        background-color: rgb(249 245 251) !important;
    }
}

.MuiDataGrid-main::-webkit-scrollbar {
    width: 12px;
}

.MuiDataGrid-main::-webkit-scrollbar-track {
    background-color: #ede6e6;
    border: 1px solid #e0e0e0;
}

.MuiDataGrid-main::-webkit-scrollbar-thumb {
    background-color: #32069a23;
}

.ticket-time {
    color: #7d4792;
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 10px 20px;
    margin-left: 30px;
}

.ticket-data {
    width: 100%;
    height: auto;
    text-align: left;
    border: 1px solid #dfdfdf;
    border-radius: 15px;
    font-size: 16px;
    color: $color-one;
    background-color: $color-two;
    padding: 15px 20px;
    margin: 0 0 20px !important;
    font-family: $inter-regular;
    h4 {
        margin: 0 !important;
    }
    p {
        margin: 0 !important;
    }
    .basic {
        padding: 5px 0;

        h4 {
            text-transform: none !important;
            margin: 0;
            color: #441556;
            margin: 0 !important;
        }
        p {
            font-size: 15px;
            margin: 0 !important;
        }
        .left-data {
            display: flex;
            flex-direction: column;
            align-items: end;
            svg {
                color: #ab8ab8;
                font-size: 17px;
            }
            .type-label {
                font-size: 13px;
                letter-spacing: 1px;
                font-family: $inter-semi-bold;
                width: 100px;
                line-height: 0;
                color: #ffffff;
                padding: 0 12px;
                border-radius: 5px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            #SIM {
                background-color: #a34398;
            }
            #eSIM {
                background-color: #362e7d;
            }
            #MiFi {
                background-color: #e17522;
            }
            #IoT {
                background-color: #1863c7;
            }
        }
    }
    .secondary {
        padding: 5px 0;
        svg {
            color: #441556;
        }
        h4 {
            font-size: 18px;
            margin: 0 0 5px;
            color: #441556;
        }
        p {
            margin: 0;
            font-size: 15px;
        }
        .status {
            font-weight: 600;
            border-radius: 5px;
            color: #441556;
            margin-bottom: 4px;
        }
        svg:hover {
            cursor: pointer;
            background-color: #f4f4f4;
        }
    }
    .numeric {
        h4 {
            color: #441556;
        }
        p,
        h4 {
            margin: 5px 0;
            font-size: 16px;
        }
    }
    hr {
        color: #d9d9d9;
    }
}

.info-box {
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    text-align: left;
    border: 1px solid #dfdfdf;
    border-radius: 15px;
    font-size: 16px;
    color: $color-one;
    background-color: $color-two;
    margin: 20px 0 30px;
    font-family: $inter-regular;
    .header {
        padding: 10px 20px;
        display: flex;
        align-content: center;
        .section-one {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            span {
                color: #611c7c;
                font-weight: 900;
                font-size: 16px;
                margin-right: 5px;
                &.name {
                    margin-left: 23px;
                }
            }
            .data {
                color: #484848;
                text-transform: none;
                justify-content: flex-start;
            }
        }
        .section-two {
            .line-status {
                width: 100%;
                display: block;
                position: relative;
                padding: 8px;
                color: #45976e;
                background-color: #c1f5cf;
                border: none;
                border-radius: 5px;
                margin: 0 auto;
                font-weight: bold;
                text-align: center;
            }
            .line-status-Idle {
                width: 100%;
                display: block;
                position: relative;
                padding: 8px;
                color: #ae5fcc;
                background-color: #f2e1f8;
                border: none;
                border-radius: 5px;
                margin: 0 auto;
                font-weight: bold;
                text-align: center;
            }
            .line-status-Suspendida {
                width: 100%;
                display: block;
                position: relative;
                padding: 8px;
                color: #cb2020;
                background-color: #ff9797;
                border: none;
                border-radius: 5px;
                margin: 0 auto;
                font-weight: bold;
                text-align: center;
            }
            .line-status-Barring {
                width: 100%;
                display: block;
                position: relative;
                padding: 8px;
                color: #dc6c16;
                background-color: #fcc48b;
                border: none;
                border-radius: 5px;
                margin: 0 auto;
                font-weight: bold;
                text-align: center;
            }

            .line-status-Predeactivate {
                width: 100%;
                display: block;
                position: relative;
                padding: 8px;
                color: #858585;
                border: none;
                border-radius: 5px;
                margin: 0 auto;
                font-weight: bold;
                text-align: center;
            }

            .line-status-Desactivate {
                width: 100%;
                display: block;
                position: relative;
                padding: 8px;
                color: #ffffff;
                background-color: #8d8d8d;
                border: none;
                border-radius: 5px;
                margin: 0 auto;
                font-weight: bold;
                text-align: center;
            }
        }
    }
    .columns-header {
        border-top: 1px solid #dfdfdf;
        padding: 10px 20px 10px 0;
        font-weight: 900;
        color: #611c7c;
        background-color: #ffffff;
        .border {
            padding-left: 20px;
            border-right: 1px solid #dfdfdf;
        }
        .last {
            padding-left: 20px;
            border: none !important;
        }
    }
    .columns {
        border-top: 1px solid #dfdfdf;
        padding: 0 20px 0 0;
        color: $color-one;
        background-color: #ffffff;
        span {
            text-transform: uppercase;
            font-weight: 900;
            color: $color-five;
            font-size: 13px;
        }
        .border {
            padding-left: 20px;
            border-right: 1px solid #dfdfdf;
        }
        .last {
            padding-left: 20px;
            border: none !important;
        }
        .data {
            color: #484848;
            font-size: 15px;
            margin: 15px 0;
            font-weight: 500;
            text-transform: uppercase;
            font-family: $inter-regular;
            justify-content: flex-start;
        }
    }
}

.list-box,
.button-box {
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    text-align: center;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    font-size: 16px;
    background-color: $color-two;
    &.button-box {
        padding: 16px 15px !important;
        margin-bottom: 30px !important;
        overflow: auto;
        height: 305px;
        border-radius: 15px;
        &.button-box::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &.button-box::-webkit-scrollbar-track {
            border-radius: 10px;
            background: rgba(55, 55, 55, 0.1);
        }
        &.button-box::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(131, 126, 131, 0.2);
        }
        &.button-box::-webkit-scrollbar-thumb:hover {
            background: rgba(0, 0, 0, 0.4);
        }
        &.button-box::-webkit-scrollbar-thumb:active {
            background: rgba(0, 0, 0, 0.9);
        }

        h5 {
            color: #212122;
            font-family: $poppins-regular;
        }
        .button {
            border: none !important;
            font-size: 13px;
            padding: 15px 10px;
            background: #f9f8fb;
            color: #625879;
            justify-content: flex-start;
            margin: 0 0 10px;
            text-align: start;
        }
        .button:hover {
            background-color: #f0edf6;
        }
    }
    .row {
        padding: 10px 25px;
        border-bottom: 1px solid #dfdfdf;
        justify-content: space-between;
        .avatar {
            font-size: 13px !important;
            font-family: $inter-bold !important;
            background-color: #ffc6f4ab;
            color: #e452b4;
            margin-right: 15px;
            width: 35px;
            height: 35px;
        }
        b {
            font-family: $poppins-regular;
            color: #484848;
            font-size: 14px;
        }
        .events-date {
            p {
                font-size: 14px;
                color: #765881;
            }
        }
        p {
            display: block;
            margin: 12px 0;
        }
        .phone-number {
            font-size: 14.5px;
            color: #504156;
            svg {
                font-size: 15px !important;
                color: #998a9f;
                margin-bottom: 1px;
                margin-right: 5px;
            }
        }
        p:first-letter {
            text-transform: uppercase;
        }
        .user {
            font-size: 16px;
            font-weight: 800;
            margin-right: 10px;
        }
        .section-one {
            text-align: left;
            h4 {
                margin: 0;
                font-size: 15px;
                color: #7f16a9;
            }
            span {
                margin-right: 20px;
            }
            p {
                display: flex;
                align-items: center;
                color: #504156;
                margin: 0;
                font-size: 14px;
                font-family: $poppins-regular;
                svg {
                    font-size: 15px !important;
                    color: #ae9eb4;
                    margin-bottom: 1px;
                    margin-right: 8px;
                }
            }
            button {
                padding: 0;
                text-transform: none !important;
                font-size: 16px;
                color: #63219f !important;
                font-family: $poppins-medium;
                letter-spacing: 0;
            }
            button:hover {
                background-color: transparent;
                text-decoration: underline;
            }
            button:first-letter {
                text-transform: uppercase;
            }
        }
        .section-two {
            text-align: right;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .avatar {
                font-size: 12px !important;
                font-family: $inter-bold !important;
                margin: 0 auto;
                top: 21%;
                background-color: #ffc6f4ab;
                color: #e452b4;
            }
            .dates {
                color: #918894;
                font-size: 13px;
                margin-left: 35px;
                p {
                    line-height: 10px;
                    text-align: right !important;
                }
            }
            .dropdown-status {
                display: block;
                position: relative;
                padding: 5px 15px;
                color: #545cd5;
                background-color: rgba(197, 201, 255, 0.4392156863);
                border: none;
                border-radius: 5px;
                margin: 10px 35px;
                font-weight: 500;
                text-align: center;
                font-size: 13px;
            }
        }
    }
}

.tab-panel {
    padding: 15px 0 !important;
    .comments {
        display: block;
        h2 {
            font-family: $poppins-regular;
            font-size: 18px;
            margin-bottom: 25px;
        }
        .comment-border {
            width: 100%;
            outline: 1px solid #dfdfdf;
            border-radius: 5px;
            .comment-box {
                width: 100%;
                padding: 10px 20px;
                font-family: $inter-regular;
                font-size: 15px;
                margin-top: 5px;
                border: none;
                background-color: white;
            }

            .comment-box:focus {
                outline: none;
            }
        }
        .comment-border:focus-within {
            outline: 2px solid $color-three;
        }
    }

    .ticket-detail-header {
        display: flex;
        align-items: center;
        border-width: 0px;
        h4 {
            font-family: $poppins-regular;
            font-size: 18px;
            margin: 15px 0 20px;
        }
        .select {
            select {
                border-width: 0px !important;
                border: none !important;
            }
        }
        svg {
            color: white !important;
        }
    }
}

.tabs-container {
    width: calc(100% - 16px);
    padding-right: 38px;
    display: block;
}

.events-box {
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    overflow: auto;
    padding: 0 !important;
    font-family: $inter-regular !important;
    p {
        margin: 10px 0 0;
        color: #765881;
        font-family: $inter-regular;
    }
}

.events-box::-webkit-scrollbar {
    width: 12px;
}

.events-box::-webkit-scrollbar-track {
    background-color: rgb(241, 241, 241);
}

.events-box::-webkit-scrollbar-thumb {
    background-color: rgb(153 152 153 / 40%);
}

.no-issues {
    padding: 50px !important;

    h2 {
        color: $color-five;
    }
    p {
        font-size: 16px;
    }
    .no-issues-img {
        width: 15%;
    }
}

.offers-container {
    position: relative;
    display: block;
    margin: 30px 0 10px !important;
    .header {
        color: $color-one;
        font-size: 18px;
        font-family: $poppins-light;
        margin: 0;
    }
    .chart-container {
        margin: 0;
        background-color: #fcfcfe !important;
        .chart {
            height: 200px;
        }
        h3 {
            font-size: 20px;
            margin: 0;
            color: $color-one;
            font-family: $poppins-regular;
        }
        h4 {
            font-size: 18px;
            margin: 0;
            color: $color-one;
        }
        p {
            font-size: 16px;
            margin: 5px 0;
        }
        .colored {
            color: $color-one;
            font-weight: 700;
        }
        .available {
            background: #fbf9ff;
            border-radius: 15px;
            border: 1px solid #d3d3d3;
            text-align: left;
        }
        svg {
            color: #441556;
        }
        svg:hover {
            cursor: pointer;
            background-color: #f4f4f4;
        }
    }
    .info {
        margin: 5px 0;
        padding: 10px 0 0;
        h3 {
            color: #6e6589;
            font-weight: 900;
            margin: 0;
            font-size: 14px;
            padding-bottom: 5px;
            border-bottom: 1px solid #d8d8d8;
        }
        h4 {
            margin: 10px 0;
            font-size: 15px;
            font-weight: 200;
        }
        &.bar {
            h3 {
                border-bottom: none !important;
            }
        }
    }
}

/* MODAL */

.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgb(156 151 167 / 17%);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 1000;
}

.modalContainer {
    border-radius: 12px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 20%) 5px 5px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column !important;
    padding: 20px 30px;
    text-align: left;
    z-index: 999 !important;
    h2 {
        font-size: 20px;
    }
    h3 {
        font-family: $inter-regular;
        font-size: 17px;
        font-weight: 500;
    }
}

.modalContainer .title {
    display: flex;
    text-align: left;
    font-size: 18px;
    height: 36px;
    margin-bottom: 25px;
    h3 {
        font-weight: 700;
        color: #050505;
        font-size: 20px;
        margin: 5px 0 10px !important;
        font-family: $poppins-regular !important;
    }
    h4 {
        margin: 5px 0 10px !important;
        font-weight: 500 !important;

        font-family: $inter-regular;
    }
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
}

.titleCloseBtn button {
    background-color: transparent;
    color: #cbc7c7;
    border: none;
    display: flex;
    align-content: flex-start;
    padding: 0;
}

.modalContainer .body {
    display: flex;
    justify-content: center;
    font-size: 1.7rem;
    text-align: center;
}

.modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 20px 0 0;
}

.modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px 0;
    border: none;
    background-color: #642ba5;
    color: white;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
    text-transform: none;
    font-weight: 400;
}

#cancelBtn {
    background-color: transparent;
    color: #7b52a6;
    letter-spacing: 0;
    font-size: 16px;
}

.required-incidence,
.error-incidence {
    color: #ff404f;
    text-align: left;
    font-size: 14px;
}

.required-incidence::before {
    display: inline;
    content: "⚠ ";
}

.required-incidence.no-before-icon::before {
    display: none;
    content: "";
}

.response-container {
    .response-title {
        font-family: $inter-bold;
    }
}
/* COMENTARIOS*/

.comment-section {
    padding-top: 20px;
    display: grid !important;
    .comment-container {
        width: 100%;
        .comment-box {
            padding: 10px 20px;
            font-family: $inter-regular !important;
            font-size: 16px;
            margin-top: 10px;
            border: 1px solid #d5d5d5;
            padding: 20px;
            border-radius: 10px;
        }
        .comment-box:focus {
            border: 2px solid;
            border-color: rgb(170, 121, 190) !important;
            outline: none !important;
        }
        .comment-btn {
            height: 50px;
            padding: 0 20px;
            text-align: center;
            border-radius: 5px;
            font-size: 15px;
            font-family: Inter-Medium, Arial, Helvetica, sans-serif;
            color: #8361aa;
            background-color: #f2eef9 !important;
            border: 1px solid #efe1ff;
            margin: 10px 0;
            transition-duration: 0.4s;
            cursor: pointer;
        }
        .comment-btn:hover {
            background-color: #f1eaff !important;
            border: 1px solid #b8a4ce !important;
        }
    }
}

/* LEADS */
.lead-container {
    padding: 0;
    position: relative;
    margin-top: -10px;
    svg {
        font-size: 14px;
        margin-right: 15px;
    }
    .lead-actions-container {
        width: 15%;
        background: #fbf9ff;
        height: 100vh;
        margin: 0;
        padding: 70px 20px 0;
        position: fixed;
        top: 0;
        right: 0;
        border: 1px solid #e9e8e9;
        overflow-y: auto !important;
        .save {
            svg {
                font-size: 18px !important;
            }
        }
        svg {
            font-size: 16px;
            margin: 0 !important;
            color: #9158a7;
        }
        .lead-actions {
            position: relative;
            h1 {
                font-family: $poppins-regular;
                color: $color-four;
            }
            h2 {
                font-size: 16px;
                color: $color-four;
                margin: 20px 0 15px;
            }
            .bottom-container {
                position: absolute;
                bottom: 0;
                .link-box {
                    background-color: #fff;
                    border: 1px solid #e5e5e5;
                    border-radius: 10px;
                    padding: 0 20px;
                    margin-bottom: 10px;
                    p {
                        font-size: 15px;
                    }
                }

                .main-btn,
                .confirm-btn,
                .copy-btn {
                    width: 100%;
                    font-size: 15px;
                    font-family: $inter-medium;
                    text-transform: none;
                    &.main-btn {
                        background: #642ba5 !important;
                        margin: 10px 0 15px;
                        height: 55px;
                    }
                    &.main-btn:hover {
                        background-color: #45048e !important;
                    }
                    &.copy-btn {
                        background-color: #fff;
                        border: 1px solid #e5e5e5;
                        color: #642ba5 !important;
                        height: 35px !important;
                        font-size: 14px;
                    }
                    &.copy-btn:hover {
                        background-color: #f1eaff !important;
                    }
                    &.confirm-btn {
                        background-color: transparent !important;
                        border: 1px solid #642ba5 !important;
                        color: #642ba5 !important;
                        margin: 20px 0 5px;
                        height: 45px;
                    }
                    &.confirm-btn:hover {
                        background-color: #f1eaff !important;
                        border: 1px solid #b8a4ce !important;
                    }
                }
            }
        }
    }
    @include mobile() {
        .lead-actions-container {
            width: 100% !important;
            background: #fbf9ff;
            margin: 0;
            padding: 10px 20px !important;
            position: relative;
            border: none !important;
            border-radius: 10px;
            margin-top: 30px;
        }
    }
    .lead-form {
        border-radius: 15px;
        border: 1px solid #d5d5d5;
        padding: 5px 20px 20px;
        h1 {
            font-family: $poppins-regular;
            font-size: 20px;
            padding: 0 0 13px;
        }
        .inline {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .imei-btn,
        .postal-code-btn {
            height: 55px;
            padding: 0 20px;
            text-align: center;
            border-radius: 5px;
            font-size: 15px;
            font-family: $inter-medium;
            color: #8361aa;
            background-color: #f2eef9 !important;
            border: 1px solid #efe1ff;
            margin: 10px 0;
            transition-duration: 0.4s;
            cursor: pointer;
            &.postal-code-btn {
                margin-top: -5px;
            }
        }
        .imei-btn:hover {
            color: #642ba5;
            background-color: #ece3f9 !important;
            border: 1px solid #efe1ff;
        }
    }
}

.lead-actions-container::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa; /* or add it to the track */
}

/* Add a thumb */
.lead-actions-container::-webkit-scrollbar-thumb {
    background: #000;
}

.lead-container {
    form {
        width: 100%;
    }
}

.lead-form {
    .lead-form-input {
        .lead-form-input-component {
        }
        .lead-form-input-error {
            margin-top: 5px;
        }
    }
}

.link-box {
    p {
        inline-size: 100%;
        overflow-wrap: break-word;
    }
}

p.copy-success {
    width: 100%;
    text-align: center;
    font-family: $inter-regular;
    color: #06ae00;
}

.modal-imei-label {
    color: #6e6b6b;
    margin: 5px 0;
}

.modal-imei-info {
    font-family: $inter-bold;
    color: #441556;
    font-size: 18px;
}

.error-modal {
    .modal-modal-title {
        color: #e62121;
    }

    .modal-modal-icon {
        svg {
            color: #e62121;
        }
    }
}

.success-modal {
    .modal-modal-title {
        color: #724ecb;
    }

    .modal-modal-icon {
        svg {
            color: #724ecb;
        }
    }
}

.lead-detail-link {
    cursor: pointer;
    font-family: $poppins-medium;
    color: #642ba5;
}

.order-detail-link {
    cursor: pointer;
    font-family: $poppins-medium;
    color: #110222;
}

.assigned-to-other {
    cursor: auto;
}

.imei-input .MuiInputAdornment-positionEnd span {
    color: #642ba5;
    font-weight: 500;

    &.imei-input-null {
        color: rgb(246 17 17);
    }
}

.leads-list {
    .MuiDataGrid-root {
        height: 71vh !important;
        overflow: auto !important;
        .MuiDataGrid-main {
            .MuiDataGrid-row {
                // &:nth-child(even) {
                //     &:hover {
                //         background-color: rgb(249, 237, 255) !important;
                //     }
                // }

                .selected {
                    background-color: #e0d9f3;
                    padding: 0 25px;
                }

                &:hover {
                    .selected {
                        background-color: #ffebfb;
                        color: #42008c;
                    }
                }
                .MuiDataGrid-cell:first-child {
                    justify-content: center;
                }
            }
        }
    }

    .MuiDataGrid-columnHeaders {
        .MuiDataGrid-columnHeadersInner {
            .MuiDataGrid-columnHeader:first-child {
                .MuiDataGrid-columnHeaderTitleContainer {
                    justify-content: center;
                }
            }
        }
    }

    color: red;
}

.customDropdown .MuiSelect-select {
    text-transform: uppercase !important;
}

li.customDropdownItem {
    text-transform: capitalize !important;
}

.lead-container
    .lead-actions-container
    .lead-actions
    .bottom-container
    .main-btn.main-btn.Mui-disabled {
    color: #919191 !important;
    background: #dbdbdb !important;
}

.filters-button-container {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0 0 0 25px;
}

.displayDatepickers {
    &.shown {
        display: block;
    }
    &.hidden {
        display: none;
    }
}

.dropdown-filters-container {
    padding: 25px 0;
}

.kpi-container {
    h2 {
        font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
        font-size: 22px;
        margin: 10px 0 0;
    }
}

.line {
    display: inline-block;
    width: 250px;
    margin-left: 15px;
    height: 1px;
    background-color: #ccc;
    vertical-align: middle;
}

.kpi-graphic-container {
    margin-top: 0;
    background: white;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    padding: 30px 0;
    margin-bottom: 30px !important;
}

.kpi-empty {
    margin: 0 auto;
    text-align: center;
    line-height: 18px;
    color: #8b8b8b;
    font-size: 14px;
    font-family: $inter-regular;
    background: white;
    padding: 30px;
    border: 1px solid #e7e7e7;
    border-radius: 15px;
    svg {
        font-size: 26px;
        color: #e0e0e0;
    }
}

.mkt-datagrid {
    height: 400px !important;
    background-color: white;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.2);
    padding: 10px;
    white-space: nowrap;
}

.lead-imei-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .lead-imei {
        background: #f8f9fb;
        border-radius: 10px;
        border: 1px solid #dfdfdf;
        padding: 7px 15px 13px;
        &.column-imei {
            margin: 15px 15px 0 0;
        }
        &.column-right {
            margin-right: 0 !important;
        }
        .imei-blocked {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }
}

.device-info-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    h3 {
        color: #171717 !important;
        padding-left: 15px;
        margin: 0 0 5px;
        font-size: 18px;
    }
    .device-info {
        background: #f8f9fb;
        border-radius: 10px;
        border: 1px solid #dfdfdf;
        padding: 10px 15px 13px;
        line-height: 30px;
        p {
            color: #626161;
            margin: 0 0 5px;
            font-size: 14px;
        }
        span {
            font-size: 17px;
            color: #422b4c;
            font-weight: 600;
        }
        hr {
            width: 100%;
        }
        .device-right-data {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        &.device-overflow {
            height: 178px;
            overflow: auto;
            span {
                font-size: 14px;
            }
        }
        &.device-overflow::-webkit-scrollbar {
            width: 8px;
        }

        &.device-overflow::-webkit-scrollbar-track {
            background: #ededee;
        }

        &.device-overflow::-webkit-scrollbar-thumb {
            background: #e2dce2;
        }
        .type-label {
            font-size: 13px;
            letter-spacing: 1px;
            font-family: $inter-semi-bold;
            width: 100px;
            max-height: 35px !important;
            line-height: 0;
            color: #ffffff;
            padding: 0 12px;
            border-radius: 5px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        #SIM {
            background-color: #a34398;
        }
        #eSIM {
            background-color: #362e7d;
        }
        #MiFi {
            background-color: #e17522;
        }
        #IoT {
            background-color: #1863c7;
        }
    }
}

/** DASHBOARD **/

.dashboard-container {
    width: 80% !important;
    margin: 0 auto;
    height: 100%;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    .dashboard-text {
        align-items: flex-start;
        h1 {
            font-family: $poppins-bold;
            font-size: 40px;
            margin: 0 0 30px;
        }
        p {
            font-size: 18px;
            line-height: 34px;
        }
    }
    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 75%;
        }
    }
}

.filters-container {
    .filter-container {
        padding: 0 0 15px 15px;
    }
}

.small-button {
    height: 54px !important;
    color: #ffffff !important;
    background: #624591 !important;
    align-items: center !important;
    justify-content: center !important;
    svg {
        font-size: 19px !important;
        margin: 0 !important;
    }
}

.css-qkprq3-MuiDataGrid-root .MuiDataGrid-virtualScroller {
    min-height: 60vh !important;
}

.MuiDataGrid-overlayWrapperInner {
    height: 390px !important;
}

.ordenes-de-compra-detalle {
    overflow: auto;
    max-height: 530px;
    display: block !important;
    margin-top: 40px;
    h1 {
        font-size: 22px;
    }
    h2 {
        color: #333333;
    }
}

.no-prod {
    position: fixed;
    width: 100%;
    top: 0px;
    padding: 0px 0;
    background: red;
    text-align: center;
    font-weight: 600;
    color: white;
    z-index: 9999;
}

section.no-prod-section {
    .no-prod-container {
        padding-top: 60px;
    }

    .page-header {
        margin-top: 0;
    }

    .drawer-header-customized {
        min-height: 10px !important;
    }
}

.modal-widget-component {
    margin-top: 5px;
}

.datepicker-cls {
    width: 100%;
}

.icon-button-search {
    background: "#642BA5";
    color: "white";
    border-radius: "6px";
}

.custom-linear-progress-container {
    span {
        height: 15px !important;
    }
}

.device-info-container {
    .ticket-detail-header {
        h4 {
            text-transform: uppercase;
        }
    }
}

.zero-number {
    color: #df62bf;
}

.reportsTableCLS {
    max-height: 60vh;
    overflow: auto !important;
}

.table-accordion-container {
    .MuiPaper-root {
        background-color: #fcfcfe;

        .MuiAccordionSummary-content p.MuiTypography-root {
            font-weight: 700;
            color: #3b3b3b;
        }

        .MuiAccordionSummary-expandIconWrapper {
            background: #e5e5e5;
            border-radius: 50px;
        }

        .MuiAccordionDetails-root {
            .MuiPaper-root {
                max-height: 350px;
            }

            .MuiTable-root {
                background-color: #ffffff;
            }
        }
    }
}

.my-sales.container-2 {
    margin-top: 50px;
}

.MuiAccordion-root {
    .MuiAccordionDetails-root {
        th,
        td {
            padding: 0 16px;
            height: 46px;
        }
    }
}

.chargeback-row {
    background: linear-gradient(to right, #fff4ee 50%, #fff4ee 50%) !important;
}

.fraud-row {
    background: linear-gradient(to right, #ffecec 50%, #ffecec 50%) !important;
}

.chargeback-and-fraud-row {
    background: linear-gradient(to right, #ffecec 50%, #ffecec 50%) !important;
}
