@charset "UTF-8";
/*** FONTS ***/
/* INTER FONT*/
@font-face {
  font-family: "Inter-Black";
  src: url("../fonts/inter/Inter-Black.ttf");
  src: local("Inter Black"), local("Inter-Black"), url("../fonts/inter/Inter-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("../fonts/inter/Inter-Bold.ttf");
  src: local("Inter Bold"), local("Inter-Bold"), url("../fonts/inter/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-ExtraBold";
  src: url("../fonts/inter/Inter-ExtraBold.ttf");
  src: local("Inter ExtraBold"), local("Inter-ExtraBold"), url("../fonts/inter/Inter-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-ExtraLight";
  src: url("../fonts/inter/Inter-ExtraLight.ttf");
  src: local("Inter ExtraLight"), local("Inter-ExtraLight"), url("../fonts/inter/Inter-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Light";
  src: url("../fonts/inter/Inter-Light.ttf");
  src: local("Inter Light"), local("Inter-Light"), url("../fonts/inter/Inter-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Medium";
  src: url("../fonts/inter/Inter-Medium.ttf");
  src: local("Inter Medium"), local("Inter-Medium"), url("../fonts/inter/Inter-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Regular";
  src: url("../fonts/inter/Inter-Regular.ttf");
  src: local("Inter Regular"), local("Inter-Regular"), url("../fonts/inter/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("../fonts/inter/Inter-SemiBold.ttf");
  src: local("Inter SemiBold"), local("Inter-SemiBold"), url("../fonts/inter/Inter-SemiBold.ttf") format("truetype");
}
/* POPPINS FONT*/
@font-face {
  font-family: "Poppins-Black";
  src: url("../fonts/poppins/Poppins-Black.otf");
  src: local("Poppins Black"), local("Poppins-Black"), url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-BlackItalic";
  src: url("../fonts/poppins/Poppins-BlackItalic.otf");
  src: local("Poppins BlackItalic"), local("Poppins-BlackItalic"), url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/poppins/Poppins-Bold.otf");
  src: local("Poppins Bold"), local("Poppins-Bold"), url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-BoldItalic";
  src: url("../fonts/poppins/Poppins-BoldItalic.otf");
  src: local("Poppins BoldItalic"), local("Poppins-BoldItalic"), url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("../fonts/poppins/Poppins-ExtraBold.otf");
  src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"), url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
  src: local("Poppins ExtraBoldItalic"), local("Poppins-ExtraBoldItalic"), url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-ExtraLight";
  src: url("../fonts/poppins/Poppins-ExtraLight.otf");
  src: local("Poppins ExtraLight"), local("Poppins-ExtraLight"), url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-ExtraLightItalic";
  src: url("../fonts/poppins/Poppins-ExtraLightItalic.otf");
  src: local("Poppins ExtraLightItalic"), local("Poppins-ExtraLightItalic"), url("../fonts/poppins/Poppins-ExtraLightItalic.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-Italic";
  src: url("../fonts/poppins/Poppins-Italic.otf");
  src: local("Poppins Italic"), local("Poppins-Italic"), url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("../fonts/poppins/Poppins-Light.otf");
  src: local("Poppins Light"), local("Poppins-Light"), url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-LightItalic";
  src: url("../fonts/poppins/Poppins-LightItalic.otf");
  src: local("Poppins LightItalic"), local("Poppins-LightItalic"), url("../fonts/poppins/Poppins-LightItalic.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/poppins/Poppins-Medium.otf");
  src: local("Poppins Medium"), local("Poppins-Medium"), url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("../fonts/poppins/Poppins-MediumItalic.otf");
  src: local("Poppins MediumItalic"), local("Poppins-MediumItalic"), url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/poppins/Poppins-Regular.otf");
  src: local("Poppins Regular"), local("Poppins-Regular"), url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/poppins/Poppins-SemiBold.otf");
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url("../fonts/poppins/Poppins-SemiBoldItalic.otf");
  src: local("Poppins SemiBoldItalic"), local("Poppins-SemiBoldItalic"), url("../fonts/poppins/Poppins-SemiBoldItalic.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-Thin";
  src: url("../fonts/poppins/Poppins-Thin.otf");
  src: local("Poppins Thin"), local("Poppins-Thin"), url("../fonts/poppins/Poppins-Thin.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-ThinItalic";
  src: url("../fonts/poppins/Poppins-ThinItalic.otf");
  src: local("Poppins ThinItalic"), local("Poppins-ThinItalic"), url("../fonts/poppins/Poppins-ThinItalic.otf") format("opentype");
}
/*** Variables ***/
/* Fonts variable */
/*** Responsivo ***/
html,
body {
  width: 100%;
  margin: 0;
  background-color: #fcfcfe !important;
  overflow: auto;
  position: relative;
}

.container {
  padding: 0;
}

.content {
  max-width: 100%;
}

html,
body,
p,
li,
a {
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}

a {
  color: #441556;
  text-decoration: none !important;
}

a:active {
  font-weight: bold;
}

form,
input,
label {
  font-family: Inter-Regular, Arial, Helvetica, sans-serif !important;
}

.hidden-element {
  display: none !important;
}

body::-webkit-scrollbar {
  width: 15px;
  height: 6px;
}

body::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(63, 59, 63, 0.1);
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb:hover {
  background: rgba(144, 143, 143, 0.4);
}

body::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.2);
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid black;
  background-color: rgba(0, 0, 0, 0.5);
}

.d-flex {
  display: flex;
}

.j-c-c {
  justify-content: center;
}

.h-55 {
  height: 55px !important;
}

.m-0 {
  margin: 0px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-t-7 {
  margin-top: 7px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.p-tb-15 {
  padding: 15px 0 !important;
}

.p-r-25 {
  padding-right: 25px;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.no-uppercase {
  text-transform: none !important;
}

.MuiCircularProgress-svg {
  display: block;
  color: #642ba5;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: #5c39b3 !important;
}

/*** Login Page ***/
/*** Forgot Page ***/
/*** Reset Page ***/
.login-page,
.forgot-password-page,
.reset-password-page {
  width: 100vw !important;
  height: 100% !important;
  min-height: 100vh !important;
  overflow: hidden;
  background: -webkit-linear-gradient(#441556, #7544ef) !important;
  background: -moz-linear-gradient(#441556, #7544ef);
  background: rgb(63, 94, 251);
  background: linear-gradient(#441556, #7544ef);
}
.login-page .login-image-container,
.login-page .forgot-password-image-container,
.login-page .reset-password-image-container,
.forgot-password-page .login-image-container,
.forgot-password-page .forgot-password-image-container,
.forgot-password-page .reset-password-image-container,
.reset-password-page .login-image-container,
.reset-password-page .forgot-password-image-container,
.reset-password-page .reset-password-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-page .login-image-container .login-image,
.login-page .login-image-container .forgot-password-image,
.login-page .forgot-password-image-container .login-image,
.login-page .forgot-password-image-container .forgot-password-image,
.login-page .reset-password-image-container .login-image,
.login-page .reset-password-image-container .forgot-password-image,
.forgot-password-page .login-image-container .login-image,
.forgot-password-page .login-image-container .forgot-password-image,
.forgot-password-page .forgot-password-image-container .login-image,
.forgot-password-page .forgot-password-image-container .forgot-password-image,
.forgot-password-page .reset-password-image-container .login-image,
.forgot-password-page .reset-password-image-container .forgot-password-image,
.reset-password-page .login-image-container .login-image,
.reset-password-page .login-image-container .forgot-password-image,
.reset-password-page .forgot-password-image-container .login-image,
.reset-password-page .forgot-password-image-container .forgot-password-image,
.reset-password-page .reset-password-image-container .login-image,
.reset-password-page .reset-password-image-container .forgot-password-image {
  width: 55%;
}
.login-page .login-image-container .reset-password-image,
.login-page .forgot-password-image-container .reset-password-image,
.login-page .reset-password-image-container .reset-password-image,
.forgot-password-page .login-image-container .reset-password-image,
.forgot-password-page .forgot-password-image-container .reset-password-image,
.forgot-password-page .reset-password-image-container .reset-password-image,
.reset-password-page .login-image-container .reset-password-image,
.reset-password-page .forgot-password-image-container .reset-password-image,
.reset-password-page .reset-password-image-container .reset-password-image {
  width: 35%;
}
.login-page .forgot-password-icon-container,
.login-page .reset-password-icon-container,
.forgot-password-page .forgot-password-icon-container,
.forgot-password-page .reset-password-icon-container,
.reset-password-page .forgot-password-icon-container,
.reset-password-page .reset-password-icon-container {
  justify-content: center;
}
.login-page .forgot-password-icon-container .forgot-password-icon.error-icon,
.login-page .forgot-password-icon-container .reset-password-icon.error-icon,
.login-page .reset-password-icon-container .forgot-password-icon.error-icon,
.login-page .reset-password-icon-container .reset-password-icon.error-icon,
.forgot-password-page .forgot-password-icon-container .forgot-password-icon.error-icon,
.forgot-password-page .forgot-password-icon-container .reset-password-icon.error-icon,
.forgot-password-page .reset-password-icon-container .forgot-password-icon.error-icon,
.forgot-password-page .reset-password-icon-container .reset-password-icon.error-icon,
.reset-password-page .forgot-password-icon-container .forgot-password-icon.error-icon,
.reset-password-page .forgot-password-icon-container .reset-password-icon.error-icon,
.reset-password-page .reset-password-icon-container .forgot-password-icon.error-icon,
.reset-password-page .reset-password-icon-container .reset-password-icon.error-icon {
  font-size: 30px;
  color: #cc1616;
}
.login-page .forgot-password-icon-container .forgot-password-icon.success-icon,
.login-page .forgot-password-icon-container .reset-password-icon.success-icon,
.login-page .reset-password-icon-container .forgot-password-icon.success-icon,
.login-page .reset-password-icon-container .reset-password-icon.success-icon,
.forgot-password-page .forgot-password-icon-container .forgot-password-icon.success-icon,
.forgot-password-page .forgot-password-icon-container .reset-password-icon.success-icon,
.forgot-password-page .reset-password-icon-container .forgot-password-icon.success-icon,
.forgot-password-page .reset-password-icon-container .reset-password-icon.success-icon,
.reset-password-page .forgot-password-icon-container .forgot-password-icon.success-icon,
.reset-password-page .forgot-password-icon-container .reset-password-icon.success-icon,
.reset-password-page .reset-password-icon-container .forgot-password-icon.success-icon,
.reset-password-page .reset-password-icon-container .reset-password-icon.success-icon {
  font-size: 30px;
  color: #29b77b;
}
@media screen and (max-width: 899px) {
  .login-page .login-image-container,
  .login-page .forgot-password-image-container,
  .login-page .reset-password-image-container,
  .forgot-password-page .login-image-container,
  .forgot-password-page .forgot-password-image-container,
  .forgot-password-page .reset-password-image-container,
  .reset-password-page .login-image-container,
  .reset-password-page .forgot-password-image-container,
  .reset-password-page .reset-password-image-container {
    display: none;
  }
}
.login-page .login-component-container .login-component,
.login-page .login-component-container .forgot-password-component,
.login-page .login-component-container .reset-password-component,
.login-page .forgot-password-component-container .login-component,
.login-page .forgot-password-component-container .forgot-password-component,
.login-page .forgot-password-component-container .reset-password-component,
.login-page .reset-password-component-container .login-component,
.login-page .reset-password-component-container .forgot-password-component,
.login-page .reset-password-component-container .reset-password-component,
.forgot-password-page .login-component-container .login-component,
.forgot-password-page .login-component-container .forgot-password-component,
.forgot-password-page .login-component-container .reset-password-component,
.forgot-password-page .forgot-password-component-container .login-component,
.forgot-password-page .forgot-password-component-container .forgot-password-component,
.forgot-password-page .forgot-password-component-container .reset-password-component,
.forgot-password-page .reset-password-component-container .login-component,
.forgot-password-page .reset-password-component-container .forgot-password-component,
.forgot-password-page .reset-password-component-container .reset-password-component,
.reset-password-page .login-component-container .login-component,
.reset-password-page .login-component-container .forgot-password-component,
.reset-password-page .login-component-container .reset-password-component,
.reset-password-page .forgot-password-component-container .login-component,
.reset-password-page .forgot-password-component-container .forgot-password-component,
.reset-password-page .forgot-password-component-container .reset-password-component,
.reset-password-page .reset-password-component-container .login-component,
.reset-password-page .reset-password-component-container .forgot-password-component,
.reset-password-page .reset-password-component-container .reset-password-component {
  position: relative;
  padding: 10% 15%;
}
.login-page .login-component-container .login-component .login-form,
.login-page .login-component-container .login-component .forgot-password-form,
.login-page .login-component-container .login-component .reset-password-form,
.login-page .login-component-container .forgot-password-component .login-form,
.login-page .login-component-container .forgot-password-component .forgot-password-form,
.login-page .login-component-container .forgot-password-component .reset-password-form,
.login-page .login-component-container .reset-password-component .login-form,
.login-page .login-component-container .reset-password-component .forgot-password-form,
.login-page .login-component-container .reset-password-component .reset-password-form,
.login-page .forgot-password-component-container .login-component .login-form,
.login-page .forgot-password-component-container .login-component .forgot-password-form,
.login-page .forgot-password-component-container .login-component .reset-password-form,
.login-page .forgot-password-component-container .forgot-password-component .login-form,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form,
.login-page .forgot-password-component-container .reset-password-component .login-form,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form,
.login-page .reset-password-component-container .login-component .login-form,
.login-page .reset-password-component-container .login-component .forgot-password-form,
.login-page .reset-password-component-container .login-component .reset-password-form,
.login-page .reset-password-component-container .forgot-password-component .login-form,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form,
.login-page .reset-password-component-container .reset-password-component .login-form,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form,
.login-page .reset-password-component-container .reset-password-component .reset-password-form,
.forgot-password-page .login-component-container .login-component .login-form,
.forgot-password-page .login-component-container .login-component .forgot-password-form,
.forgot-password-page .login-component-container .login-component .reset-password-form,
.forgot-password-page .login-component-container .forgot-password-component .login-form,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form,
.forgot-password-page .login-component-container .reset-password-component .login-form,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form,
.forgot-password-page .forgot-password-component-container .login-component .login-form,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form,
.forgot-password-page .reset-password-component-container .login-component .login-form,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form,
.reset-password-page .login-component-container .login-component .login-form,
.reset-password-page .login-component-container .login-component .forgot-password-form,
.reset-password-page .login-component-container .login-component .reset-password-form,
.reset-password-page .login-component-container .forgot-password-component .login-form,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form,
.reset-password-page .login-component-container .reset-password-component .login-form,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form,
.reset-password-page .login-component-container .reset-password-component .reset-password-form,
.reset-password-page .forgot-password-component-container .login-component .login-form,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form,
.reset-password-page .reset-password-component-container .login-component .login-form,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form,
.reset-password-page .reset-password-component-container .login-component .reset-password-form,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form,
.reset-password-page .reset-password-component-container .reset-password-component .login-form,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 70%;
  height: auto;
  border-radius: 5px;
  background: white;
}
.login-page .login-component-container .login-component .login-form.no-form,
.login-page .login-component-container .login-component .forgot-password-form.no-form,
.login-page .login-component-container .login-component .reset-password-form.no-form,
.login-page .login-component-container .forgot-password-component .login-form.no-form,
.login-page .login-component-container .forgot-password-component .forgot-password-form.no-form,
.login-page .login-component-container .forgot-password-component .reset-password-form.no-form,
.login-page .login-component-container .reset-password-component .login-form.no-form,
.login-page .login-component-container .reset-password-component .forgot-password-form.no-form,
.login-page .login-component-container .reset-password-component .reset-password-form.no-form,
.login-page .forgot-password-component-container .login-component .login-form.no-form,
.login-page .forgot-password-component-container .login-component .forgot-password-form.no-form,
.login-page .forgot-password-component-container .login-component .reset-password-form.no-form,
.login-page .forgot-password-component-container .forgot-password-component .login-form.no-form,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form.no-form,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form.no-form,
.login-page .forgot-password-component-container .reset-password-component .login-form.no-form,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form.no-form,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form.no-form,
.login-page .reset-password-component-container .login-component .login-form.no-form,
.login-page .reset-password-component-container .login-component .forgot-password-form.no-form,
.login-page .reset-password-component-container .login-component .reset-password-form.no-form,
.login-page .reset-password-component-container .forgot-password-component .login-form.no-form,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form.no-form,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form.no-form,
.login-page .reset-password-component-container .reset-password-component .login-form.no-form,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form.no-form,
.login-page .reset-password-component-container .reset-password-component .reset-password-form.no-form,
.forgot-password-page .login-component-container .login-component .login-form.no-form,
.forgot-password-page .login-component-container .login-component .forgot-password-form.no-form,
.forgot-password-page .login-component-container .login-component .reset-password-form.no-form,
.forgot-password-page .login-component-container .forgot-password-component .login-form.no-form,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form.no-form,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form.no-form,
.forgot-password-page .login-component-container .reset-password-component .login-form.no-form,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form.no-form,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form.no-form,
.forgot-password-page .forgot-password-component-container .login-component .login-form.no-form,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form.no-form,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form.no-form,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form.no-form,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form.no-form,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form.no-form,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form.no-form,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form.no-form,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form.no-form,
.forgot-password-page .reset-password-component-container .login-component .login-form.no-form,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form.no-form,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form.no-form,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form.no-form,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form.no-form,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form.no-form,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form.no-form,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form.no-form,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form.no-form,
.reset-password-page .login-component-container .login-component .login-form.no-form,
.reset-password-page .login-component-container .login-component .forgot-password-form.no-form,
.reset-password-page .login-component-container .login-component .reset-password-form.no-form,
.reset-password-page .login-component-container .forgot-password-component .login-form.no-form,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form.no-form,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form.no-form,
.reset-password-page .login-component-container .reset-password-component .login-form.no-form,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form.no-form,
.reset-password-page .login-component-container .reset-password-component .reset-password-form.no-form,
.reset-password-page .forgot-password-component-container .login-component .login-form.no-form,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form.no-form,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form.no-form,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form.no-form,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form.no-form,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form.no-form,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form.no-form,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form.no-form,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form.no-form,
.reset-password-page .reset-password-component-container .login-component .login-form.no-form,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form.no-form,
.reset-password-page .reset-password-component-container .login-component .reset-password-form.no-form,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form.no-form,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form.no-form,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form.no-form,
.reset-password-page .reset-password-component-container .reset-password-component .login-form.no-form,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form.no-form,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form.no-form {
  position: relative;
  padding: 0 75px;
}
.login-page .login-component-container .login-component .login-form .login-title-container,
.login-page .login-component-container .login-component .login-form .forgot-password-title-container,
.login-page .login-component-container .login-component .login-form .reset-password-title-container,
.login-page .login-component-container .login-component .forgot-password-form .login-title-container,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container,
.login-page .login-component-container .login-component .reset-password-form .login-title-container,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container,
.login-page .login-component-container .login-component .reset-password-form .reset-password-title-container,
.login-page .login-component-container .forgot-password-component .login-form .login-title-container,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.login-page .login-component-container .reset-password-component .login-form .login-title-container,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container,
.login-page .login-component-container .reset-password-component .login-form .reset-password-title-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.login-page .login-component-container .reset-password-component .reset-password-form .login-title-container,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.login-page .forgot-password-component-container .login-component .login-form .login-title-container,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.login-page .reset-password-component-container .login-component .login-form .login-title-container,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container,
.login-page .reset-password-component-container .login-component .login-form .reset-password-title-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container,
.login-page .reset-password-component-container .login-component .reset-password-form .login-title-container,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.login-page .reset-password-component-container .reset-password-component .login-form .login-title-container,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.forgot-password-page .login-component-container .login-component .login-form .login-title-container,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.reset-password-page .login-component-container .login-component .login-form .login-title-container,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container,
.reset-password-page .login-component-container .login-component .login-form .reset-password-title-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container,
.reset-password-page .login-component-container .login-component .reset-password-form .login-title-container,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.reset-password-page .login-component-container .reset-password-component .login-form .login-title-container,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .login-component .login-form .login-title-container,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container {
  padding: 50px 0;
}
.login-page .login-component-container .login-component .login-form .login-title-container .login-title,
.login-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title,
.login-page .login-component-container .login-component .login-form .login-title-container .reset-password-title,
.login-page .login-component-container .login-component .login-form .forgot-password-title-container .login-title,
.login-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .login-form .reset-password-title-container .login-title,
.login-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .forgot-password-form .login-title-container .login-title,
.login-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .reset-password-form .login-title-container .login-title,
.login-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
.login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .login-form .login-title-container .login-title,
.login-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .login-form .login-title-container .login-title,
.login-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
.login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
.login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .login-form .login-title-container .login-title,
.login-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .login-title,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .login-title,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .login-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .login-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .login-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .login-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .login-form .login-title-container .login-title,
.login-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .login-title,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .login-title,
.login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .login-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .login-title,
.login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .login-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .login-title,
.login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .login-form .login-title-container .login-title,
.forgot-password-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .login-title,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .login-title,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .login-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .login-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .login-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .login-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .login-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .login-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .login-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .login-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .login-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .login-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .login-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .login-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .login-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .login-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .login-form .login-title-container .login-title,
.reset-password-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .login-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .login-title,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .login-title,
.reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .login-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .login-title,
.reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .login-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .login-title,
.reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .login-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .login-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .login-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .login-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .login-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .login-title,
.reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .login-title,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .login-title,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .login-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .login-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .login-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .login-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title {
  color: #441556;
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
  font-size: 25px;
  margin: 0;
}
.login-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title,
.login-page .login-component-container .login-component .login-form .login-title-container .reset-password-title,
.login-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .login-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title {
  font-size: 20px;
}
.login-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
.login-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text {
  font-size: 18px;
  color: #062d51;
}
.login-page .login-component-container .login-component .login-form .login-title-container .reset-password-title,
.login-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .login-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title {
  color: #062d51;
}
@media screen and (max-width: 767px) {
  .login-page .login-component-container .login-component .login-form .login-title-container .login-title,
  .login-page .login-component-container .login-component .login-form .forgot-password-title-container .login-title,
  .login-page .login-component-container .login-component .login-form .reset-password-title-container .login-title,
  .login-page .login-component-container .login-component .forgot-password-form .login-title-container .login-title,
  .login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
  .login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
  .login-page .login-component-container .login-component .reset-password-form .login-title-container .login-title,
  .login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
  .login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
  .login-page .login-component-container .forgot-password-component .login-form .login-title-container .login-title,
  .login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
  .login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
  .login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
  .login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
  .login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
  .login-page .login-component-container .reset-password-component .login-form .login-title-container .login-title,
  .login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
  .login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
  .login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
  .login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
  .login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
  .login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
  .login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
  .login-page .forgot-password-component-container .login-component .login-form .login-title-container .login-title,
  .login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .login-title,
  .login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .login-title,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .login-title,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
  .login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .login-title,
  .login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
  .login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .login-title,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
  .login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .login-title,
  .login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
  .login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
  .login-page .reset-password-component-container .login-component .login-form .login-title-container .login-title,
  .login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .login-title,
  .login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .login-title,
  .login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .login-title,
  .login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
  .login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
  .login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .login-title,
  .login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
  .login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
  .login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .login-title,
  .login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
  .login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
  .login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .login-title,
  .login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
  .login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
  .forgot-password-page .login-component-container .login-component .login-form .login-title-container .login-title,
  .forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .login-title,
  .forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .login-title,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .login-title,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
  .forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .login-title,
  .forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .login-title,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
  .forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .login-title,
  .forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
  .forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .login-title,
  .forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .login-title,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .login-title,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .login-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .login-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
  .reset-password-page .login-component-container .login-component .login-form .login-title-container .login-title,
  .reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .login-title,
  .reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .login-title,
  .reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .login-title,
  .reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
  .reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
  .reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .login-title,
  .reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
  .reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
  .reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .login-title,
  .reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
  .reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
  .reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .login-title,
  .reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
  .reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .login-title,
  .reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .login-title,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .login-title,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .login-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .login-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .login-title,
  .reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .login-title,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .login-title,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .login-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .login-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .login-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .login-title,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .login-title,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .login-title,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .login-title,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .login-title {
    font-size: 21px;
  }
  .login-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title,
  .login-page .login-component-container .login-component .login-form .login-title-container .reset-password-title,
  .login-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
  .login-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
  .login-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
  .login-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
  .login-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
  .login-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
  .login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .login-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
  .login-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
  .login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
  .login-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
  .login-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
  .login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
  .login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
  .login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
  .login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
  .login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .login-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
  .login-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
  .login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
  .login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
  .login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
  .login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
  .login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
  .login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
  .login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title,
  .login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
  .login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
  .login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
  .login-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title,
  .login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
  .login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
  .login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
  .login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
  .login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
  .login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
  .login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
  .login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
  .login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .login-component .login-form .login-title-container .reset-password-title,
  .forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
  .forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
  .forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title,
  .reset-password-page .login-component-container .login-component .login-form .login-title-container .reset-password-title,
  .reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
  .reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
  .reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
  .reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
  .reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
  .reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
  .reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
  .reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
  .reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title {
    font-size: 16px;
  }
  .login-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .login-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container .reset-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .forgot-password-title.custom-text,
  .reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container .reset-password-title.custom-text {
    font-size: 14px;
    color: #062d51;
  }
}
.login-page .login-component-container .login-component .login-form .forgot-password-title-container,
.login-page .login-component-container .login-component .login-form .reset-password-title-container,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-title-container,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-title-container,
.login-page .login-component-container .login-component .reset-password-form .reset-password-title-container,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-title-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-title-container,
.login-page .login-component-container .reset-password-component .login-form .reset-password-title-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-title-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-title-container,
.login-page .reset-password-component-container .login-component .login-form .reset-password-title-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-title-container,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-title-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-title-container,
.reset-password-page .login-component-container .login-component .login-form .reset-password-title-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-title-container,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-title-container,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-title-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-title-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-title-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-title-container,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-title-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-title-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-title-container {
  justify-content: center;
}
.login-page .login-component-container .login-component .login-form .forgot-password-text-container,
.login-page .login-component-container .login-component .login-form .reset-password-text-container,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-text-container,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-text-container,
.login-page .login-component-container .login-component .reset-password-form .reset-password-text-container,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-text-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-text-container,
.login-page .login-component-container .reset-password-component .login-form .reset-password-text-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-text-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-text-container,
.login-page .reset-password-component-container .login-component .login-form .reset-password-text-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-text-container,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-text-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-text-container,
.reset-password-page .login-component-container .login-component .login-form .reset-password-text-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container {
  justify-content: center;
}
.login-page .login-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text,
.login-page .login-component-container .login-component .login-form .forgot-password-text-container .reset-password-text,
.login-page .login-component-container .login-component .login-form .reset-password-text-container .forgot-password-text,
.login-page .login-component-container .login-component .login-form .reset-password-text-container .reset-password-text,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text,
.login-page .login-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text,
.login-page .login-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text,
.login-page .login-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text,
.login-page .login-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-text-container .reset-password-text,
.login-page .reset-password-component-container .login-component .login-form .reset-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .login-component .login-form .reset-password-text-container .reset-password-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-text-container .reset-password-text,
.reset-password-page .login-component-container .login-component .login-form .reset-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .login-component .login-form .reset-password-text-container .reset-password-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text {
  color: #062d51;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  font-size: 15px;
  letter-spacing: 0.05rem;
}
.login-page .login-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .login-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .login-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .login-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .login-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .login-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .login-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .login-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container .reset-password-text.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .forgot-password-text.custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container .reset-password-text.custom-text {
  font-size: 16px;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  color: #441556;
}
.login-page .login-component-container .login-component .login-form .forgot-password-text-container.custom-text-container,
.login-page .login-component-container .login-component .login-form .reset-password-text-container.custom-text-container,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container,
.login-page .login-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container,
.login-page .login-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-text-container.custom-text-container,
.login-page .reset-password-component-container .login-component .login-form .reset-password-text-container.custom-text-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-text-container.custom-text-container,
.reset-password-page .login-component-container .login-component .login-form .reset-password-text-container.custom-text-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container {
  position: absolute;
  bottom: 50px;
}
.login-page .login-component-container .login-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .login-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .login-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .login-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-text-container.custom-text-container .custom-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-text-container.custom-text-container .custom-text {
  font-size: 13px;
  font-family: Inter-Light, Arial, Helvetica, sans-serif;
  color: #062d51;
}
.login-page .login-component-container .login-component .login-form .reset-password-button-container,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-button-container,
.login-page .login-component-container .login-component .reset-password-form .reset-password-button-container,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-button-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-button-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-button-container,
.login-page .login-component-container .reset-password-component .login-form .reset-password-button-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-button-container,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-button-container,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-button-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-button-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-button-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-button-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-button-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-button-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-button-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-button-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-button-container,
.login-page .reset-password-component-container .login-component .login-form .reset-password-button-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-button-container,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-button-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-button-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-button-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-button-container,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-button-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-button-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-button-container,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-button-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-button-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-button-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-button-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-button-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-button-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-button-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-button-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-button-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-button-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-button-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-button-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-button-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-button-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-button-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-button-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-button-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-button-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-button-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-button-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-button-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-button-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-button-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-button-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-button-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-button-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-button-container,
.reset-password-page .login-component-container .login-component .login-form .reset-password-button-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-button-container,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-button-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-button-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-button-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-button-container,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-button-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-button-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-button-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-button-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-button-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-button-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-button-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-button-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-button-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-button-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-button-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-button-container,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-button-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-button-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-button-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-button-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-button-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-button-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-button-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-button-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-button-container {
  justify-content: center;
}
.login-page .login-component-container .login-component .login-form .reset-password-button-container .reset-password-button,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-button-container .reset-password-button,
.login-page .login-component-container .login-component .reset-password-form .reset-password-button-container .reset-password-button,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-button-container .reset-password-button,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.login-page .login-component-container .reset-password-component .login-form .reset-password-button-container .reset-password-button,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-button-container .reset-password-button,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-button-container .reset-password-button,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-button-container .reset-password-button,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-button-container .reset-password-button,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-button-container .reset-password-button,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.login-page .reset-password-component-container .login-component .login-form .reset-password-button-container .reset-password-button,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-button-container .reset-password-button,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-button-container .reset-password-button,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-button-container .reset-password-button,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-button-container .reset-password-button,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-button-container .reset-password-button,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-button-container .reset-password-button,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-button-container .reset-password-button,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-button-container .reset-password-button,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-button-container .reset-password-button,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-button-container .reset-password-button,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-button-container .reset-password-button,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-button-container .reset-password-button,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-button-container .reset-password-button,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .login-component-container .login-component .login-form .reset-password-button-container .reset-password-button,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-button-container .reset-password-button,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-button-container .reset-password-button,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-button-container .reset-password-button,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-button-container .reset-password-button,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-button-container .reset-password-button,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-button-container .reset-password-button,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-button-container .reset-password-button,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-button-container .reset-password-button,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-button-container .reset-password-button,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-button-container .reset-password-button {
  font-size: 16px;
  font-family: Inter-Bold, Arial, Helvetica, sans-serif;
  text-transform: none;
}
.login-page .login-component-container .login-component .login-form .login-logo-container,
.login-page .login-component-container .login-component .login-form .forgot-password-logo-container,
.login-page .login-component-container .login-component .login-form .reset-password-logo-container,
.login-page .login-component-container .login-component .forgot-password-form .login-logo-container,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container,
.login-page .login-component-container .login-component .reset-password-form .login-logo-container,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container,
.login-page .login-component-container .login-component .reset-password-form .reset-password-logo-container,
.login-page .login-component-container .forgot-password-component .login-form .login-logo-container,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container,
.login-page .login-component-container .reset-password-component .login-form .login-logo-container,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container,
.login-page .login-component-container .reset-password-component .login-form .reset-password-logo-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container,
.login-page .login-component-container .reset-password-component .reset-password-form .login-logo-container,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container,
.login-page .forgot-password-component-container .login-component .login-form .login-logo-container,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container,
.login-page .reset-password-component-container .login-component .login-form .login-logo-container,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container,
.login-page .reset-password-component-container .login-component .login-form .reset-password-logo-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container,
.login-page .reset-password-component-container .login-component .reset-password-form .login-logo-container,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container,
.login-page .reset-password-component-container .reset-password-component .login-form .login-logo-container,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container,
.forgot-password-page .login-component-container .login-component .login-form .login-logo-container,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-logo-container,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-logo-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-logo-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-logo-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-logo-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-logo-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-logo-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-logo-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-logo-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-logo-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-logo-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-logo-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-logo-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-logo-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container,
.reset-password-page .login-component-container .login-component .login-form .login-logo-container,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-logo-container,
.reset-password-page .login-component-container .login-component .login-form .reset-password-logo-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-logo-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container,
.reset-password-page .login-component-container .login-component .reset-password-form .login-logo-container,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-logo-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-logo-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container,
.reset-password-page .login-component-container .reset-password-component .login-form .login-logo-container,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-logo-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-logo-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-logo-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container,
.reset-password-page .reset-password-component-container .login-component .login-form .login-logo-container,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-logo-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-logo-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-logo-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 15px 0 15px 15px;
}
.login-page .login-component-container .login-component .login-form .login-logo-container.custom-logo,
.login-page .login-component-container .login-component .login-form .forgot-password-logo-container.custom-logo,
.login-page .login-component-container .login-component .login-form .reset-password-logo-container.custom-logo,
.login-page .login-component-container .login-component .forgot-password-form .login-logo-container.custom-logo,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container.custom-logo,
.login-page .login-component-container .login-component .reset-password-form .login-logo-container.custom-logo,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container.custom-logo,
.login-page .login-component-container .login-component .reset-password-form .reset-password-logo-container.custom-logo,
.login-page .login-component-container .forgot-password-component .login-form .login-logo-container.custom-logo,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container.custom-logo,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container.custom-logo,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container.custom-logo,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container.custom-logo,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.login-page .login-component-container .reset-password-component .login-form .login-logo-container.custom-logo,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container.custom-logo,
.login-page .login-component-container .reset-password-component .login-form .reset-password-logo-container.custom-logo,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container.custom-logo,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.login-page .login-component-container .reset-password-component .reset-password-form .login-logo-container.custom-logo,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .login-component .login-form .login-logo-container.custom-logo,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container.custom-logo,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container.custom-logo,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container.custom-logo,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container.custom-logo,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container.custom-logo,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container.custom-logo,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container.custom-logo,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container.custom-logo,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.login-page .reset-password-component-container .login-component .login-form .login-logo-container.custom-logo,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container.custom-logo,
.login-page .reset-password-component-container .login-component .login-form .reset-password-logo-container.custom-logo,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container.custom-logo,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container.custom-logo,
.login-page .reset-password-component-container .login-component .reset-password-form .login-logo-container.custom-logo,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container.custom-logo,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container.custom-logo,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container.custom-logo,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container.custom-logo,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container.custom-logo,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container.custom-logo,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container.custom-logo,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.login-page .reset-password-component-container .reset-password-component .login-form .login-logo-container.custom-logo,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container.custom-logo,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container.custom-logo,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container.custom-logo,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container.custom-logo,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .login-component .login-form .login-logo-container.custom-logo,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-logo-container.custom-logo,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-logo-container.custom-logo,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-logo-container.custom-logo,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container.custom-logo,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container.custom-logo,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-logo-container.custom-logo,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container.custom-logo,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-logo-container.custom-logo,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .login-component-container .login-component .login-form .login-logo-container.custom-logo,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-logo-container.custom-logo,
.reset-password-page .login-component-container .login-component .login-form .reset-password-logo-container.custom-logo,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-logo-container.custom-logo,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .login-component-container .login-component .reset-password-form .login-logo-container.custom-logo,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-logo-container.custom-logo,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container.custom-logo,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container.custom-logo,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container.custom-logo,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container.custom-logo,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .login-component-container .reset-password-component .login-form .login-logo-container.custom-logo,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container.custom-logo,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-logo-container.custom-logo,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container.custom-logo,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-logo-container.custom-logo,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .login-component .login-form .login-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container.custom-logo,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container.custom-logo {
  position: absolute;
  top: 20px;
  right: 20px;
}
.login-page .login-component-container .login-component .login-form .login-logo-container .login-logo,
.login-page .login-component-container .login-component .login-form .login-logo-container .forgot-password-logo,
.login-page .login-component-container .login-component .login-form .login-logo-container .reset-password-logo,
.login-page .login-component-container .login-component .login-form .forgot-password-logo-container .login-logo,
.login-page .login-component-container .login-component .login-form .forgot-password-logo-container .forgot-password-logo,
.login-page .login-component-container .login-component .login-form .forgot-password-logo-container .reset-password-logo,
.login-page .login-component-container .login-component .login-form .reset-password-logo-container .login-logo,
.login-page .login-component-container .login-component .login-form .reset-password-logo-container .forgot-password-logo,
.login-page .login-component-container .login-component .login-form .reset-password-logo-container .reset-password-logo,
.login-page .login-component-container .login-component .forgot-password-form .login-logo-container .login-logo,
.login-page .login-component-container .login-component .forgot-password-form .login-logo-container .forgot-password-logo,
.login-page .login-component-container .login-component .forgot-password-form .login-logo-container .reset-password-logo,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container .login-logo,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container .login-logo,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.login-page .login-component-container .login-component .reset-password-form .login-logo-container .login-logo,
.login-page .login-component-container .login-component .reset-password-form .login-logo-container .forgot-password-logo,
.login-page .login-component-container .login-component .reset-password-form .login-logo-container .reset-password-logo,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container .login-logo,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .login-component-container .login-component .reset-password-form .reset-password-logo-container .login-logo,
.login-page .login-component-container .login-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .login-component-container .login-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.login-page .login-component-container .forgot-password-component .login-form .login-logo-container .login-logo,
.login-page .login-component-container .forgot-password-component .login-form .login-logo-container .forgot-password-logo,
.login-page .login-component-container .forgot-password-component .login-form .login-logo-container .reset-password-logo,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container .login-logo,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.login-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container .login-logo,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.login-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container .reset-password-logo,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container .login-logo,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.login-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container .login-logo,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.login-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container .reset-password-logo,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .login-logo,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.login-page .login-component-container .reset-password-component .login-form .login-logo-container .login-logo,
.login-page .login-component-container .reset-password-component .login-form .login-logo-container .forgot-password-logo,
.login-page .login-component-container .reset-password-component .login-form .login-logo-container .reset-password-logo,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container .login-logo,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.login-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.login-page .login-component-container .reset-password-component .login-form .reset-password-logo-container .login-logo,
.login-page .login-component-container .reset-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.login-page .login-component-container .reset-password-component .login-form .reset-password-logo-container .reset-password-logo,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container .login-logo,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.login-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.login-page .login-component-container .reset-password-component .reset-password-form .login-logo-container .login-logo,
.login-page .login-component-container .reset-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.login-page .login-component-container .reset-password-component .reset-password-form .login-logo-container .reset-password-logo,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container .login-logo,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .login-component .login-form .login-logo-container .login-logo,
.login-page .forgot-password-component-container .login-component .login-form .login-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .login-component .login-form .login-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container .login-logo,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container .login-logo,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container .login-logo,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .login-logo,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container .login-logo,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container .login-logo,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container .login-logo,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container .login-logo,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container .login-logo,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .login-logo,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container .login-logo,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .login-logo,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container .login-logo,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .login-logo,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container .login-logo,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container .login-logo,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container .login-logo,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container .login-logo,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container .login-logo,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .login-logo,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .login-component .login-form .login-logo-container .login-logo,
.login-page .reset-password-component-container .login-component .login-form .login-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .login-component .login-form .login-logo-container .reset-password-logo,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container .login-logo,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .login-component .login-form .reset-password-logo-container .login-logo,
.login-page .reset-password-component-container .login-component .login-form .reset-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .login-component .login-form .reset-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container .login-logo,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container .reset-password-logo,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .login-logo,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container .login-logo,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .login-component .reset-password-form .login-logo-container .login-logo,
.login-page .reset-password-component-container .login-component .reset-password-form .login-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .login-component .reset-password-form .login-logo-container .reset-password-logo,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container .login-logo,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container .login-logo,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container .login-logo,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container .reset-password-logo,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .login-logo,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container .login-logo,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .login-logo,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container .login-logo,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container .reset-password-logo,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .login-logo,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .reset-password-component .login-form .login-logo-container .login-logo,
.login-page .reset-password-component-container .reset-password-component .login-form .login-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .reset-password-component .login-form .login-logo-container .reset-password-logo,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container .login-logo,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container .login-logo,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container .login-logo,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container .login-logo,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container .reset-password-logo,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .login-logo,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.login-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .login-component .login-form .login-logo-container .login-logo,
.forgot-password-page .login-component-container .login-component .login-form .login-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .login-component .login-form .login-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-logo-container .login-logo,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .login-component .login-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-logo-container .login-logo,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .login-component .login-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-logo-container .login-logo,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .login-component .forgot-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-logo-container .login-logo,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .login-component .reset-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .login-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-logo-container .login-logo,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .login-form .login-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container .login-logo,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container .login-logo,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container .login-logo,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container .login-logo,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-logo-container .login-logo,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .reset-password-component .login-form .login-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container .login-logo,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-logo-container .login-logo,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .reset-password-component .login-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container .login-logo,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-logo-container .login-logo,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .login-form .login-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .login-component .login-form .login-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .login-component .login-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .login-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .login-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .login-component .login-form .login-logo-container .login-logo,
.reset-password-page .login-component-container .login-component .login-form .login-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .login-component .login-form .login-logo-container .reset-password-logo,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-logo-container .login-logo,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .login-component .login-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .login-component .login-form .reset-password-logo-container .login-logo,
.reset-password-page .login-component-container .login-component .login-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .login-component .login-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-logo-container .login-logo,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .login-component .forgot-password-form .login-logo-container .reset-password-logo,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .login-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container .login-logo,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .login-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .login-component .reset-password-form .login-logo-container .login-logo,
.reset-password-page .login-component-container .login-component .reset-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .login-component .reset-password-form .login-logo-container .reset-password-logo,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .login-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-logo-container .login-logo,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .login-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-logo-container .login-logo,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .forgot-password-component .login-form .login-logo-container .reset-password-logo,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container .login-logo,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .forgot-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container .login-logo,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .forgot-password-component .login-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container .login-logo,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container .login-logo,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .login-logo-container .reset-password-logo,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .login-logo,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .reset-password-component .login-form .login-logo-container .login-logo,
.reset-password-page .login-component-container .reset-password-component .login-form .login-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .reset-password-component .login-form .login-logo-container .reset-password-logo,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container .login-logo,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .reset-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-logo-container .login-logo,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .reset-password-component .login-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container .login-logo,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-logo-container .login-logo,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .login-logo-container .reset-password-logo,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container .login-logo,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .login-component-container .reset-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .login-component .login-form .login-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .login-component .login-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .login-component .login-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .login-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .login-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .login-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .login-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .login-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .login-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .login-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .login-component .login-form .login-logo-container .login-logo,
.reset-password-page .reset-password-component-container .login-component .login-form .login-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .login-component .login-form .login-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .login-component .login-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .login-component .login-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container .login-logo,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .login-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-logo-container .login-logo,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .login-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .login-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container .login-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .login-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .login-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container .login-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .login-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-logo-container .login-logo,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .login-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .login-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container .login-logo,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .login-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form .reset-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container .login-logo,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .login-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .forgot-password-logo-container .reset-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .login-logo,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .forgot-password-logo,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form .reset-password-logo-container .reset-password-logo {
  width: 80px;
  height: 60px;
}
.login-page .login-component-container .login-component .login-form form,
.login-page .login-component-container .login-component .forgot-password-form form,
.login-page .login-component-container .login-component .reset-password-form form,
.login-page .login-component-container .forgot-password-component .login-form form,
.login-page .login-component-container .forgot-password-component .forgot-password-form form,
.login-page .login-component-container .forgot-password-component .reset-password-form form,
.login-page .login-component-container .reset-password-component .login-form form,
.login-page .login-component-container .reset-password-component .forgot-password-form form,
.login-page .login-component-container .reset-password-component .reset-password-form form,
.login-page .forgot-password-component-container .login-component .login-form form,
.login-page .forgot-password-component-container .login-component .forgot-password-form form,
.login-page .forgot-password-component-container .login-component .reset-password-form form,
.login-page .forgot-password-component-container .forgot-password-component .login-form form,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form,
.login-page .forgot-password-component-container .reset-password-component .login-form form,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form,
.login-page .reset-password-component-container .login-component .login-form form,
.login-page .reset-password-component-container .login-component .forgot-password-form form,
.login-page .reset-password-component-container .login-component .reset-password-form form,
.login-page .reset-password-component-container .forgot-password-component .login-form form,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form,
.login-page .reset-password-component-container .reset-password-component .login-form form,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form,
.forgot-password-page .login-component-container .login-component .login-form form,
.forgot-password-page .login-component-container .login-component .forgot-password-form form,
.forgot-password-page .login-component-container .login-component .reset-password-form form,
.forgot-password-page .login-component-container .forgot-password-component .login-form form,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form,
.forgot-password-page .login-component-container .reset-password-component .login-form form,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form,
.forgot-password-page .forgot-password-component-container .login-component .login-form form,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form,
.forgot-password-page .reset-password-component-container .login-component .login-form form,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form,
.reset-password-page .login-component-container .login-component .login-form form,
.reset-password-page .login-component-container .login-component .forgot-password-form form,
.reset-password-page .login-component-container .login-component .reset-password-form form,
.reset-password-page .login-component-container .forgot-password-component .login-form form,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form,
.reset-password-page .login-component-container .reset-password-component .login-form form,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form,
.reset-password-page .forgot-password-component-container .login-component .login-form form,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form,
.reset-password-page .reset-password-component-container .login-component .login-form form,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form {
  position: relative;
  width: 70%;
  height: auto;
  padding-bottom: 40px;
}
.login-page .login-component-container .login-component .login-form form .custom-field-container,
.login-page .login-component-container .login-component .login-form form .login-field-container,
.login-page .login-component-container .login-component .login-form form .forgot-password-field-container,
.login-page .login-component-container .login-component .login-form form .reset-password-field-container,
.login-page .login-component-container .login-component .forgot-password-form form .custom-field-container,
.login-page .login-component-container .login-component .forgot-password-form form .login-field-container,
.login-page .login-component-container .login-component .forgot-password-form form .forgot-password-field-container,
.login-page .login-component-container .login-component .forgot-password-form form .reset-password-field-container,
.login-page .login-component-container .login-component .reset-password-form form .custom-field-container,
.login-page .login-component-container .login-component .reset-password-form form .login-field-container,
.login-page .login-component-container .login-component .reset-password-form form .forgot-password-field-container,
.login-page .login-component-container .login-component .reset-password-form form .reset-password-field-container,
.login-page .login-component-container .forgot-password-component .login-form form .custom-field-container,
.login-page .login-component-container .forgot-password-component .login-form form .login-field-container,
.login-page .login-component-container .forgot-password-component .login-form form .forgot-password-field-container,
.login-page .login-component-container .forgot-password-component .login-form form .reset-password-field-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .custom-field-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .login-field-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container,
.login-page .login-component-container .forgot-password-component .reset-password-form form .custom-field-container,
.login-page .login-component-container .forgot-password-component .reset-password-form form .login-field-container,
.login-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container,
.login-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-field-container,
.login-page .login-component-container .reset-password-component .login-form form .custom-field-container,
.login-page .login-component-container .reset-password-component .login-form form .login-field-container,
.login-page .login-component-container .reset-password-component .login-form form .forgot-password-field-container,
.login-page .login-component-container .reset-password-component .login-form form .reset-password-field-container,
.login-page .login-component-container .reset-password-component .forgot-password-form form .custom-field-container,
.login-page .login-component-container .reset-password-component .forgot-password-form form .login-field-container,
.login-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container,
.login-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-field-container,
.login-page .login-component-container .reset-password-component .reset-password-form form .custom-field-container,
.login-page .login-component-container .reset-password-component .reset-password-form form .login-field-container,
.login-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-field-container,
.login-page .login-component-container .reset-password-component .reset-password-form form .reset-password-field-container,
.login-page .forgot-password-component-container .login-component .login-form form .custom-field-container,
.login-page .forgot-password-component-container .login-component .login-form form .login-field-container,
.login-page .forgot-password-component-container .login-component .login-form form .forgot-password-field-container,
.login-page .forgot-password-component-container .login-component .login-form form .reset-password-field-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .custom-field-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .login-field-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-field-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-field-container,
.login-page .forgot-password-component-container .login-component .reset-password-form form .custom-field-container,
.login-page .forgot-password-component-container .login-component .reset-password-form form .login-field-container,
.login-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-field-container,
.login-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-field-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .custom-field-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .login-field-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-field-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-field-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-field-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .custom-field-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-field-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container,
.login-page .forgot-password-component-container .reset-password-component .login-form form .custom-field-container,
.login-page .forgot-password-component-container .reset-password-component .login-form form .login-field-container,
.login-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-field-container,
.login-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-field-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .custom-field-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-field-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .custom-field-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-field-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container,
.login-page .reset-password-component-container .login-component .login-form form .custom-field-container,
.login-page .reset-password-component-container .login-component .login-form form .login-field-container,
.login-page .reset-password-component-container .login-component .login-form form .forgot-password-field-container,
.login-page .reset-password-component-container .login-component .login-form form .reset-password-field-container,
.login-page .reset-password-component-container .login-component .forgot-password-form form .custom-field-container,
.login-page .reset-password-component-container .login-component .forgot-password-form form .login-field-container,
.login-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-field-container,
.login-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-field-container,
.login-page .reset-password-component-container .login-component .reset-password-form form .custom-field-container,
.login-page .reset-password-component-container .login-component .reset-password-form form .login-field-container,
.login-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-field-container,
.login-page .reset-password-component-container .login-component .reset-password-form form .reset-password-field-container,
.login-page .reset-password-component-container .forgot-password-component .login-form form .custom-field-container,
.login-page .reset-password-component-container .forgot-password-component .login-form form .login-field-container,
.login-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-field-container,
.login-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-field-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-field-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .custom-field-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-field-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container,
.login-page .reset-password-component-container .reset-password-component .login-form form .custom-field-container,
.login-page .reset-password-component-container .reset-password-component .login-form form .login-field-container,
.login-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-field-container,
.login-page .reset-password-component-container .reset-password-component .login-form form .reset-password-field-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .custom-field-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-field-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .custom-field-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .login-field-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container,
.forgot-password-page .login-component-container .login-component .login-form form .custom-field-container,
.forgot-password-page .login-component-container .login-component .login-form form .login-field-container,
.forgot-password-page .login-component-container .login-component .login-form form .forgot-password-field-container,
.forgot-password-page .login-component-container .login-component .login-form form .reset-password-field-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .custom-field-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .login-field-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-field-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .reset-password-field-container,
.forgot-password-page .login-component-container .login-component .reset-password-form form .custom-field-container,
.forgot-password-page .login-component-container .login-component .reset-password-form form .login-field-container,
.forgot-password-page .login-component-container .login-component .reset-password-form form .forgot-password-field-container,
.forgot-password-page .login-component-container .login-component .reset-password-form form .reset-password-field-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .custom-field-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .login-field-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-field-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .reset-password-field-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .custom-field-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-field-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .custom-field-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .login-field-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-field-container,
.forgot-password-page .login-component-container .reset-password-component .login-form form .custom-field-container,
.forgot-password-page .login-component-container .reset-password-component .login-form form .login-field-container,
.forgot-password-page .login-component-container .reset-password-component .login-form form .forgot-password-field-container,
.forgot-password-page .login-component-container .reset-password-component .login-form form .reset-password-field-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .custom-field-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .login-field-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-field-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .custom-field-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .login-field-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-field-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-field-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .custom-field-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .login-field-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-field-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .reset-password-field-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .custom-field-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-field-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-field-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-field-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .custom-field-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .login-field-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-field-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-field-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .custom-field-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-field-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-field-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-field-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-field-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .custom-field-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-field-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .custom-field-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .login-field-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-field-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-field-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .custom-field-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-field-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .custom-field-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-field-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container,
.forgot-password-page .reset-password-component-container .login-component .login-form form .custom-field-container,
.forgot-password-page .reset-password-component-container .login-component .login-form form .login-field-container,
.forgot-password-page .reset-password-component-container .login-component .login-form form .forgot-password-field-container,
.forgot-password-page .reset-password-component-container .login-component .login-form form .reset-password-field-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .custom-field-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .login-field-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-field-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-field-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .custom-field-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .login-field-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-field-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-field-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .custom-field-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .login-field-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-field-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-field-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-field-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .custom-field-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-field-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .custom-field-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .login-field-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-field-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-field-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .custom-field-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-field-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .custom-field-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-field-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container,
.reset-password-page .login-component-container .login-component .login-form form .custom-field-container,
.reset-password-page .login-component-container .login-component .login-form form .login-field-container,
.reset-password-page .login-component-container .login-component .login-form form .forgot-password-field-container,
.reset-password-page .login-component-container .login-component .login-form form .reset-password-field-container,
.reset-password-page .login-component-container .login-component .forgot-password-form form .custom-field-container,
.reset-password-page .login-component-container .login-component .forgot-password-form form .login-field-container,
.reset-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-field-container,
.reset-password-page .login-component-container .login-component .forgot-password-form form .reset-password-field-container,
.reset-password-page .login-component-container .login-component .reset-password-form form .custom-field-container,
.reset-password-page .login-component-container .login-component .reset-password-form form .login-field-container,
.reset-password-page .login-component-container .login-component .reset-password-form form .forgot-password-field-container,
.reset-password-page .login-component-container .login-component .reset-password-form form .reset-password-field-container,
.reset-password-page .login-component-container .forgot-password-component .login-form form .custom-field-container,
.reset-password-page .login-component-container .forgot-password-component .login-form form .login-field-container,
.reset-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-field-container,
.reset-password-page .login-component-container .forgot-password-component .login-form form .reset-password-field-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .custom-field-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-field-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .custom-field-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .login-field-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-field-container,
.reset-password-page .login-component-container .reset-password-component .login-form form .custom-field-container,
.reset-password-page .login-component-container .reset-password-component .login-form form .login-field-container,
.reset-password-page .login-component-container .reset-password-component .login-form form .forgot-password-field-container,
.reset-password-page .login-component-container .reset-password-component .login-form form .reset-password-field-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .custom-field-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .login-field-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-field-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .custom-field-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .login-field-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-field-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-field-container,
.reset-password-page .forgot-password-component-container .login-component .login-form form .custom-field-container,
.reset-password-page .forgot-password-component-container .login-component .login-form form .login-field-container,
.reset-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-field-container,
.reset-password-page .forgot-password-component-container .login-component .login-form form .reset-password-field-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .custom-field-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-field-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-field-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-field-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .custom-field-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .login-field-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-field-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-field-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .custom-field-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-field-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-field-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-field-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-field-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .custom-field-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-field-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .custom-field-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .login-field-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-field-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-field-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .custom-field-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-field-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .custom-field-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-field-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container,
.reset-password-page .reset-password-component-container .login-component .login-form form .custom-field-container,
.reset-password-page .reset-password-component-container .login-component .login-form form .login-field-container,
.reset-password-page .reset-password-component-container .login-component .login-form form .forgot-password-field-container,
.reset-password-page .reset-password-component-container .login-component .login-form form .reset-password-field-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .custom-field-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .login-field-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-field-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-field-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .custom-field-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .login-field-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-field-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-field-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .custom-field-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .login-field-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-field-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-field-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-field-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .custom-field-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-field-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .custom-field-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .login-field-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-field-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-field-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .custom-field-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-field-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .custom-field-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-field-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container {
  padding: 12px 0;
}
.login-page .login-component-container .login-component .login-form form .custom-field-container .custom-field,
.login-page .login-component-container .login-component .login-form form .login-field-container .custom-field,
.login-page .login-component-container .login-component .login-form form .forgot-password-field-container .custom-field,
.login-page .login-component-container .login-component .login-form form .reset-password-field-container .custom-field,
.login-page .login-component-container .login-component .forgot-password-form form .custom-field-container .custom-field,
.login-page .login-component-container .login-component .forgot-password-form form .login-field-container .custom-field,
.login-page .login-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field,
.login-page .login-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field,
.login-page .login-component-container .login-component .reset-password-form form .custom-field-container .custom-field,
.login-page .login-component-container .login-component .reset-password-form form .login-field-container .custom-field,
.login-page .login-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field,
.login-page .login-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field,
.login-page .login-component-container .forgot-password-component .login-form form .custom-field-container .custom-field,
.login-page .login-component-container .forgot-password-component .login-form form .login-field-container .custom-field,
.login-page .login-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field,
.login-page .login-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.login-page .login-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field,
.login-page .login-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field,
.login-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.login-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field,
.login-page .login-component-container .reset-password-component .login-form form .custom-field-container .custom-field,
.login-page .login-component-container .reset-password-component .login-form form .login-field-container .custom-field,
.login-page .login-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field,
.login-page .login-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field,
.login-page .login-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field,
.login-page .login-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field,
.login-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.login-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.login-page .login-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field,
.login-page .login-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field,
.login-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.login-page .login-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field,
.login-page .forgot-password-component-container .login-component .login-form form .custom-field-container .custom-field,
.login-page .forgot-password-component-container .login-component .login-form form .login-field-container .custom-field,
.login-page .forgot-password-component-container .login-component .login-form form .forgot-password-field-container .custom-field,
.login-page .forgot-password-component-container .login-component .login-form form .reset-password-field-container .custom-field,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .custom-field-container .custom-field,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .login-field-container .custom-field,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field,
.login-page .forgot-password-component-container .login-component .reset-password-form form .custom-field-container .custom-field,
.login-page .forgot-password-component-container .login-component .reset-password-form form .login-field-container .custom-field,
.login-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field,
.login-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .custom-field-container .custom-field,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .login-field-container .custom-field,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field,
.login-page .forgot-password-component-container .reset-password-component .login-form form .custom-field-container .custom-field,
.login-page .forgot-password-component-container .reset-password-component .login-form form .login-field-container .custom-field,
.login-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field,
.login-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field,
.login-page .reset-password-component-container .login-component .login-form form .custom-field-container .custom-field,
.login-page .reset-password-component-container .login-component .login-form form .login-field-container .custom-field,
.login-page .reset-password-component-container .login-component .login-form form .forgot-password-field-container .custom-field,
.login-page .reset-password-component-container .login-component .login-form form .reset-password-field-container .custom-field,
.login-page .reset-password-component-container .login-component .forgot-password-form form .custom-field-container .custom-field,
.login-page .reset-password-component-container .login-component .forgot-password-form form .login-field-container .custom-field,
.login-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field,
.login-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field,
.login-page .reset-password-component-container .login-component .reset-password-form form .custom-field-container .custom-field,
.login-page .reset-password-component-container .login-component .reset-password-form form .login-field-container .custom-field,
.login-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field,
.login-page .reset-password-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field,
.login-page .reset-password-component-container .forgot-password-component .login-form form .custom-field-container .custom-field,
.login-page .reset-password-component-container .forgot-password-component .login-form form .login-field-container .custom-field,
.login-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field,
.login-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field,
.login-page .reset-password-component-container .reset-password-component .login-form form .custom-field-container .custom-field,
.login-page .reset-password-component-container .reset-password-component .login-form form .login-field-container .custom-field,
.login-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field,
.login-page .reset-password-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .login-component-container .login-component .login-form form .custom-field-container .custom-field,
.forgot-password-page .login-component-container .login-component .login-form form .login-field-container .custom-field,
.forgot-password-page .login-component-container .login-component .login-form form .forgot-password-field-container .custom-field,
.forgot-password-page .login-component-container .login-component .login-form form .reset-password-field-container .custom-field,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .custom-field-container .custom-field,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .login-field-container .custom-field,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .login-component-container .login-component .reset-password-form form .custom-field-container .custom-field,
.forgot-password-page .login-component-container .login-component .reset-password-form form .login-field-container .custom-field,
.forgot-password-page .login-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .login-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .custom-field-container .custom-field,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .login-field-container .custom-field,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .login-component-container .reset-password-component .login-form form .custom-field-container .custom-field,
.forgot-password-page .login-component-container .reset-password-component .login-form form .login-field-container .custom-field,
.forgot-password-page .login-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field,
.forgot-password-page .login-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .custom-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .login-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .reset-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .custom-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .custom-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .login-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .custom-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .custom-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .login-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .login-component .login-form form .custom-field-container .custom-field,
.forgot-password-page .reset-password-component-container .login-component .login-form form .login-field-container .custom-field,
.forgot-password-page .reset-password-component-container .login-component .login-form form .forgot-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .login-component .login-form form .reset-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .custom-field-container .custom-field,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .login-field-container .custom-field,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .custom-field-container .custom-field,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .login-field-container .custom-field,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .custom-field-container .custom-field,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .login-field-container .custom-field,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .custom-field-container .custom-field,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .login-field-container .custom-field,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field,
.reset-password-page .login-component-container .login-component .login-form form .custom-field-container .custom-field,
.reset-password-page .login-component-container .login-component .login-form form .login-field-container .custom-field,
.reset-password-page .login-component-container .login-component .login-form form .forgot-password-field-container .custom-field,
.reset-password-page .login-component-container .login-component .login-form form .reset-password-field-container .custom-field,
.reset-password-page .login-component-container .login-component .forgot-password-form form .custom-field-container .custom-field,
.reset-password-page .login-component-container .login-component .forgot-password-form form .login-field-container .custom-field,
.reset-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .login-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field,
.reset-password-page .login-component-container .login-component .reset-password-form form .custom-field-container .custom-field,
.reset-password-page .login-component-container .login-component .reset-password-form form .login-field-container .custom-field,
.reset-password-page .login-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .login-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field,
.reset-password-page .login-component-container .forgot-password-component .login-form form .custom-field-container .custom-field,
.reset-password-page .login-component-container .forgot-password-component .login-form form .login-field-container .custom-field,
.reset-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field,
.reset-password-page .login-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field,
.reset-password-page .login-component-container .reset-password-component .login-form form .custom-field-container .custom-field,
.reset-password-page .login-component-container .reset-password-component .login-form form .login-field-container .custom-field,
.reset-password-page .login-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field,
.reset-password-page .login-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .login-component .login-form form .custom-field-container .custom-field,
.reset-password-page .forgot-password-component-container .login-component .login-form form .login-field-container .custom-field,
.reset-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .login-component .login-form form .reset-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .custom-field-container .custom-field,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-field-container .custom-field,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .custom-field-container .custom-field,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .login-field-container .custom-field,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .custom-field-container .custom-field,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-field-container .custom-field,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .custom-field-container .custom-field,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .login-field-container .custom-field,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .login-component .login-form form .custom-field-container .custom-field,
.reset-password-page .reset-password-component-container .login-component .login-form form .login-field-container .custom-field,
.reset-password-page .reset-password-component-container .login-component .login-form form .forgot-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .login-component .login-form form .reset-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .custom-field-container .custom-field,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .login-field-container .custom-field,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .custom-field-container .custom-field,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .login-field-container .custom-field,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .custom-field-container .custom-field,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .login-field-container .custom-field,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .custom-field-container .custom-field,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .login-field-container .custom-field,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field {
  margin: 5px 0;
}
.login-page .login-component-container .login-component .login-form form .custom-field-container .custom-field .custom-textfield,
.login-page .login-component-container .login-component .login-form form .login-field-container .custom-field .custom-textfield,
.login-page .login-component-container .login-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .login-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .login-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .login-component-container .login-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .login-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .login-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .login-component-container .login-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .login-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .forgot-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.login-page .login-component-container .forgot-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.login-page .login-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .login-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .reset-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.login-page .login-component-container .reset-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.login-page .login-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .login-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .login-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .login-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .login-component .login-form form .custom-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .login-component .login-form form .login-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .login-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .login-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .login-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .login-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .reset-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .reset-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .login-component .login-form form .custom-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .login-component .login-form form .login-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .login-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .login-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .login-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .login-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .login-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .login-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .forgot-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .forgot-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .reset-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .reset-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .login-component .login-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .login-component .login-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .login-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .login-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .login-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .login-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .reset-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .reset-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .login-component .login-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .login-component .login-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .login-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .login-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .login-component .login-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .login-component .login-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .login-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .login-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .login-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .login-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .login-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .login-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .forgot-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .forgot-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .reset-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .reset-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .login-component .login-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .login-component .login-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .login-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .login-component .login-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .login-component .login-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .login-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .login-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .custom-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-field-container .custom-field .custom-textfield,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-field-container .custom-field .custom-textfield {
  width: 100% !important;
}
.login-page .login-component-container .login-component .login-form form .login-button,
.login-page .login-component-container .login-component .login-form form .forgot-password-button,
.login-page .login-component-container .login-component .login-form form .reset-password-button,
.login-page .login-component-container .login-component .forgot-password-form form .login-button,
.login-page .login-component-container .login-component .forgot-password-form form .forgot-password-button,
.login-page .login-component-container .login-component .forgot-password-form form .reset-password-button,
.login-page .login-component-container .login-component .reset-password-form form .login-button,
.login-page .login-component-container .login-component .reset-password-form form .forgot-password-button,
.login-page .login-component-container .login-component .reset-password-form form .reset-password-button,
.login-page .login-component-container .forgot-password-component .login-form form .login-button,
.login-page .login-component-container .forgot-password-component .login-form form .forgot-password-button,
.login-page .login-component-container .forgot-password-component .login-form form .reset-password-button,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .login-button,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-button,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-button,
.login-page .login-component-container .forgot-password-component .reset-password-form form .login-button,
.login-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-button,
.login-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-button,
.login-page .login-component-container .reset-password-component .login-form form .login-button,
.login-page .login-component-container .reset-password-component .login-form form .forgot-password-button,
.login-page .login-component-container .reset-password-component .login-form form .reset-password-button,
.login-page .login-component-container .reset-password-component .forgot-password-form form .login-button,
.login-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-button,
.login-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-button,
.login-page .login-component-container .reset-password-component .reset-password-form form .login-button,
.login-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-button,
.login-page .login-component-container .reset-password-component .reset-password-form form .reset-password-button,
.login-page .forgot-password-component-container .login-component .login-form form .login-button,
.login-page .forgot-password-component-container .login-component .login-form form .forgot-password-button,
.login-page .forgot-password-component-container .login-component .login-form form .reset-password-button,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .login-button,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-button,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-button,
.login-page .forgot-password-component-container .login-component .reset-password-form form .login-button,
.login-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-button,
.login-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-button,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .login-button,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-button,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-button,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-button,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-button,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-button,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-button,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-button,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-button,
.login-page .forgot-password-component-container .reset-password-component .login-form form .login-button,
.login-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-button,
.login-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-button,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-button,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-button,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-button,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-button,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-button,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-button,
.login-page .reset-password-component-container .login-component .login-form form .login-button,
.login-page .reset-password-component-container .login-component .login-form form .forgot-password-button,
.login-page .reset-password-component-container .login-component .login-form form .reset-password-button,
.login-page .reset-password-component-container .login-component .forgot-password-form form .login-button,
.login-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-button,
.login-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-button,
.login-page .reset-password-component-container .login-component .reset-password-form form .login-button,
.login-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-button,
.login-page .reset-password-component-container .login-component .reset-password-form form .reset-password-button,
.login-page .reset-password-component-container .forgot-password-component .login-form form .login-button,
.login-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-button,
.login-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-button,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-button,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-button,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-button,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-button,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-button,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-button,
.login-page .reset-password-component-container .reset-password-component .login-form form .login-button,
.login-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-button,
.login-page .reset-password-component-container .reset-password-component .login-form form .reset-password-button,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-button,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-button,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-button,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .login-button,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-button,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-button,
.forgot-password-page .login-component-container .login-component .login-form form .login-button,
.forgot-password-page .login-component-container .login-component .login-form form .forgot-password-button,
.forgot-password-page .login-component-container .login-component .login-form form .reset-password-button,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .login-button,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-button,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .reset-password-button,
.forgot-password-page .login-component-container .login-component .reset-password-form form .login-button,
.forgot-password-page .login-component-container .login-component .reset-password-form form .forgot-password-button,
.forgot-password-page .login-component-container .login-component .reset-password-form form .reset-password-button,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .login-button,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-button,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .reset-password-button,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-button,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-button,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-button,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .login-button,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-button,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-button,
.forgot-password-page .login-component-container .reset-password-component .login-form form .login-button,
.forgot-password-page .login-component-container .reset-password-component .login-form form .forgot-password-button,
.forgot-password-page .login-component-container .reset-password-component .login-form form .reset-password-button,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .login-button,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-button,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-button,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .login-button,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-button,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-button,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .login-button,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-button,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .reset-password-button,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-button,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-button,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-button,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .login-button,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-button,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-button,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-button,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-button,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-button,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-button,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-button,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-button,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-button,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-button,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-button,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .login-button,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-button,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-button,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-button,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-button,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-button,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-button,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-button,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-button,
.forgot-password-page .reset-password-component-container .login-component .login-form form .login-button,
.forgot-password-page .reset-password-component-container .login-component .login-form form .forgot-password-button,
.forgot-password-page .reset-password-component-container .login-component .login-form form .reset-password-button,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .login-button,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-button,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-button,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .login-button,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-button,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-button,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .login-button,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-button,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-button,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-button,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-button,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-button,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-button,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-button,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-button,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .login-button,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-button,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-button,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-button,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-button,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-button,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-button,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-button,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-button,
.reset-password-page .login-component-container .login-component .login-form form .login-button,
.reset-password-page .login-component-container .login-component .login-form form .forgot-password-button,
.reset-password-page .login-component-container .login-component .login-form form .reset-password-button,
.reset-password-page .login-component-container .login-component .forgot-password-form form .login-button,
.reset-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-button,
.reset-password-page .login-component-container .login-component .forgot-password-form form .reset-password-button,
.reset-password-page .login-component-container .login-component .reset-password-form form .login-button,
.reset-password-page .login-component-container .login-component .reset-password-form form .forgot-password-button,
.reset-password-page .login-component-container .login-component .reset-password-form form .reset-password-button,
.reset-password-page .login-component-container .forgot-password-component .login-form form .login-button,
.reset-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-button,
.reset-password-page .login-component-container .forgot-password-component .login-form form .reset-password-button,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-button,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-button,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-button,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .login-button,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-button,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-button,
.reset-password-page .login-component-container .reset-password-component .login-form form .login-button,
.reset-password-page .login-component-container .reset-password-component .login-form form .forgot-password-button,
.reset-password-page .login-component-container .reset-password-component .login-form form .reset-password-button,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .login-button,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-button,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-button,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .login-button,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-button,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-button,
.reset-password-page .forgot-password-component-container .login-component .login-form form .login-button,
.reset-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-button,
.reset-password-page .forgot-password-component-container .login-component .login-form form .reset-password-button,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-button,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-button,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-button,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .login-button,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-button,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-button,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-button,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-button,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-button,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-button,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-button,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-button,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-button,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-button,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-button,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .login-button,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-button,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-button,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-button,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-button,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-button,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-button,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-button,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-button,
.reset-password-page .reset-password-component-container .login-component .login-form form .login-button,
.reset-password-page .reset-password-component-container .login-component .login-form form .forgot-password-button,
.reset-password-page .reset-password-component-container .login-component .login-form form .reset-password-button,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .login-button,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-button,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-button,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .login-button,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-button,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-button,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .login-button,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-button,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-button,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-button,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-button,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-button,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-button,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-button,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-button,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .login-button,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-button,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-button,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-button,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-button,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-button,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-button,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-button,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-button {
  width: 100%;
  margin: 20px 0;
  padding: 10px 0;
  background: #642ba5;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-transform: none;
}
.login-page .login-component-container .login-component .login-form form .forgot-password-link-container,
.login-page .login-component-container .login-component .forgot-password-form form .forgot-password-link-container,
.login-page .login-component-container .login-component .reset-password-form form .forgot-password-link-container,
.login-page .login-component-container .forgot-password-component .login-form form .forgot-password-link-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container,
.login-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container,
.login-page .login-component-container .reset-password-component .login-form form .forgot-password-link-container,
.login-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container,
.login-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-link-container,
.login-page .forgot-password-component-container .login-component .login-form form .forgot-password-link-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-link-container,
.login-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-link-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-link-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container,
.login-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-link-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-link-container,
.login-page .reset-password-component-container .login-component .login-form form .forgot-password-link-container,
.login-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-link-container,
.login-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-link-container,
.login-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-link-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container,
.login-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-link-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-link-container,
.forgot-password-page .login-component-container .login-component .login-form form .forgot-password-link-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-link-container,
.forgot-password-page .login-component-container .login-component .reset-password-form form .forgot-password-link-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-link-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container,
.forgot-password-page .login-component-container .reset-password-component .login-form form .forgot-password-link-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-link-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-link-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-link-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-link-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-link-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-link-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-link-container,
.forgot-password-page .reset-password-component-container .login-component .login-form form .forgot-password-link-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-link-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-link-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-link-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-link-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-link-container,
.reset-password-page .login-component-container .login-component .login-form form .forgot-password-link-container,
.reset-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-link-container,
.reset-password-page .login-component-container .login-component .reset-password-form form .forgot-password-link-container,
.reset-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-link-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container,
.reset-password-page .login-component-container .reset-password-component .login-form form .forgot-password-link-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-link-container,
.reset-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-link-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-link-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-link-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-link-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-link-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-link-container,
.reset-password-page .reset-password-component-container .login-component .login-form form .forgot-password-link-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-link-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-link-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-link-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-link-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-link-container {
  justify-content: center;
}
.login-page .login-component-container .login-component .login-form form .forgot-password-link-container .forgot-password-link,
.login-page .login-component-container .login-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .login-component-container .login-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .login-component-container .forgot-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .login-component-container .reset-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.login-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .forgot-password-component-container .login-component .login-form form .forgot-password-link-container .forgot-password-link,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .reset-password-component-container .login-component .login-form form .forgot-password-link-container .forgot-password-link,
.login-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .login-component-container .login-component .login-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .login-component-container .login-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .login-component-container .reset-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .reset-password-component-container .login-component .login-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .login-component-container .login-component .login-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .login-component-container .login-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .login-component-container .reset-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .reset-password-component-container .login-component .login-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-link-container .forgot-password-link,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-link-container .forgot-password-link {
  color: #ff28df;
  font-size: 14px;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  text-align: center;
  text-decoration: none;
}
.login-page .login-component-container .login-component .login-form form .error-container,
.login-page .login-component-container .login-component .forgot-password-form form .error-container,
.login-page .login-component-container .login-component .reset-password-form form .error-container,
.login-page .login-component-container .forgot-password-component .login-form form .error-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .error-container,
.login-page .login-component-container .forgot-password-component .reset-password-form form .error-container,
.login-page .login-component-container .reset-password-component .login-form form .error-container,
.login-page .login-component-container .reset-password-component .forgot-password-form form .error-container,
.login-page .login-component-container .reset-password-component .reset-password-form form .error-container,
.login-page .forgot-password-component-container .login-component .login-form form .error-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .error-container,
.login-page .forgot-password-component-container .login-component .reset-password-form form .error-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .error-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .error-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .error-container,
.login-page .forgot-password-component-container .reset-password-component .login-form form .error-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .error-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .error-container,
.login-page .reset-password-component-container .login-component .login-form form .error-container,
.login-page .reset-password-component-container .login-component .forgot-password-form form .error-container,
.login-page .reset-password-component-container .login-component .reset-password-form form .error-container,
.login-page .reset-password-component-container .forgot-password-component .login-form form .error-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .error-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .error-container,
.login-page .reset-password-component-container .reset-password-component .login-form form .error-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .error-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .error-container,
.forgot-password-page .login-component-container .login-component .login-form form .error-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .error-container,
.forgot-password-page .login-component-container .login-component .reset-password-form form .error-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .error-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .error-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .error-container,
.forgot-password-page .login-component-container .reset-password-component .login-form form .error-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .error-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .error-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .error-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .error-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .error-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .error-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .error-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .error-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .error-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .error-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .error-container,
.forgot-password-page .reset-password-component-container .login-component .login-form form .error-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .error-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .error-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .error-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .error-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .error-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .error-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .error-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .error-container,
.reset-password-page .login-component-container .login-component .login-form form .error-container,
.reset-password-page .login-component-container .login-component .forgot-password-form form .error-container,
.reset-password-page .login-component-container .login-component .reset-password-form form .error-container,
.reset-password-page .login-component-container .forgot-password-component .login-form form .error-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .error-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .error-container,
.reset-password-page .login-component-container .reset-password-component .login-form form .error-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .error-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .error-container,
.reset-password-page .forgot-password-component-container .login-component .login-form form .error-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .error-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .error-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .error-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .error-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .error-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .error-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .error-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .error-container,
.reset-password-page .reset-password-component-container .login-component .login-form form .error-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .error-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .error-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .error-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .error-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .error-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .error-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .error-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .error-container {
  width: 100%;
  padding: 5px 0 0;
}
.login-page .login-component-container .login-component .login-form form .error-container .error-text,
.login-page .login-component-container .login-component .forgot-password-form form .error-container .error-text,
.login-page .login-component-container .login-component .reset-password-form form .error-container .error-text,
.login-page .login-component-container .forgot-password-component .login-form form .error-container .error-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .error-container .error-text,
.login-page .login-component-container .forgot-password-component .reset-password-form form .error-container .error-text,
.login-page .login-component-container .reset-password-component .login-form form .error-container .error-text,
.login-page .login-component-container .reset-password-component .forgot-password-form form .error-container .error-text,
.login-page .login-component-container .reset-password-component .reset-password-form form .error-container .error-text,
.login-page .forgot-password-component-container .login-component .login-form form .error-container .error-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .error-container .error-text,
.login-page .forgot-password-component-container .login-component .reset-password-form form .error-container .error-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .error-container .error-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .error-container .error-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .error-container .error-text,
.login-page .forgot-password-component-container .reset-password-component .login-form form .error-container .error-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .error-container .error-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .error-container .error-text,
.login-page .reset-password-component-container .login-component .login-form form .error-container .error-text,
.login-page .reset-password-component-container .login-component .forgot-password-form form .error-container .error-text,
.login-page .reset-password-component-container .login-component .reset-password-form form .error-container .error-text,
.login-page .reset-password-component-container .forgot-password-component .login-form form .error-container .error-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .error-container .error-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .error-container .error-text,
.login-page .reset-password-component-container .reset-password-component .login-form form .error-container .error-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .error-container .error-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .error-container .error-text,
.forgot-password-page .login-component-container .login-component .login-form form .error-container .error-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .error-container .error-text,
.forgot-password-page .login-component-container .login-component .reset-password-form form .error-container .error-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .error-container .error-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .error-container .error-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .error-container .error-text,
.forgot-password-page .login-component-container .reset-password-component .login-form form .error-container .error-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .error-container .error-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .error-container .error-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .error-container .error-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .error-container .error-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .error-container .error-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .error-container .error-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .error-container .error-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .error-container .error-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .error-container .error-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .error-container .error-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .error-container .error-text,
.forgot-password-page .reset-password-component-container .login-component .login-form form .error-container .error-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .error-container .error-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .error-container .error-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .error-container .error-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .error-container .error-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .error-container .error-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .error-container .error-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .error-container .error-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .error-container .error-text,
.reset-password-page .login-component-container .login-component .login-form form .error-container .error-text,
.reset-password-page .login-component-container .login-component .forgot-password-form form .error-container .error-text,
.reset-password-page .login-component-container .login-component .reset-password-form form .error-container .error-text,
.reset-password-page .login-component-container .forgot-password-component .login-form form .error-container .error-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .error-container .error-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .error-container .error-text,
.reset-password-page .login-component-container .reset-password-component .login-form form .error-container .error-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .error-container .error-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .error-container .error-text,
.reset-password-page .forgot-password-component-container .login-component .login-form form .error-container .error-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .error-container .error-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .error-container .error-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .error-container .error-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .error-container .error-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .error-container .error-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .error-container .error-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .error-container .error-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .error-container .error-text,
.reset-password-page .reset-password-component-container .login-component .login-form form .error-container .error-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .error-container .error-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .error-container .error-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .error-container .error-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .error-container .error-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .error-container .error-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .error-container .error-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .error-container .error-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .error-container .error-text {
  font-family: Inter-Light, Arial, Helvetica, sans-serif;
  color: #e00000;
  font-size: 13px;
  margin: 0;
}
.login-page .login-component-container .login-component .login-form form .login-loader,
.login-page .login-component-container .login-component .login-form form .forgot-password-loader,
.login-page .login-component-container .login-component .login-form form .reset-password-loader,
.login-page .login-component-container .login-component .forgot-password-form form .login-loader,
.login-page .login-component-container .login-component .forgot-password-form form .forgot-password-loader,
.login-page .login-component-container .login-component .forgot-password-form form .reset-password-loader,
.login-page .login-component-container .login-component .reset-password-form form .login-loader,
.login-page .login-component-container .login-component .reset-password-form form .forgot-password-loader,
.login-page .login-component-container .login-component .reset-password-form form .reset-password-loader,
.login-page .login-component-container .forgot-password-component .login-form form .login-loader,
.login-page .login-component-container .forgot-password-component .login-form form .forgot-password-loader,
.login-page .login-component-container .forgot-password-component .login-form form .reset-password-loader,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .login-loader,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-loader,
.login-page .login-component-container .forgot-password-component .reset-password-form form .login-loader,
.login-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-loader,
.login-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-loader,
.login-page .login-component-container .reset-password-component .login-form form .login-loader,
.login-page .login-component-container .reset-password-component .login-form form .forgot-password-loader,
.login-page .login-component-container .reset-password-component .login-form form .reset-password-loader,
.login-page .login-component-container .reset-password-component .forgot-password-form form .login-loader,
.login-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-loader,
.login-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-loader,
.login-page .login-component-container .reset-password-component .reset-password-form form .login-loader,
.login-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-loader,
.login-page .login-component-container .reset-password-component .reset-password-form form .reset-password-loader,
.login-page .forgot-password-component-container .login-component .login-form form .login-loader,
.login-page .forgot-password-component-container .login-component .login-form form .forgot-password-loader,
.login-page .forgot-password-component-container .login-component .login-form form .reset-password-loader,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .login-loader,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-loader,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-loader,
.login-page .forgot-password-component-container .login-component .reset-password-form form .login-loader,
.login-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-loader,
.login-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-loader,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .login-loader,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-loader,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-loader,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-loader,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-loader,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-loader,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-loader,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-loader,
.login-page .forgot-password-component-container .reset-password-component .login-form form .login-loader,
.login-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-loader,
.login-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-loader,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-loader,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-loader,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-loader,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-loader,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-loader,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-loader,
.login-page .reset-password-component-container .login-component .login-form form .login-loader,
.login-page .reset-password-component-container .login-component .login-form form .forgot-password-loader,
.login-page .reset-password-component-container .login-component .login-form form .reset-password-loader,
.login-page .reset-password-component-container .login-component .forgot-password-form form .login-loader,
.login-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-loader,
.login-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-loader,
.login-page .reset-password-component-container .login-component .reset-password-form form .login-loader,
.login-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-loader,
.login-page .reset-password-component-container .login-component .reset-password-form form .reset-password-loader,
.login-page .reset-password-component-container .forgot-password-component .login-form form .login-loader,
.login-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-loader,
.login-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-loader,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-loader,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-loader,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-loader,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-loader,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-loader,
.login-page .reset-password-component-container .reset-password-component .login-form form .login-loader,
.login-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-loader,
.login-page .reset-password-component-container .reset-password-component .login-form form .reset-password-loader,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-loader,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-loader,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-loader,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .login-loader,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-loader,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-loader,
.forgot-password-page .login-component-container .login-component .login-form form .login-loader,
.forgot-password-page .login-component-container .login-component .login-form form .forgot-password-loader,
.forgot-password-page .login-component-container .login-component .login-form form .reset-password-loader,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .login-loader,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-loader,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .reset-password-loader,
.forgot-password-page .login-component-container .login-component .reset-password-form form .login-loader,
.forgot-password-page .login-component-container .login-component .reset-password-form form .forgot-password-loader,
.forgot-password-page .login-component-container .login-component .reset-password-form form .reset-password-loader,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .login-loader,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-loader,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .reset-password-loader,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-loader,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-loader,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .login-loader,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-loader,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-loader,
.forgot-password-page .login-component-container .reset-password-component .login-form form .login-loader,
.forgot-password-page .login-component-container .reset-password-component .login-form form .forgot-password-loader,
.forgot-password-page .login-component-container .reset-password-component .login-form form .reset-password-loader,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .login-loader,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-loader,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-loader,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .login-loader,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-loader,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-loader,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .login-loader,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-loader,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .reset-password-loader,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-loader,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-loader,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-loader,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .login-loader,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-loader,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-loader,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-loader,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-loader,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-loader,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-loader,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-loader,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-loader,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-loader,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-loader,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .login-loader,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-loader,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-loader,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-loader,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-loader,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-loader,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-loader,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-loader,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-loader,
.forgot-password-page .reset-password-component-container .login-component .login-form form .login-loader,
.forgot-password-page .reset-password-component-container .login-component .login-form form .forgot-password-loader,
.forgot-password-page .reset-password-component-container .login-component .login-form form .reset-password-loader,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .login-loader,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-loader,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-loader,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .login-loader,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-loader,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-loader,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .login-loader,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-loader,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-loader,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-loader,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-loader,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-loader,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-loader,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-loader,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .login-loader,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-loader,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-loader,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-loader,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-loader,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-loader,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-loader,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-loader,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-loader,
.reset-password-page .login-component-container .login-component .login-form form .login-loader,
.reset-password-page .login-component-container .login-component .login-form form .forgot-password-loader,
.reset-password-page .login-component-container .login-component .login-form form .reset-password-loader,
.reset-password-page .login-component-container .login-component .forgot-password-form form .login-loader,
.reset-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-loader,
.reset-password-page .login-component-container .login-component .forgot-password-form form .reset-password-loader,
.reset-password-page .login-component-container .login-component .reset-password-form form .login-loader,
.reset-password-page .login-component-container .login-component .reset-password-form form .forgot-password-loader,
.reset-password-page .login-component-container .login-component .reset-password-form form .reset-password-loader,
.reset-password-page .login-component-container .forgot-password-component .login-form form .login-loader,
.reset-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-loader,
.reset-password-page .login-component-container .forgot-password-component .login-form form .reset-password-loader,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-loader,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-loader,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .login-loader,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-loader,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-loader,
.reset-password-page .login-component-container .reset-password-component .login-form form .login-loader,
.reset-password-page .login-component-container .reset-password-component .login-form form .forgot-password-loader,
.reset-password-page .login-component-container .reset-password-component .login-form form .reset-password-loader,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .login-loader,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-loader,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-loader,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .login-loader,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-loader,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-loader,
.reset-password-page .forgot-password-component-container .login-component .login-form form .login-loader,
.reset-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-loader,
.reset-password-page .forgot-password-component-container .login-component .login-form form .reset-password-loader,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-loader,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-loader,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-loader,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .login-loader,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-loader,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-loader,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-loader,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-loader,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-loader,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-loader,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-loader,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-loader,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-loader,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-loader,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .login-loader,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-loader,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-loader,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-loader,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-loader,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-loader,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-loader,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-loader,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-loader,
.reset-password-page .reset-password-component-container .login-component .login-form form .login-loader,
.reset-password-page .reset-password-component-container .login-component .login-form form .forgot-password-loader,
.reset-password-page .reset-password-component-container .login-component .login-form form .reset-password-loader,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .login-loader,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-loader,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-loader,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .login-loader,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-loader,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-loader,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .login-loader,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-loader,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-loader,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-loader,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-loader,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-loader,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-loader,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-loader,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .login-loader,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-loader,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-loader,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-loader,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-loader,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-loader,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-loader,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-loader,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-loader {
  justify-content: center;
  padding: 25px 0 5px;
}
.login-page .login-component-container .login-component .login-form form .login-loader span,
.login-page .login-component-container .login-component .login-form form .forgot-password-loader span,
.login-page .login-component-container .login-component .login-form form .reset-password-loader span,
.login-page .login-component-container .login-component .forgot-password-form form .login-loader span,
.login-page .login-component-container .login-component .forgot-password-form form .forgot-password-loader span,
.login-page .login-component-container .login-component .forgot-password-form form .reset-password-loader span,
.login-page .login-component-container .login-component .reset-password-form form .login-loader span,
.login-page .login-component-container .login-component .reset-password-form form .forgot-password-loader span,
.login-page .login-component-container .login-component .reset-password-form form .reset-password-loader span,
.login-page .login-component-container .forgot-password-component .login-form form .login-loader span,
.login-page .login-component-container .forgot-password-component .login-form form .forgot-password-loader span,
.login-page .login-component-container .forgot-password-component .login-form form .reset-password-loader span,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .login-loader span,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader span,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-loader span,
.login-page .login-component-container .forgot-password-component .reset-password-form form .login-loader span,
.login-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-loader span,
.login-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-loader span,
.login-page .login-component-container .reset-password-component .login-form form .login-loader span,
.login-page .login-component-container .reset-password-component .login-form form .forgot-password-loader span,
.login-page .login-component-container .reset-password-component .login-form form .reset-password-loader span,
.login-page .login-component-container .reset-password-component .forgot-password-form form .login-loader span,
.login-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-loader span,
.login-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-loader span,
.login-page .login-component-container .reset-password-component .reset-password-form form .login-loader span,
.login-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-loader span,
.login-page .login-component-container .reset-password-component .reset-password-form form .reset-password-loader span,
.login-page .forgot-password-component-container .login-component .login-form form .login-loader span,
.login-page .forgot-password-component-container .login-component .login-form form .forgot-password-loader span,
.login-page .forgot-password-component-container .login-component .login-form form .reset-password-loader span,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .login-loader span,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-loader span,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-loader span,
.login-page .forgot-password-component-container .login-component .reset-password-form form .login-loader span,
.login-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-loader span,
.login-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-loader span,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .login-loader span,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-loader span,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-loader span,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-loader span,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader span,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-loader span,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-loader span,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-loader span,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-loader span,
.login-page .forgot-password-component-container .reset-password-component .login-form form .login-loader span,
.login-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-loader span,
.login-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-loader span,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-loader span,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-loader span,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-loader span,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-loader span,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-loader span,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-loader span,
.login-page .reset-password-component-container .login-component .login-form form .login-loader span,
.login-page .reset-password-component-container .login-component .login-form form .forgot-password-loader span,
.login-page .reset-password-component-container .login-component .login-form form .reset-password-loader span,
.login-page .reset-password-component-container .login-component .forgot-password-form form .login-loader span,
.login-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-loader span,
.login-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-loader span,
.login-page .reset-password-component-container .login-component .reset-password-form form .login-loader span,
.login-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-loader span,
.login-page .reset-password-component-container .login-component .reset-password-form form .reset-password-loader span,
.login-page .reset-password-component-container .forgot-password-component .login-form form .login-loader span,
.login-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-loader span,
.login-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-loader span,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-loader span,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader span,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-loader span,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-loader span,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-loader span,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-loader span,
.login-page .reset-password-component-container .reset-password-component .login-form form .login-loader span,
.login-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-loader span,
.login-page .reset-password-component-container .reset-password-component .login-form form .reset-password-loader span,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-loader span,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-loader span,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-loader span,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .login-loader span,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-loader span,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-loader span,
.forgot-password-page .login-component-container .login-component .login-form form .login-loader span,
.forgot-password-page .login-component-container .login-component .login-form form .forgot-password-loader span,
.forgot-password-page .login-component-container .login-component .login-form form .reset-password-loader span,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .login-loader span,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-loader span,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .reset-password-loader span,
.forgot-password-page .login-component-container .login-component .reset-password-form form .login-loader span,
.forgot-password-page .login-component-container .login-component .reset-password-form form .forgot-password-loader span,
.forgot-password-page .login-component-container .login-component .reset-password-form form .reset-password-loader span,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .login-loader span,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-loader span,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .reset-password-loader span,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-loader span,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader span,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-loader span,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .login-loader span,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-loader span,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-loader span,
.forgot-password-page .login-component-container .reset-password-component .login-form form .login-loader span,
.forgot-password-page .login-component-container .reset-password-component .login-form form .forgot-password-loader span,
.forgot-password-page .login-component-container .reset-password-component .login-form form .reset-password-loader span,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .login-loader span,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-loader span,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-loader span,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .login-loader span,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-loader span,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-loader span,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .login-loader span,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-loader span,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .reset-password-loader span,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-loader span,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-loader span,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-loader span,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .login-loader span,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-loader span,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-loader span,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-loader span,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-loader span,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-loader span,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-loader span,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader span,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-loader span,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-loader span,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-loader span,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-loader span,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .login-loader span,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-loader span,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-loader span,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-loader span,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-loader span,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-loader span,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-loader span,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-loader span,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-loader span,
.forgot-password-page .reset-password-component-container .login-component .login-form form .login-loader span,
.forgot-password-page .reset-password-component-container .login-component .login-form form .forgot-password-loader span,
.forgot-password-page .reset-password-component-container .login-component .login-form form .reset-password-loader span,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .login-loader span,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-loader span,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-loader span,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .login-loader span,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-loader span,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-loader span,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .login-loader span,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-loader span,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-loader span,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-loader span,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader span,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-loader span,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-loader span,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-loader span,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-loader span,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .login-loader span,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-loader span,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-loader span,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-loader span,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-loader span,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-loader span,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-loader span,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-loader span,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-loader span,
.reset-password-page .login-component-container .login-component .login-form form .login-loader span,
.reset-password-page .login-component-container .login-component .login-form form .forgot-password-loader span,
.reset-password-page .login-component-container .login-component .login-form form .reset-password-loader span,
.reset-password-page .login-component-container .login-component .forgot-password-form form .login-loader span,
.reset-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-loader span,
.reset-password-page .login-component-container .login-component .forgot-password-form form .reset-password-loader span,
.reset-password-page .login-component-container .login-component .reset-password-form form .login-loader span,
.reset-password-page .login-component-container .login-component .reset-password-form form .forgot-password-loader span,
.reset-password-page .login-component-container .login-component .reset-password-form form .reset-password-loader span,
.reset-password-page .login-component-container .forgot-password-component .login-form form .login-loader span,
.reset-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-loader span,
.reset-password-page .login-component-container .forgot-password-component .login-form form .reset-password-loader span,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-loader span,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader span,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-loader span,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .login-loader span,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-loader span,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-loader span,
.reset-password-page .login-component-container .reset-password-component .login-form form .login-loader span,
.reset-password-page .login-component-container .reset-password-component .login-form form .forgot-password-loader span,
.reset-password-page .login-component-container .reset-password-component .login-form form .reset-password-loader span,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .login-loader span,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-loader span,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-loader span,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .login-loader span,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-loader span,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-loader span,
.reset-password-page .forgot-password-component-container .login-component .login-form form .login-loader span,
.reset-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-loader span,
.reset-password-page .forgot-password-component-container .login-component .login-form form .reset-password-loader span,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-loader span,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-loader span,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-loader span,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .login-loader span,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-loader span,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-loader span,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-loader span,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-loader span,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-loader span,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-loader span,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader span,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-loader span,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-loader span,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-loader span,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-loader span,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .login-loader span,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-loader span,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-loader span,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-loader span,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-loader span,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-loader span,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-loader span,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-loader span,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-loader span,
.reset-password-page .reset-password-component-container .login-component .login-form form .login-loader span,
.reset-password-page .reset-password-component-container .login-component .login-form form .forgot-password-loader span,
.reset-password-page .reset-password-component-container .login-component .login-form form .reset-password-loader span,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .login-loader span,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-loader span,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-loader span,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .login-loader span,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-loader span,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-loader span,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .login-loader span,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-loader span,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-loader span,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-loader span,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-loader span,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-loader span,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-loader span,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-loader span,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-loader span,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .login-loader span,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-loader span,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-loader span,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-loader span,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-loader span,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-loader span,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-loader span,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-loader span,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-loader span {
  width: 25px !important;
  height: 25px !important;
}
.login-page .login-component-container .login-component .login-form form .login-response,
.login-page .login-component-container .login-component .login-form form .forgot-password-response,
.login-page .login-component-container .login-component .login-form form .reset-password-response,
.login-page .login-component-container .login-component .forgot-password-form form .login-response,
.login-page .login-component-container .login-component .forgot-password-form form .forgot-password-response,
.login-page .login-component-container .login-component .forgot-password-form form .reset-password-response,
.login-page .login-component-container .login-component .reset-password-form form .login-response,
.login-page .login-component-container .login-component .reset-password-form form .forgot-password-response,
.login-page .login-component-container .login-component .reset-password-form form .reset-password-response,
.login-page .login-component-container .forgot-password-component .login-form form .login-response,
.login-page .login-component-container .forgot-password-component .login-form form .forgot-password-response,
.login-page .login-component-container .forgot-password-component .login-form form .reset-password-response,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .login-response,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-response,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-response,
.login-page .login-component-container .forgot-password-component .reset-password-form form .login-response,
.login-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-response,
.login-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-response,
.login-page .login-component-container .reset-password-component .login-form form .login-response,
.login-page .login-component-container .reset-password-component .login-form form .forgot-password-response,
.login-page .login-component-container .reset-password-component .login-form form .reset-password-response,
.login-page .login-component-container .reset-password-component .forgot-password-form form .login-response,
.login-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-response,
.login-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-response,
.login-page .login-component-container .reset-password-component .reset-password-form form .login-response,
.login-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-response,
.login-page .login-component-container .reset-password-component .reset-password-form form .reset-password-response,
.login-page .forgot-password-component-container .login-component .login-form form .login-response,
.login-page .forgot-password-component-container .login-component .login-form form .forgot-password-response,
.login-page .forgot-password-component-container .login-component .login-form form .reset-password-response,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .login-response,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-response,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-response,
.login-page .forgot-password-component-container .login-component .reset-password-form form .login-response,
.login-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-response,
.login-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-response,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .login-response,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-response,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-response,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-response,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-response,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-response,
.login-page .forgot-password-component-container .reset-password-component .login-form form .login-response,
.login-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-response,
.login-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-response,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-response,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-response,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-response,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-response,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-response,
.login-page .reset-password-component-container .login-component .login-form form .login-response,
.login-page .reset-password-component-container .login-component .login-form form .forgot-password-response,
.login-page .reset-password-component-container .login-component .login-form form .reset-password-response,
.login-page .reset-password-component-container .login-component .forgot-password-form form .login-response,
.login-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-response,
.login-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-response,
.login-page .reset-password-component-container .login-component .reset-password-form form .login-response,
.login-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-response,
.login-page .reset-password-component-container .login-component .reset-password-form form .reset-password-response,
.login-page .reset-password-component-container .forgot-password-component .login-form form .login-response,
.login-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-response,
.login-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-response,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-response,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-response,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-response,
.login-page .reset-password-component-container .reset-password-component .login-form form .login-response,
.login-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-response,
.login-page .reset-password-component-container .reset-password-component .login-form form .reset-password-response,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-response,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-response,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .login-response,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-response,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-response,
.forgot-password-page .login-component-container .login-component .login-form form .login-response,
.forgot-password-page .login-component-container .login-component .login-form form .forgot-password-response,
.forgot-password-page .login-component-container .login-component .login-form form .reset-password-response,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .login-response,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-response,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .reset-password-response,
.forgot-password-page .login-component-container .login-component .reset-password-form form .login-response,
.forgot-password-page .login-component-container .login-component .reset-password-form form .forgot-password-response,
.forgot-password-page .login-component-container .login-component .reset-password-form form .reset-password-response,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .login-response,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-response,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .reset-password-response,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-response,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-response,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-response,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .login-response,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-response,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-response,
.forgot-password-page .login-component-container .reset-password-component .login-form form .login-response,
.forgot-password-page .login-component-container .reset-password-component .login-form form .forgot-password-response,
.forgot-password-page .login-component-container .reset-password-component .login-form form .reset-password-response,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .login-response,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-response,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-response,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .login-response,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-response,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-response,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .login-response,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-response,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .reset-password-response,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-response,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-response,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-response,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .login-response,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-response,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-response,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-response,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-response,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-response,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-response,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-response,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-response,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .login-response,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-response,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-response,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-response,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-response,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-response,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-response,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-response,
.forgot-password-page .reset-password-component-container .login-component .login-form form .login-response,
.forgot-password-page .reset-password-component-container .login-component .login-form form .forgot-password-response,
.forgot-password-page .reset-password-component-container .login-component .login-form form .reset-password-response,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .login-response,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-response,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-response,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .login-response,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-response,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-response,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .login-response,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-response,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-response,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-response,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-response,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-response,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .login-response,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-response,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-response,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-response,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-response,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-response,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-response,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-response,
.reset-password-page .login-component-container .login-component .login-form form .login-response,
.reset-password-page .login-component-container .login-component .login-form form .forgot-password-response,
.reset-password-page .login-component-container .login-component .login-form form .reset-password-response,
.reset-password-page .login-component-container .login-component .forgot-password-form form .login-response,
.reset-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-response,
.reset-password-page .login-component-container .login-component .forgot-password-form form .reset-password-response,
.reset-password-page .login-component-container .login-component .reset-password-form form .login-response,
.reset-password-page .login-component-container .login-component .reset-password-form form .forgot-password-response,
.reset-password-page .login-component-container .login-component .reset-password-form form .reset-password-response,
.reset-password-page .login-component-container .forgot-password-component .login-form form .login-response,
.reset-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-response,
.reset-password-page .login-component-container .forgot-password-component .login-form form .reset-password-response,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-response,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-response,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-response,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .login-response,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-response,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-response,
.reset-password-page .login-component-container .reset-password-component .login-form form .login-response,
.reset-password-page .login-component-container .reset-password-component .login-form form .forgot-password-response,
.reset-password-page .login-component-container .reset-password-component .login-form form .reset-password-response,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .login-response,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-response,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-response,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .login-response,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-response,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-response,
.reset-password-page .forgot-password-component-container .login-component .login-form form .login-response,
.reset-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-response,
.reset-password-page .forgot-password-component-container .login-component .login-form form .reset-password-response,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-response,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-response,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-response,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .login-response,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-response,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-response,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-response,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-response,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-response,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-response,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-response,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-response,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .login-response,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-response,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-response,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-response,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-response,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-response,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-response,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-response,
.reset-password-page .reset-password-component-container .login-component .login-form form .login-response,
.reset-password-page .reset-password-component-container .login-component .login-form form .forgot-password-response,
.reset-password-page .reset-password-component-container .login-component .login-form form .reset-password-response,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .login-response,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-response,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-response,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .login-response,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-response,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-response,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .login-response,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-response,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-response,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-response,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-response,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-response,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .login-response,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-response,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-response,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-response,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-response,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-response,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-response,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-response {
  margin: 10px 0 20px;
  text-align: center;
}
.login-page .login-component-container .login-component .login-form form .login-response .error-container .error-text,
.login-page .login-component-container .login-component .login-form form .forgot-password-response .error-container .error-text,
.login-page .login-component-container .login-component .login-form form .reset-password-response .error-container .error-text,
.login-page .login-component-container .login-component .forgot-password-form form .login-response .error-container .error-text,
.login-page .login-component-container .login-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.login-page .login-component-container .login-component .forgot-password-form form .reset-password-response .error-container .error-text,
.login-page .login-component-container .login-component .reset-password-form form .login-response .error-container .error-text,
.login-page .login-component-container .login-component .reset-password-form form .forgot-password-response .error-container .error-text,
.login-page .login-component-container .login-component .reset-password-form form .reset-password-response .error-container .error-text,
.login-page .login-component-container .forgot-password-component .login-form form .login-response .error-container .error-text,
.login-page .login-component-container .forgot-password-component .login-form form .forgot-password-response .error-container .error-text,
.login-page .login-component-container .forgot-password-component .login-form form .reset-password-response .error-container .error-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .login-response .error-container .error-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.login-page .login-component-container .forgot-password-component .reset-password-form form .login-response .error-container .error-text,
.login-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.login-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.login-page .login-component-container .reset-password-component .login-form form .login-response .error-container .error-text,
.login-page .login-component-container .reset-password-component .login-form form .forgot-password-response .error-container .error-text,
.login-page .login-component-container .reset-password-component .login-form form .reset-password-response .error-container .error-text,
.login-page .login-component-container .reset-password-component .forgot-password-form form .login-response .error-container .error-text,
.login-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.login-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.login-page .login-component-container .reset-password-component .reset-password-form form .login-response .error-container .error-text,
.login-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.login-page .login-component-container .reset-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.login-page .forgot-password-component-container .login-component .login-form form .login-response .error-container .error-text,
.login-page .forgot-password-component-container .login-component .login-form form .forgot-password-response .error-container .error-text,
.login-page .forgot-password-component-container .login-component .login-form form .reset-password-response .error-container .error-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .login-response .error-container .error-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-response .error-container .error-text,
.login-page .forgot-password-component-container .login-component .reset-password-form form .login-response .error-container .error-text,
.login-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-response .error-container .error-text,
.login-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-response .error-container .error-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .login-response .error-container .error-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-response .error-container .error-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-response .error-container .error-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-response .error-container .error-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-response .error-container .error-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.login-page .forgot-password-component-container .reset-password-component .login-form form .login-response .error-container .error-text,
.login-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-response .error-container .error-text,
.login-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-response .error-container .error-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-response .error-container .error-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-response .error-container .error-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.login-page .reset-password-component-container .login-component .login-form form .login-response .error-container .error-text,
.login-page .reset-password-component-container .login-component .login-form form .forgot-password-response .error-container .error-text,
.login-page .reset-password-component-container .login-component .login-form form .reset-password-response .error-container .error-text,
.login-page .reset-password-component-container .login-component .forgot-password-form form .login-response .error-container .error-text,
.login-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.login-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-response .error-container .error-text,
.login-page .reset-password-component-container .login-component .reset-password-form form .login-response .error-container .error-text,
.login-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-response .error-container .error-text,
.login-page .reset-password-component-container .login-component .reset-password-form form .reset-password-response .error-container .error-text,
.login-page .reset-password-component-container .forgot-password-component .login-form form .login-response .error-container .error-text,
.login-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-response .error-container .error-text,
.login-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-response .error-container .error-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-response .error-container .error-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-response .error-container .error-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.login-page .reset-password-component-container .reset-password-component .login-form form .login-response .error-container .error-text,
.login-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-response .error-container .error-text,
.login-page .reset-password-component-container .reset-password-component .login-form form .reset-password-response .error-container .error-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-response .error-container .error-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .login-response .error-container .error-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .login-component-container .login-component .login-form form .login-response .error-container .error-text,
.forgot-password-page .login-component-container .login-component .login-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .login-component-container .login-component .login-form form .reset-password-response .error-container .error-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .login-response .error-container .error-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .login-component-container .login-component .reset-password-form form .login-response .error-container .error-text,
.forgot-password-page .login-component-container .login-component .reset-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .login-component-container .login-component .reset-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .login-response .error-container .error-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .reset-password-response .error-container .error-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-response .error-container .error-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .login-response .error-container .error-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .login-component-container .reset-password-component .login-form form .login-response .error-container .error-text,
.forgot-password-page .login-component-container .reset-password-component .login-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .login-component-container .reset-password-component .login-form form .reset-password-response .error-container .error-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .login-response .error-container .error-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .login-response .error-container .error-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .login-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .reset-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .login-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .login-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .login-component .login-form form .login-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .login-component .login-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .login-component .login-form form .reset-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .login-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .login-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .login-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .login-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .login-component-container .login-component .login-form form .login-response .error-container .error-text,
.reset-password-page .login-component-container .login-component .login-form form .forgot-password-response .error-container .error-text,
.reset-password-page .login-component-container .login-component .login-form form .reset-password-response .error-container .error-text,
.reset-password-page .login-component-container .login-component .forgot-password-form form .login-response .error-container .error-text,
.reset-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .login-component-container .login-component .forgot-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .login-component-container .login-component .reset-password-form form .login-response .error-container .error-text,
.reset-password-page .login-component-container .login-component .reset-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .login-component-container .login-component .reset-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .login-component-container .forgot-password-component .login-form form .login-response .error-container .error-text,
.reset-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-response .error-container .error-text,
.reset-password-page .login-component-container .forgot-password-component .login-form form .reset-password-response .error-container .error-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-response .error-container .error-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .login-response .error-container .error-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .login-component-container .reset-password-component .login-form form .login-response .error-container .error-text,
.reset-password-page .login-component-container .reset-password-component .login-form form .forgot-password-response .error-container .error-text,
.reset-password-page .login-component-container .reset-password-component .login-form form .reset-password-response .error-container .error-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .login-response .error-container .error-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .login-response .error-container .error-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .login-component .login-form form .login-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .login-component .login-form form .reset-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .login-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .login-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .login-component .login-form form .login-response .error-container .error-text,
.reset-password-page .reset-password-component-container .login-component .login-form form .forgot-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .login-component .login-form form .reset-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .login-response .error-container .error-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .login-response .error-container .error-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .login-response .error-container .error-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-response .error-container .error-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-response .error-container .error-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .login-response .error-container .error-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-response .error-container .error-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-response .error-container .error-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .error-container .error-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-response .error-container .error-text {
  font-family: Inter-Light, Arial, Helvetica, sans-serif;
  font-size: 16px;
}
.login-page .login-component-container .login-component .login-form form .login-response .success-container,
.login-page .login-component-container .login-component .login-form form .forgot-password-response .success-container,
.login-page .login-component-container .login-component .login-form form .reset-password-response .success-container,
.login-page .login-component-container .login-component .forgot-password-form form .login-response .success-container,
.login-page .login-component-container .login-component .forgot-password-form form .forgot-password-response .success-container,
.login-page .login-component-container .login-component .forgot-password-form form .reset-password-response .success-container,
.login-page .login-component-container .login-component .reset-password-form form .login-response .success-container,
.login-page .login-component-container .login-component .reset-password-form form .forgot-password-response .success-container,
.login-page .login-component-container .login-component .reset-password-form form .reset-password-response .success-container,
.login-page .login-component-container .forgot-password-component .login-form form .login-response .success-container,
.login-page .login-component-container .forgot-password-component .login-form form .forgot-password-response .success-container,
.login-page .login-component-container .forgot-password-component .login-form form .reset-password-response .success-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .login-response .success-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container,
.login-page .login-component-container .forgot-password-component .reset-password-form form .login-response .success-container,
.login-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container,
.login-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container,
.login-page .login-component-container .reset-password-component .login-form form .login-response .success-container,
.login-page .login-component-container .reset-password-component .login-form form .forgot-password-response .success-container,
.login-page .login-component-container .reset-password-component .login-form form .reset-password-response .success-container,
.login-page .login-component-container .reset-password-component .forgot-password-form form .login-response .success-container,
.login-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container,
.login-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container,
.login-page .login-component-container .reset-password-component .reset-password-form form .login-response .success-container,
.login-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container,
.login-page .login-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container,
.login-page .forgot-password-component-container .login-component .login-form form .login-response .success-container,
.login-page .forgot-password-component-container .login-component .login-form form .forgot-password-response .success-container,
.login-page .forgot-password-component-container .login-component .login-form form .reset-password-response .success-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .login-response .success-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-response .success-container,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-response .success-container,
.login-page .forgot-password-component-container .login-component .reset-password-form form .login-response .success-container,
.login-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-response .success-container,
.login-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-response .success-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .login-response .success-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-response .success-container,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-response .success-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-response .success-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-response .success-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container,
.login-page .forgot-password-component-container .reset-password-component .login-form form .login-response .success-container,
.login-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-response .success-container,
.login-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-response .success-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-response .success-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-response .success-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container,
.login-page .reset-password-component-container .login-component .login-form form .login-response .success-container,
.login-page .reset-password-component-container .login-component .login-form form .forgot-password-response .success-container,
.login-page .reset-password-component-container .login-component .login-form form .reset-password-response .success-container,
.login-page .reset-password-component-container .login-component .forgot-password-form form .login-response .success-container,
.login-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-response .success-container,
.login-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-response .success-container,
.login-page .reset-password-component-container .login-component .reset-password-form form .login-response .success-container,
.login-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-response .success-container,
.login-page .reset-password-component-container .login-component .reset-password-form form .reset-password-response .success-container,
.login-page .reset-password-component-container .forgot-password-component .login-form form .login-response .success-container,
.login-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-response .success-container,
.login-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-response .success-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-response .success-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-response .success-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container,
.login-page .reset-password-component-container .reset-password-component .login-form form .login-response .success-container,
.login-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-response .success-container,
.login-page .reset-password-component-container .reset-password-component .login-form form .reset-password-response .success-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-response .success-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .login-response .success-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container,
.forgot-password-page .login-component-container .login-component .login-form form .login-response .success-container,
.forgot-password-page .login-component-container .login-component .login-form form .forgot-password-response .success-container,
.forgot-password-page .login-component-container .login-component .login-form form .reset-password-response .success-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .login-response .success-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-response .success-container,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .reset-password-response .success-container,
.forgot-password-page .login-component-container .login-component .reset-password-form form .login-response .success-container,
.forgot-password-page .login-component-container .login-component .reset-password-form form .forgot-password-response .success-container,
.forgot-password-page .login-component-container .login-component .reset-password-form form .reset-password-response .success-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .login-response .success-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-response .success-container,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .reset-password-response .success-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-response .success-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .login-response .success-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container,
.forgot-password-page .login-component-container .reset-password-component .login-form form .login-response .success-container,
.forgot-password-page .login-component-container .reset-password-component .login-form form .forgot-password-response .success-container,
.forgot-password-page .login-component-container .reset-password-component .login-form form .reset-password-response .success-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .login-response .success-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .login-response .success-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .login-response .success-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-response .success-container,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .reset-password-response .success-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-response .success-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-response .success-container,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-response .success-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .login-response .success-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-response .success-container,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-response .success-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-response .success-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-response .success-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-response .success-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-response .success-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-response .success-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .login-response .success-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-response .success-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-response .success-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-response .success-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-response .success-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container,
.forgot-password-page .reset-password-component-container .login-component .login-form form .login-response .success-container,
.forgot-password-page .reset-password-component-container .login-component .login-form form .forgot-password-response .success-container,
.forgot-password-page .reset-password-component-container .login-component .login-form form .reset-password-response .success-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .login-response .success-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-response .success-container,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-response .success-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .login-response .success-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-response .success-container,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-response .success-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .login-response .success-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-response .success-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-response .success-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-response .success-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-response .success-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .login-response .success-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-response .success-container,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-response .success-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-response .success-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-response .success-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container,
.reset-password-page .login-component-container .login-component .login-form form .login-response .success-container,
.reset-password-page .login-component-container .login-component .login-form form .forgot-password-response .success-container,
.reset-password-page .login-component-container .login-component .login-form form .reset-password-response .success-container,
.reset-password-page .login-component-container .login-component .forgot-password-form form .login-response .success-container,
.reset-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-response .success-container,
.reset-password-page .login-component-container .login-component .forgot-password-form form .reset-password-response .success-container,
.reset-password-page .login-component-container .login-component .reset-password-form form .login-response .success-container,
.reset-password-page .login-component-container .login-component .reset-password-form form .forgot-password-response .success-container,
.reset-password-page .login-component-container .login-component .reset-password-form form .reset-password-response .success-container,
.reset-password-page .login-component-container .forgot-password-component .login-form form .login-response .success-container,
.reset-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-response .success-container,
.reset-password-page .login-component-container .forgot-password-component .login-form form .reset-password-response .success-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-response .success-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .login-response .success-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container,
.reset-password-page .login-component-container .reset-password-component .login-form form .login-response .success-container,
.reset-password-page .login-component-container .reset-password-component .login-form form .forgot-password-response .success-container,
.reset-password-page .login-component-container .reset-password-component .login-form form .reset-password-response .success-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .login-response .success-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .login-response .success-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container,
.reset-password-page .forgot-password-component-container .login-component .login-form form .login-response .success-container,
.reset-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-response .success-container,
.reset-password-page .forgot-password-component-container .login-component .login-form form .reset-password-response .success-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-response .success-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-response .success-container,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-response .success-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .login-response .success-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-response .success-container,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-response .success-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-response .success-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-response .success-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-response .success-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-response .success-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-response .success-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .login-response .success-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-response .success-container,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-response .success-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-response .success-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-response .success-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container,
.reset-password-page .reset-password-component-container .login-component .login-form form .login-response .success-container,
.reset-password-page .reset-password-component-container .login-component .login-form form .forgot-password-response .success-container,
.reset-password-page .reset-password-component-container .login-component .login-form form .reset-password-response .success-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .login-response .success-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-response .success-container,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-response .success-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .login-response .success-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-response .success-container,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-response .success-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .login-response .success-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-response .success-container,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-response .success-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-response .success-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-response .success-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .login-response .success-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-response .success-container,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-response .success-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-response .success-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-response .success-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container {
  padding: 5px 0 0;
  width: 100%;
}
.login-page .login-component-container .login-component .login-form form .login-response .success-container .success-text,
.login-page .login-component-container .login-component .login-form form .forgot-password-response .success-container .success-text,
.login-page .login-component-container .login-component .login-form form .reset-password-response .success-container .success-text,
.login-page .login-component-container .login-component .forgot-password-form form .login-response .success-container .success-text,
.login-page .login-component-container .login-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.login-page .login-component-container .login-component .forgot-password-form form .reset-password-response .success-container .success-text,
.login-page .login-component-container .login-component .reset-password-form form .login-response .success-container .success-text,
.login-page .login-component-container .login-component .reset-password-form form .forgot-password-response .success-container .success-text,
.login-page .login-component-container .login-component .reset-password-form form .reset-password-response .success-container .success-text,
.login-page .login-component-container .forgot-password-component .login-form form .login-response .success-container .success-text,
.login-page .login-component-container .forgot-password-component .login-form form .forgot-password-response .success-container .success-text,
.login-page .login-component-container .forgot-password-component .login-form form .reset-password-response .success-container .success-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .login-response .success-container .success-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.login-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.login-page .login-component-container .forgot-password-component .reset-password-form form .login-response .success-container .success-text,
.login-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.login-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.login-page .login-component-container .reset-password-component .login-form form .login-response .success-container .success-text,
.login-page .login-component-container .reset-password-component .login-form form .forgot-password-response .success-container .success-text,
.login-page .login-component-container .reset-password-component .login-form form .reset-password-response .success-container .success-text,
.login-page .login-component-container .reset-password-component .forgot-password-form form .login-response .success-container .success-text,
.login-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.login-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.login-page .login-component-container .reset-password-component .reset-password-form form .login-response .success-container .success-text,
.login-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.login-page .login-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.login-page .forgot-password-component-container .login-component .login-form form .login-response .success-container .success-text,
.login-page .forgot-password-component-container .login-component .login-form form .forgot-password-response .success-container .success-text,
.login-page .forgot-password-component-container .login-component .login-form form .reset-password-response .success-container .success-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .login-response .success-container .success-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.login-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-response .success-container .success-text,
.login-page .forgot-password-component-container .login-component .reset-password-form form .login-response .success-container .success-text,
.login-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-response .success-container .success-text,
.login-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-response .success-container .success-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .login-response .success-container .success-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-response .success-container .success-text,
.login-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-response .success-container .success-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-response .success-container .success-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.login-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-response .success-container .success-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.login-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.login-page .forgot-password-component-container .reset-password-component .login-form form .login-response .success-container .success-text,
.login-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-response .success-container .success-text,
.login-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-response .success-container .success-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-response .success-container .success-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.login-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-response .success-container .success-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.login-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.login-page .reset-password-component-container .login-component .login-form form .login-response .success-container .success-text,
.login-page .reset-password-component-container .login-component .login-form form .forgot-password-response .success-container .success-text,
.login-page .reset-password-component-container .login-component .login-form form .reset-password-response .success-container .success-text,
.login-page .reset-password-component-container .login-component .forgot-password-form form .login-response .success-container .success-text,
.login-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.login-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-response .success-container .success-text,
.login-page .reset-password-component-container .login-component .reset-password-form form .login-response .success-container .success-text,
.login-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-response .success-container .success-text,
.login-page .reset-password-component-container .login-component .reset-password-form form .reset-password-response .success-container .success-text,
.login-page .reset-password-component-container .forgot-password-component .login-form form .login-response .success-container .success-text,
.login-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-response .success-container .success-text,
.login-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-response .success-container .success-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-response .success-container .success-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.login-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-response .success-container .success-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.login-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.login-page .reset-password-component-container .reset-password-component .login-form form .login-response .success-container .success-text,
.login-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-response .success-container .success-text,
.login-page .reset-password-component-container .reset-password-component .login-form form .reset-password-response .success-container .success-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-response .success-container .success-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.login-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .login-response .success-container .success-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.login-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .login-component-container .login-component .login-form form .login-response .success-container .success-text,
.forgot-password-page .login-component-container .login-component .login-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .login-component-container .login-component .login-form form .reset-password-response .success-container .success-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .login-response .success-container .success-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .login-component-container .login-component .forgot-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .login-component-container .login-component .reset-password-form form .login-response .success-container .success-text,
.forgot-password-page .login-component-container .login-component .reset-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .login-component-container .login-component .reset-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .login-response .success-container .success-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .login-component-container .forgot-password-component .login-form form .reset-password-response .success-container .success-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-response .success-container .success-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .login-response .success-container .success-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .login-component-container .reset-password-component .login-form form .login-response .success-container .success-text,
.forgot-password-page .login-component-container .reset-password-component .login-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .login-component-container .reset-password-component .login-form form .reset-password-response .success-container .success-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .login-response .success-container .success-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .login-response .success-container .success-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .login-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .login-component .login-form form .reset-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .login-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .login-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .login-component .login-form form .login-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .login-component .login-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .login-component .login-form form .reset-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .login-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .login-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .login-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .login-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.forgot-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .login-component-container .login-component .login-form form .login-response .success-container .success-text,
.reset-password-page .login-component-container .login-component .login-form form .forgot-password-response .success-container .success-text,
.reset-password-page .login-component-container .login-component .login-form form .reset-password-response .success-container .success-text,
.reset-password-page .login-component-container .login-component .forgot-password-form form .login-response .success-container .success-text,
.reset-password-page .login-component-container .login-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .login-component-container .login-component .forgot-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .login-component-container .login-component .reset-password-form form .login-response .success-container .success-text,
.reset-password-page .login-component-container .login-component .reset-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .login-component-container .login-component .reset-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .login-component-container .forgot-password-component .login-form form .login-response .success-container .success-text,
.reset-password-page .login-component-container .forgot-password-component .login-form form .forgot-password-response .success-container .success-text,
.reset-password-page .login-component-container .forgot-password-component .login-form form .reset-password-response .success-container .success-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .login-response .success-container .success-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .login-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .login-response .success-container .success-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .login-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .login-component-container .reset-password-component .login-form form .login-response .success-container .success-text,
.reset-password-page .login-component-container .reset-password-component .login-form form .forgot-password-response .success-container .success-text,
.reset-password-page .login-component-container .reset-password-component .login-form form .reset-password-response .success-container .success-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .login-response .success-container .success-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .login-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .login-response .success-container .success-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .login-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .login-component .login-form form .login-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .login-component .login-form form .forgot-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .login-component .login-form form .reset-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .login-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .login-component .forgot-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .login-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .login-component .reset-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .login-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .forgot-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .login-form form .reset-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .login-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .login-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .login-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .forgot-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .reset-password-component .login-form form .reset-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .login-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .login-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .forgot-password-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .login-component .login-form form .login-response .success-container .success-text,
.reset-password-page .reset-password-component-container .login-component .login-form form .forgot-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .login-component .login-form form .reset-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .login-response .success-container .success-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .login-component .forgot-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .login-response .success-container .success-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .login-component .reset-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .login-response .success-container .success-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .forgot-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .forgot-password-component .login-form form .reset-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .login-response .success-container .success-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .forgot-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .login-response .success-container .success-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .forgot-password-component .reset-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .login-response .success-container .success-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .forgot-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .reset-password-component .login-form form .reset-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .login-response .success-container .success-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .reset-password-component .forgot-password-form form .reset-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .login-response .success-container .success-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .forgot-password-response .success-container .success-text,
.reset-password-page .reset-password-component-container .reset-password-component .reset-password-form form .reset-password-response .success-container .success-text {
  font-size: 16px;
  font-family: Inter-Light, Arial, Helvetica, sans-serif;
  color: #441556;
  margin: 0;
}

.form-footer-text-container {
  justify-content: center;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 15px;
  right: auto;
  left: auto;
}
.form-footer-text-container .copyright-icon {
  font-size: 11px;
  color: #441556;
}
.form-footer-text-container .form-footer-text {
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  color: #441556;
  font-size: 14px;
  margin: 0;
}

.justify-content-start {
  justify-content: start !important;
}

.css-16ac5r2-MuiButtonBase-root:active {
  background-color: #000;
}

hr.MuiDivider-root {
  width: 100%;
  margin: 0;
  padding: 0 0 5px;
  color: #e5e6e8;
}

.app-main-layout {
  min-height: 100vh;
}
.app-main-layout div header.layout-header {
  border: none;
  background: #ffffff;
  border-bottom: 1px solid #e9e8e9;
  box-shadow: none;
}
.app-main-layout div header.layout-header div button svg {
  color: #611c7c;
}
.app-main-layout div header.layout-header div div {
  font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
  color: #441556;
  padding-left: 20px;
}
.app-main-layout div div.layout-menu div {
  border-color: #e9e8e9;
  color: #611c7c;
}
.app-main-layout div div.layout-menu div div.layout-menu-header img.layout-logo {
  width: 125px;
  position: absolute;
  left: 10px;
}
.app-main-layout div div.layout-menu div div.layout-menu-list ul li a {
  color: #062d51;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  text-decoration: none !important;
}
.app-main-layout div div.layout-menu div div.layout-menu-list ul li a div div svg {
  color: #611c7c;
}
.app-main-layout div div.layout-menu div div.layout-menu-list ul li.active {
  background: #341f72 !important;
}
.app-main-layout div div.layout-menu div div.layout-menu-list ul div div svg {
  color: #611c7c;
}
.app-main-layout div div.layout-menu div .layout-menu-submenu svg {
  color: #611c7c;
}
.app-main-layout div div.layout-menu div .layout-menu-submenu-options div div div a {
  color: #062d51;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  text-decoration: none !important;
}

.div.layout-menu-list:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

/** MARGENES Y PADDINGS **/
.margin-0 {
  margin: 0 !important;
}

/** ELEMENTS **/
button {
  height: 45px;
  padding: 0 20px;
  text-align: center;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-family: Inter-Medium, Arial, Helvetica, sans-serif;
  letter-spacing: 0.5px;
  color: #ffffff;
  background-color: #642ba5;
  margin: 10px 0;
  transition-duration: 0.4s;
  cursor: pointer;
}
button icon {
  font-size: 16px;
  font-weight: 700;
  margin: 0 10px 0 0;
}

button:hover {
  background-color: #491881;
}

.svg {
  font-size: 17px !important;
  top: 50%;
  margin: 0 10px 0 0;
  vertical-align: text-bottom !important;
}

/*** CONTENT ***/
.page-header {
  position: relative;
  margin-bottom: 15px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.page-header h3 {
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
  font-size: 22px;
  margin: 15px 0 23px;
}
.page-header.ticket-detail {
  margin: 0 !important;
  display: flex;
  align-items: center;
  margin-top: -7px !important;
}
.page-header.ticket-detail svg {
  font-size: 15px;
  margin-right: 10px;
  font-weight: 700;
}
.page-header.ticket-detail svg:hover {
  color: #9c27b0;
}
.page-header.ticket-detail h3 {
  font-size: 21px;
  display: block;
}
.page-header.ticket-detail h3:first-letter {
  text-transform: uppercase;
}
.page-header.ticket-detail .right {
  position: absolute;
  width: 100%;
}
.page-header.ticket-detail .right .ticket {
  display: flex;
  align-items: center;
  color: #591a64;
}
.page-header.ticket-detail .right .ticket h4 {
  font-size: 17px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.page-header.ticket-detail .right .ticket h4 svg {
  font-size: 15px !important;
  margin-top: 0 !important;
}
.page-header.ticket-detail .right .counter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.page-header.ticket-detail .right .counter .item {
  color: #591a64;
  padding: 0 30px;
  text-align: center;
  background-color: white;
  border: solid 1px #d8d7db;
}

.right,
.right-data {
  position: absolute;
  right: 0;
}

.ticket-bar {
  background: #fbf9ff;
  margin: 0;
  display: block;
  padding: 85px 20px 0;
  position: fixed;
  top: 0;
  right: 0;
  border: 1px solid #e9e8e9;
  border-radius: 0 0 15px 15px;
}
.ticket-bar .ticket-id-status {
  margin: 5px 0;
  display: flex;
  align-items: center;
}
.ticket-bar .ticket-id-status h2 {
  font-size: 20px;
  margin-bottom: 0;
  margin-right: 20px !important;
  display: flex;
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
  align-items: center;
  color: #441556;
}

/*** TICKETS ***/
.support-tickets-user-datagrid {
  height: 500px;
  width: 100%;
}

.MuiDataGrid-main {
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #212122;
  overflow-y: auto !important;
}
.MuiDataGrid-main .MuiDataGrid-columnHeaderTitle {
  font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.87);
}
.MuiDataGrid-main .MuiDataGrid-row:hover {
  background-color: transparent !important;
}
.MuiDataGrid-main .MuiDataGrid-row.Mui-selected {
  background-color: rgb(255, 255, 255) !important;
}
.MuiDataGrid-main .MuiDataGrid-row:nth-child(even) {
  background-color: rgb(249, 245, 251) !important;
}

.MuiDataGrid-main::-webkit-scrollbar {
  width: 12px;
}

.MuiDataGrid-main::-webkit-scrollbar-track {
  background-color: #ede6e6;
  border: 1px solid #e0e0e0;
}

.MuiDataGrid-main::-webkit-scrollbar-thumb {
  background-color: rgba(50, 6, 154, 0.137254902);
}

.ticket-time {
  color: #7d4792;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px 20px;
  margin-left: 30px;
}

.ticket-data {
  width: 100%;
  height: auto;
  text-align: left;
  border: 1px solid #dfdfdf;
  border-radius: 15px;
  font-size: 16px;
  color: #050505;
  background-color: #ffffff;
  padding: 15px 20px;
  margin: 0 0 20px !important;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
}
.ticket-data h4 {
  margin: 0 !important;
}
.ticket-data p {
  margin: 0 !important;
}
.ticket-data .basic {
  padding: 5px 0;
}
.ticket-data .basic h4 {
  text-transform: none !important;
  margin: 0;
  color: #441556;
  margin: 0 !important;
}
.ticket-data .basic p {
  font-size: 15px;
  margin: 0 !important;
}
.ticket-data .basic .left-data {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.ticket-data .basic .left-data svg {
  color: #ab8ab8;
  font-size: 17px;
}
.ticket-data .basic .left-data .type-label {
  font-size: 13px;
  letter-spacing: 1px;
  font-family: Inter-SemiBold, Arial, Helvetica, sans-serif;
  width: 100px;
  line-height: 0;
  color: #ffffff;
  padding: 0 12px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ticket-data .basic .left-data #SIM {
  background-color: #a34398;
}
.ticket-data .basic .left-data #eSIM {
  background-color: #362e7d;
}
.ticket-data .basic .left-data #MiFi {
  background-color: #e17522;
}
.ticket-data .basic .left-data #IoT {
  background-color: #1863c7;
}
.ticket-data .secondary {
  padding: 5px 0;
}
.ticket-data .secondary svg {
  color: #441556;
}
.ticket-data .secondary h4 {
  font-size: 18px;
  margin: 0 0 5px;
  color: #441556;
}
.ticket-data .secondary p {
  margin: 0;
  font-size: 15px;
}
.ticket-data .secondary .status {
  font-weight: 600;
  border-radius: 5px;
  color: #441556;
  margin-bottom: 4px;
}
.ticket-data .secondary svg:hover {
  cursor: pointer;
  background-color: #f4f4f4;
}
.ticket-data .numeric h4 {
  color: #441556;
}
.ticket-data .numeric p,
.ticket-data .numeric h4 {
  margin: 5px 0;
  font-size: 16px;
}
.ticket-data hr {
  color: #d9d9d9;
}

.info-box {
  width: 100%;
  flex-wrap: wrap;
  height: auto;
  text-align: left;
  border: 1px solid #dfdfdf;
  border-radius: 15px;
  font-size: 16px;
  color: #050505;
  background-color: #ffffff;
  margin: 20px 0 30px;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
}
.info-box .header {
  padding: 10px 20px;
  display: flex;
  align-content: center;
}
.info-box .header .section-one {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.info-box .header .section-one span {
  color: #611c7c;
  font-weight: 900;
  font-size: 16px;
  margin-right: 5px;
}
.info-box .header .section-one span.name {
  margin-left: 23px;
}
.info-box .header .section-one .data {
  color: #484848;
  text-transform: none;
  justify-content: flex-start;
}
.info-box .header .section-two .line-status {
  width: 100%;
  display: block;
  position: relative;
  padding: 8px;
  color: #45976e;
  background-color: #c1f5cf;
  border: none;
  border-radius: 5px;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
}
.info-box .header .section-two .line-status-Idle {
  width: 100%;
  display: block;
  position: relative;
  padding: 8px;
  color: #ae5fcc;
  background-color: #f2e1f8;
  border: none;
  border-radius: 5px;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
}
.info-box .header .section-two .line-status-Suspendida {
  width: 100%;
  display: block;
  position: relative;
  padding: 8px;
  color: #cb2020;
  background-color: #ff9797;
  border: none;
  border-radius: 5px;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
}
.info-box .header .section-two .line-status-Barring {
  width: 100%;
  display: block;
  position: relative;
  padding: 8px;
  color: #dc6c16;
  background-color: #fcc48b;
  border: none;
  border-radius: 5px;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
}
.info-box .header .section-two .line-status-Predeactivate {
  width: 100%;
  display: block;
  position: relative;
  padding: 8px;
  color: #858585;
  border: none;
  border-radius: 5px;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
}
.info-box .header .section-two .line-status-Desactivate {
  width: 100%;
  display: block;
  position: relative;
  padding: 8px;
  color: #ffffff;
  background-color: #8d8d8d;
  border: none;
  border-radius: 5px;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
}
.info-box .columns-header {
  border-top: 1px solid #dfdfdf;
  padding: 10px 20px 10px 0;
  font-weight: 900;
  color: #611c7c;
  background-color: #ffffff;
}
.info-box .columns-header .border {
  padding-left: 20px;
  border-right: 1px solid #dfdfdf;
}
.info-box .columns-header .last {
  padding-left: 20px;
  border: none !important;
}
.info-box .columns {
  border-top: 1px solid #dfdfdf;
  padding: 0 20px 0 0;
  color: #050505;
  background-color: #ffffff;
}
.info-box .columns span {
  text-transform: uppercase;
  font-weight: 900;
  color: #ff28df;
  font-size: 13px;
}
.info-box .columns .border {
  padding-left: 20px;
  border-right: 1px solid #dfdfdf;
}
.info-box .columns .last {
  padding-left: 20px;
  border: none !important;
}
.info-box .columns .data {
  color: #484848;
  font-size: 15px;
  margin: 15px 0;
  font-weight: 500;
  text-transform: uppercase;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  justify-content: flex-start;
}

.list-box,
.button-box {
  width: 100%;
  flex-wrap: wrap;
  height: auto;
  text-align: center;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  font-size: 16px;
  background-color: #ffffff;
}
.list-box.button-box,
.button-box.button-box {
  padding: 16px 15px !important;
  margin-bottom: 30px !important;
  overflow: auto;
  height: 305px;
  border-radius: 15px;
}
.list-box.button-box.button-box::-webkit-scrollbar,
.button-box.button-box.button-box::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.list-box.button-box.button-box::-webkit-scrollbar-track,
.button-box.button-box.button-box::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(55, 55, 55, 0.1);
}
.list-box.button-box.button-box::-webkit-scrollbar-thumb,
.button-box.button-box.button-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(131, 126, 131, 0.2);
}
.list-box.button-box.button-box::-webkit-scrollbar-thumb:hover,
.button-box.button-box.button-box::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
.list-box.button-box.button-box::-webkit-scrollbar-thumb:active,
.button-box.button-box.button-box::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}
.list-box.button-box h5,
.button-box.button-box h5 {
  color: #212122;
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}
.list-box.button-box .button,
.button-box.button-box .button {
  border: none !important;
  font-size: 13px;
  padding: 15px 10px;
  background: #f9f8fb;
  color: #625879;
  justify-content: flex-start;
  margin: 0 0 10px;
  text-align: start;
}
.list-box.button-box .button:hover,
.button-box.button-box .button:hover {
  background-color: #f0edf6;
}
.list-box .row,
.button-box .row {
  padding: 10px 25px;
  border-bottom: 1px solid #dfdfdf;
  justify-content: space-between;
}
.list-box .row .avatar,
.button-box .row .avatar {
  font-size: 13px !important;
  font-family: Inter-Bold, Arial, Helvetica, sans-serif !important;
  background-color: rgba(255, 198, 244, 0.6705882353);
  color: #e452b4;
  margin-right: 15px;
  width: 35px;
  height: 35px;
}
.list-box .row b,
.button-box .row b {
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
  color: #484848;
  font-size: 14px;
}
.list-box .row .events-date p,
.button-box .row .events-date p {
  font-size: 14px;
  color: #765881;
}
.list-box .row p,
.button-box .row p {
  display: block;
  margin: 12px 0;
}
.list-box .row .phone-number,
.button-box .row .phone-number {
  font-size: 14.5px;
  color: #504156;
}
.list-box .row .phone-number svg,
.button-box .row .phone-number svg {
  font-size: 15px !important;
  color: #998a9f;
  margin-bottom: 1px;
  margin-right: 5px;
}
.list-box .row p:first-letter,
.button-box .row p:first-letter {
  text-transform: uppercase;
}
.list-box .row .user,
.button-box .row .user {
  font-size: 16px;
  font-weight: 800;
  margin-right: 10px;
}
.list-box .row .section-one,
.button-box .row .section-one {
  text-align: left;
}
.list-box .row .section-one h4,
.button-box .row .section-one h4 {
  margin: 0;
  font-size: 15px;
  color: #7f16a9;
}
.list-box .row .section-one span,
.button-box .row .section-one span {
  margin-right: 20px;
}
.list-box .row .section-one p,
.button-box .row .section-one p {
  display: flex;
  align-items: center;
  color: #504156;
  margin: 0;
  font-size: 14px;
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}
.list-box .row .section-one p svg,
.button-box .row .section-one p svg {
  font-size: 15px !important;
  color: #ae9eb4;
  margin-bottom: 1px;
  margin-right: 8px;
}
.list-box .row .section-one button,
.button-box .row .section-one button {
  padding: 0;
  text-transform: none !important;
  font-size: 16px;
  color: #63219f !important;
  font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
  letter-spacing: 0;
}
.list-box .row .section-one button:hover,
.button-box .row .section-one button:hover {
  background-color: transparent;
  text-decoration: underline;
}
.list-box .row .section-one button:first-letter,
.button-box .row .section-one button:first-letter {
  text-transform: uppercase;
}
.list-box .row .section-two,
.button-box .row .section-two {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.list-box .row .section-two .avatar,
.button-box .row .section-two .avatar {
  font-size: 12px !important;
  font-family: Inter-Bold, Arial, Helvetica, sans-serif !important;
  margin: 0 auto;
  top: 21%;
  background-color: rgba(255, 198, 244, 0.6705882353);
  color: #e452b4;
}
.list-box .row .section-two .dates,
.button-box .row .section-two .dates {
  color: #918894;
  font-size: 13px;
  margin-left: 35px;
}
.list-box .row .section-two .dates p,
.button-box .row .section-two .dates p {
  line-height: 10px;
  text-align: right !important;
}
.list-box .row .section-two .dropdown-status,
.button-box .row .section-two .dropdown-status {
  display: block;
  position: relative;
  padding: 5px 15px;
  color: #545cd5;
  background-color: rgba(197, 201, 255, 0.4392156863);
  border: none;
  border-radius: 5px;
  margin: 10px 35px;
  font-weight: 500;
  text-align: center;
  font-size: 13px;
}

.tab-panel {
  padding: 15px 0 !important;
}
.tab-panel .comments {
  display: block;
}
.tab-panel .comments h2 {
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin-bottom: 25px;
}
.tab-panel .comments .comment-border {
  width: 100%;
  outline: 1px solid #dfdfdf;
  border-radius: 5px;
}
.tab-panel .comments .comment-border .comment-box {
  width: 100%;
  padding: 10px 20px;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin-top: 5px;
  border: none;
  background-color: white;
}
.tab-panel .comments .comment-border .comment-box:focus {
  outline: none;
}
.tab-panel .comments .comment-border:focus-within {
  outline: 2px solid #642ba5;
}
.tab-panel .ticket-detail-header {
  display: flex;
  align-items: center;
  border-width: 0px;
}
.tab-panel .ticket-detail-header h4 {
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin: 15px 0 20px;
}
.tab-panel .ticket-detail-header .select select {
  border-width: 0px !important;
  border: none !important;
}
.tab-panel .ticket-detail-header svg {
  color: white !important;
}

.tabs-container {
  width: calc(100% - 16px);
  padding-right: 38px;
  display: block;
}

.events-box {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  overflow: auto;
  padding: 0 !important;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif !important;
}
.events-box p {
  margin: 10px 0 0;
  color: #765881;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
}

.events-box::-webkit-scrollbar {
  width: 12px;
}

.events-box::-webkit-scrollbar-track {
  background-color: rgb(241, 241, 241);
}

.events-box::-webkit-scrollbar-thumb {
  background-color: rgba(153, 152, 153, 0.4);
}

.no-issues {
  padding: 50px !important;
}
.no-issues h2 {
  color: #ff28df;
}
.no-issues p {
  font-size: 16px;
}
.no-issues .no-issues-img {
  width: 15%;
}

.offers-container {
  position: relative;
  display: block;
  margin: 30px 0 10px !important;
}
.offers-container .header {
  color: #050505;
  font-size: 18px;
  font-family: Poppins-Light, Arial, Helvetica, sans-serif;
  margin: 0;
}
.offers-container .chart-container {
  margin: 0;
  background-color: #fcfcfe !important;
}
.offers-container .chart-container .chart {
  height: 200px;
}
.offers-container .chart-container h3 {
  font-size: 20px;
  margin: 0;
  color: #050505;
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}
.offers-container .chart-container h4 {
  font-size: 18px;
  margin: 0;
  color: #050505;
}
.offers-container .chart-container p {
  font-size: 16px;
  margin: 5px 0;
}
.offers-container .chart-container .colored {
  color: #050505;
  font-weight: 700;
}
.offers-container .chart-container .available {
  background: #fbf9ff;
  border-radius: 15px;
  border: 1px solid #d3d3d3;
  text-align: left;
}
.offers-container .chart-container svg {
  color: #441556;
}
.offers-container .chart-container svg:hover {
  cursor: pointer;
  background-color: #f4f4f4;
}
.offers-container .info {
  margin: 5px 0;
  padding: 10px 0 0;
}
.offers-container .info h3 {
  color: #6e6589;
  font-weight: 900;
  margin: 0;
  font-size: 14px;
  padding-bottom: 5px;
  border-bottom: 1px solid #d8d8d8;
}
.offers-container .info h4 {
  margin: 10px 0;
  font-size: 15px;
  font-weight: 200;
}
.offers-container .info.bar h3 {
  border-bottom: none !important;
}

/* MODAL */
.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(156, 151, 167, 0.17);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1000;
}

.modalContainer {
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column !important;
  padding: 20px 30px;
  text-align: left;
  z-index: 999 !important;
}
.modalContainer h2 {
  font-size: 20px;
}
.modalContainer h3 {
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-weight: 500;
}

.modalContainer .title {
  display: flex;
  text-align: left;
  font-size: 18px;
  height: 36px;
  margin-bottom: 25px;
}
.modalContainer .title h3 {
  font-weight: 700;
  color: #050505;
  font-size: 20px;
  margin: 5px 0 10px !important;
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif !important;
}
.modalContainer .title h4 {
  margin: 5px 0 10px !important;
  font-weight: 500 !important;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  color: #cbc7c7;
  border: none;
  display: flex;
  align-content: flex-start;
  padding: 0;
}

.modalContainer .body {
  display: flex;
  justify-content: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 20px 0 0;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px 0;
  border: none;
  background-color: #642ba5;
  color: white;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
  text-transform: none;
  font-weight: 400;
}

#cancelBtn {
  background-color: transparent;
  color: #7b52a6;
  letter-spacing: 0;
  font-size: 16px;
}

.required-incidence,
.error-incidence {
  color: #ff404f;
  text-align: left;
  font-size: 14px;
}

.required-incidence::before {
  display: inline;
  content: "⚠ ";
}

.required-incidence.no-before-icon::before {
  display: none;
  content: "";
}

.response-container .response-title {
  font-family: Inter-Bold, Arial, Helvetica, sans-serif;
}

/* COMENTARIOS*/
.comment-section {
  padding-top: 20px;
  display: grid !important;
}
.comment-section .comment-container {
  width: 100%;
}
.comment-section .comment-container .comment-box {
  padding: 10px 20px;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif !important;
  font-size: 16px;
  margin-top: 10px;
  border: 1px solid #d5d5d5;
  padding: 20px;
  border-radius: 10px;
}
.comment-section .comment-container .comment-box:focus {
  border: 2px solid;
  border-color: rgb(170, 121, 190) !important;
  outline: none !important;
}
.comment-section .comment-container .comment-btn {
  height: 50px;
  padding: 0 20px;
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
  font-family: Inter-Medium, Arial, Helvetica, sans-serif;
  color: #8361aa;
  background-color: #f2eef9 !important;
  border: 1px solid #efe1ff;
  margin: 10px 0;
  transition-duration: 0.4s;
  cursor: pointer;
}
.comment-section .comment-container .comment-btn:hover {
  background-color: #f1eaff !important;
  border: 1px solid #b8a4ce !important;
}

/* LEADS */
.lead-container {
  padding: 0;
  position: relative;
  margin-top: -10px;
}
.lead-container svg {
  font-size: 14px;
  margin-right: 15px;
}
.lead-container .lead-actions-container {
  width: 15%;
  background: #fbf9ff;
  height: 100vh;
  margin: 0;
  padding: 70px 20px 0;
  position: fixed;
  top: 0;
  right: 0;
  border: 1px solid #e9e8e9;
  overflow-y: auto !important;
}
.lead-container .lead-actions-container .save svg {
  font-size: 18px !important;
}
.lead-container .lead-actions-container svg {
  font-size: 16px;
  margin: 0 !important;
  color: #9158a7;
}
.lead-container .lead-actions-container .lead-actions {
  position: relative;
}
.lead-container .lead-actions-container .lead-actions h1 {
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
  color: #441556;
}
.lead-container .lead-actions-container .lead-actions h2 {
  font-size: 16px;
  color: #441556;
  margin: 20px 0 15px;
}
.lead-container .lead-actions-container .lead-actions .bottom-container {
  position: absolute;
  bottom: 0;
}
.lead-container .lead-actions-container .lead-actions .bottom-container .link-box {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 0 20px;
  margin-bottom: 10px;
}
.lead-container .lead-actions-container .lead-actions .bottom-container .link-box p {
  font-size: 15px;
}
.lead-container .lead-actions-container .lead-actions .bottom-container .main-btn,
.lead-container .lead-actions-container .lead-actions .bottom-container .confirm-btn,
.lead-container .lead-actions-container .lead-actions .bottom-container .copy-btn {
  width: 100%;
  font-size: 15px;
  font-family: Inter-Medium, Arial, Helvetica, sans-serif;
  text-transform: none;
}
.lead-container .lead-actions-container .lead-actions .bottom-container .main-btn.main-btn,
.lead-container .lead-actions-container .lead-actions .bottom-container .confirm-btn.main-btn,
.lead-container .lead-actions-container .lead-actions .bottom-container .copy-btn.main-btn {
  background: #642ba5 !important;
  margin: 10px 0 15px;
  height: 55px;
}
.lead-container .lead-actions-container .lead-actions .bottom-container .main-btn.main-btn:hover,
.lead-container .lead-actions-container .lead-actions .bottom-container .confirm-btn.main-btn:hover,
.lead-container .lead-actions-container .lead-actions .bottom-container .copy-btn.main-btn:hover {
  background-color: #45048e !important;
}
.lead-container .lead-actions-container .lead-actions .bottom-container .main-btn.copy-btn,
.lead-container .lead-actions-container .lead-actions .bottom-container .confirm-btn.copy-btn,
.lead-container .lead-actions-container .lead-actions .bottom-container .copy-btn.copy-btn {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  color: #642ba5 !important;
  height: 35px !important;
  font-size: 14px;
}
.lead-container .lead-actions-container .lead-actions .bottom-container .main-btn.copy-btn:hover,
.lead-container .lead-actions-container .lead-actions .bottom-container .confirm-btn.copy-btn:hover,
.lead-container .lead-actions-container .lead-actions .bottom-container .copy-btn.copy-btn:hover {
  background-color: #f1eaff !important;
}
.lead-container .lead-actions-container .lead-actions .bottom-container .main-btn.confirm-btn,
.lead-container .lead-actions-container .lead-actions .bottom-container .confirm-btn.confirm-btn,
.lead-container .lead-actions-container .lead-actions .bottom-container .copy-btn.confirm-btn {
  background-color: transparent !important;
  border: 1px solid #642ba5 !important;
  color: #642ba5 !important;
  margin: 20px 0 5px;
  height: 45px;
}
.lead-container .lead-actions-container .lead-actions .bottom-container .main-btn.confirm-btn:hover,
.lead-container .lead-actions-container .lead-actions .bottom-container .confirm-btn.confirm-btn:hover,
.lead-container .lead-actions-container .lead-actions .bottom-container .copy-btn.confirm-btn:hover {
  background-color: #f1eaff !important;
  border: 1px solid #b8a4ce !important;
}
@media screen and (max-width: 899px) {
  .lead-container .lead-actions-container {
    width: 100% !important;
    background: #fbf9ff;
    margin: 0;
    padding: 10px 20px !important;
    position: relative;
    border: none !important;
    border-radius: 10px;
    margin-top: 30px;
  }
}
.lead-container .lead-form {
  border-radius: 15px;
  border: 1px solid #d5d5d5;
  padding: 5px 20px 20px;
}
.lead-container .lead-form h1 {
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
  font-size: 20px;
  padding: 0 0 13px;
}
.lead-container .lead-form .inline {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lead-container .lead-form .imei-btn,
.lead-container .lead-form .postal-code-btn {
  height: 55px;
  padding: 0 20px;
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
  font-family: Inter-Medium, Arial, Helvetica, sans-serif;
  color: #8361aa;
  background-color: #f2eef9 !important;
  border: 1px solid #efe1ff;
  margin: 10px 0;
  transition-duration: 0.4s;
  cursor: pointer;
}
.lead-container .lead-form .imei-btn.postal-code-btn,
.lead-container .lead-form .postal-code-btn.postal-code-btn {
  margin-top: -5px;
}
.lead-container .lead-form .imei-btn:hover {
  color: #642ba5;
  background-color: #ece3f9 !important;
  border: 1px solid #efe1ff;
}

.lead-actions-container::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa; /* or add it to the track */
}

/* Add a thumb */
.lead-actions-container::-webkit-scrollbar-thumb {
  background: #000;
}

.lead-container form {
  width: 100%;
}

.lead-form .lead-form-input .lead-form-input-error {
  margin-top: 5px;
}

.link-box p {
  inline-size: 100%;
  overflow-wrap: break-word;
}

p.copy-success {
  width: 100%;
  text-align: center;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  color: #06ae00;
}

.modal-imei-label {
  color: #6e6b6b;
  margin: 5px 0;
}

.modal-imei-info {
  font-family: Inter-Bold, Arial, Helvetica, sans-serif;
  color: #441556;
  font-size: 18px;
}

.error-modal .modal-modal-title {
  color: #e62121;
}
.error-modal .modal-modal-icon svg {
  color: #e62121;
}

.success-modal .modal-modal-title {
  color: #724ecb;
}
.success-modal .modal-modal-icon svg {
  color: #724ecb;
}

.lead-detail-link {
  cursor: pointer;
  font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
  color: #642ba5;
}

.order-detail-link {
  cursor: pointer;
  font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
  color: #110222;
}

.assigned-to-other {
  cursor: auto;
}

.imei-input .MuiInputAdornment-positionEnd span {
  color: #642ba5;
  font-weight: 500;
}
.imei-input .MuiInputAdornment-positionEnd span.imei-input-null {
  color: rgb(246, 17, 17);
}

.leads-list {
  color: red;
}
.leads-list .MuiDataGrid-root {
  height: 71vh !important;
  overflow: auto !important;
}
.leads-list .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-row .selected {
  background-color: #e0d9f3;
  padding: 0 25px;
}
.leads-list .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-row:hover .selected {
  background-color: #ffebfb;
  color: #42008c;
}
.leads-list .MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-cell:first-child {
  justify-content: center;
}
.leads-list .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:first-child .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}

.customDropdown .MuiSelect-select {
  text-transform: uppercase !important;
}

li.customDropdownItem {
  text-transform: capitalize !important;
}

.lead-container .lead-actions-container .lead-actions .bottom-container .main-btn.main-btn.Mui-disabled {
  color: #919191 !important;
  background: #dbdbdb !important;
}

.filters-button-container {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 0 0 25px;
}

.displayDatepickers.shown {
  display: block;
}
.displayDatepickers.hidden {
  display: none;
}

.dropdown-filters-container {
  padding: 25px 0;
}

.kpi-container h2 {
  font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
  font-size: 22px;
  margin: 10px 0 0;
}

.line {
  display: inline-block;
  width: 250px;
  margin-left: 15px;
  height: 1px;
  background-color: #ccc;
  vertical-align: middle;
}

.kpi-graphic-container {
  margin-top: 0;
  background: white;
  border: 1px solid #dcdcdc;
  border-radius: 15px;
  padding: 30px 0;
  margin-bottom: 30px !important;
}

.kpi-empty {
  margin: 0 auto;
  text-align: center;
  line-height: 18px;
  color: #8b8b8b;
  font-size: 14px;
  font-family: Inter-Regular, Arial, Helvetica, sans-serif;
  background: white;
  padding: 30px;
  border: 1px solid #e7e7e7;
  border-radius: 15px;
}
.kpi-empty svg {
  font-size: 26px;
  color: #e0e0e0;
}

.mkt-datagrid {
  height: 400px !important;
  background-color: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.2);
  padding: 10px;
  white-space: nowrap;
}

.lead-imei-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.lead-imei-container .lead-imei {
  background: #f8f9fb;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  padding: 7px 15px 13px;
}
.lead-imei-container .lead-imei.column-imei {
  margin: 15px 15px 0 0;
}
.lead-imei-container .lead-imei.column-right {
  margin-right: 0 !important;
}
.lead-imei-container .lead-imei .imei-blocked {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.device-info-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.device-info-container h3 {
  color: #171717 !important;
  padding-left: 15px;
  margin: 0 0 5px;
  font-size: 18px;
}
.device-info-container .device-info {
  background: #f8f9fb;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  padding: 10px 15px 13px;
  line-height: 30px;
}
.device-info-container .device-info p {
  color: #626161;
  margin: 0 0 5px;
  font-size: 14px;
}
.device-info-container .device-info span {
  font-size: 17px;
  color: #422b4c;
  font-weight: 600;
}
.device-info-container .device-info hr {
  width: 100%;
}
.device-info-container .device-info .device-right-data {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.device-info-container .device-info.device-overflow {
  height: 178px;
  overflow: auto;
}
.device-info-container .device-info.device-overflow span {
  font-size: 14px;
}
.device-info-container .device-info.device-overflow::-webkit-scrollbar {
  width: 8px;
}
.device-info-container .device-info.device-overflow::-webkit-scrollbar-track {
  background: #ededee;
}
.device-info-container .device-info.device-overflow::-webkit-scrollbar-thumb {
  background: #e2dce2;
}
.device-info-container .device-info .type-label {
  font-size: 13px;
  letter-spacing: 1px;
  font-family: Inter-SemiBold, Arial, Helvetica, sans-serif;
  width: 100px;
  max-height: 35px !important;
  line-height: 0;
  color: #ffffff;
  padding: 0 12px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.device-info-container .device-info #SIM {
  background-color: #a34398;
}
.device-info-container .device-info #eSIM {
  background-color: #362e7d;
}
.device-info-container .device-info #MiFi {
  background-color: #e17522;
}
.device-info-container .device-info #IoT {
  background-color: #1863c7;
}

/** DASHBOARD **/
.dashboard-container {
  width: 80% !important;
  margin: 0 auto;
  height: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-container .dashboard-text {
  align-items: flex-start;
}
.dashboard-container .dashboard-text h1 {
  font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
  font-size: 40px;
  margin: 0 0 30px;
}
.dashboard-container .dashboard-text p {
  font-size: 18px;
  line-height: 34px;
}
.dashboard-container .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-container .image-container img {
  width: 75%;
}

.filters-container .filter-container {
  padding: 0 0 15px 15px;
}

.small-button {
  height: 54px !important;
  color: #ffffff !important;
  background: #624591 !important;
  align-items: center !important;
  justify-content: center !important;
}
.small-button svg {
  font-size: 19px !important;
  margin: 0 !important;
}

.css-qkprq3-MuiDataGrid-root .MuiDataGrid-virtualScroller {
  min-height: 60vh !important;
}

.MuiDataGrid-overlayWrapperInner {
  height: 390px !important;
}

.ordenes-de-compra-detalle {
  overflow: auto;
  max-height: 530px;
  display: block !important;
  margin-top: 40px;
}
.ordenes-de-compra-detalle h1 {
  font-size: 22px;
}
.ordenes-de-compra-detalle h2 {
  color: #333333;
}

.no-prod {
  position: fixed;
  width: 100%;
  top: 0px;
  padding: 0px 0;
  background: red;
  text-align: center;
  font-weight: 600;
  color: white;
  z-index: 9999;
}

section.no-prod-section .no-prod-container {
  padding-top: 60px;
}
section.no-prod-section .page-header {
  margin-top: 0;
}
section.no-prod-section .drawer-header-customized {
  min-height: 10px !important;
}

.modal-widget-component {
  margin-top: 5px;
}

.datepicker-cls {
  width: 100%;
}

.icon-button-search {
  background: "#642BA5";
  color: "white";
  border-radius: "6px";
}

.custom-linear-progress-container span {
  height: 15px !important;
}

.device-info-container .ticket-detail-header h4 {
  text-transform: uppercase;
}

.zero-number {
  color: #df62bf;
}

.reportsTableCLS {
  max-height: 60vh;
  overflow: auto !important;
}

.table-accordion-container .MuiPaper-root {
  background-color: #fcfcfe;
}
.table-accordion-container .MuiPaper-root .MuiAccordionSummary-content p.MuiTypography-root {
  font-weight: 700;
  color: #3b3b3b;
}
.table-accordion-container .MuiPaper-root .MuiAccordionSummary-expandIconWrapper {
  background: #e5e5e5;
  border-radius: 50px;
}
.table-accordion-container .MuiPaper-root .MuiAccordionDetails-root .MuiPaper-root {
  max-height: 350px;
}
.table-accordion-container .MuiPaper-root .MuiAccordionDetails-root .MuiTable-root {
  background-color: #ffffff;
}

.my-sales.container-2 {
  margin-top: 50px;
}

.MuiAccordion-root .MuiAccordionDetails-root th,
.MuiAccordion-root .MuiAccordionDetails-root td {
  padding: 0 16px;
  height: 46px;
}

.chargeback-row {
  background: linear-gradient(to right, #fff4ee 50%, #fff4ee 50%) !important;
}

.fraud-row {
  background: linear-gradient(to right, #ffecec 50%, #ffecec 50%) !important;
}

.chargeback-and-fraud-row {
  background: linear-gradient(to right, #ffecec 50%, #ffecec 50%) !important;
}

